import react from "react"
import moment from 'moment';

const Fun=(props)=>{
 let flag=true;
  return (  <>
  
  <div className="dropdown">
  <button className="btn dropdown-toggle" onClick={(e)=>e.preventDefault()} id={`dropdownMenuLink${props.dir}${props.name}${props.index}`} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
  {props.dir==="from" && moment.unix(props.from).format("h:mm A")}

  {props.dir==="to" && moment.unix(props.to).format("h:mm A")}
  </button>

  <div className="dropdown-menu" aria-labelledby={`dropdownMenuLink${props.dir}${props.name}${props.index}`}>
    {props.value.map((val,index)=>{
    
      if(props.value[index+1]-val !== 0 && index < props.value.length-1){
       
        if(props.dir==="from"){
          return <span  className="dropdown-item" onClick={()=>props.sets(props.dir,val,props.index,props.name)}>{moment.unix(val).format("h:mm A")}</span>
        }
        else{
       
        if(val >= props.from && flag){
          if(props.name,props.index, Number(props.value[index+1])-Number(val) > 1800){
          flag=false;
          }
          return <span className="dropdown-item" onClick={()=>props.sets(props.dir,Number(val)+1800,props.index,props.name)}>{moment.unix(Number(val)+1800).format("h:mm A")}</span>
          }
          
          }
      }
    })}
  
 </div>
</div>
    </>
  )

}
export default react.memo(Fun);