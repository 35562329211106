import './assets/css/style.css'
import Router from "./Routers"
import {useEffect} from "react"
import Nav from "./component/nav/Nav"
import Loggednav from "./component/nav/LoggedNav"
import Storage from "./helper/Storage"
import { useAuthContext } from './contexts/AuthContext'
import Header from './component/landing/LaComponents/Header';
function App() {
const authContext = useAuthContext()
useEffect(()=>{
  let rec=setInterval(() =>{
    if(!Storage.getCookies()){
      Storage.removelocalstorage();
      if(authContext.isAuthenticated){
        authContext.updateState(false);
      }
    
    }
  }, 3000);
  
return ()=>clearTimeout(rec);
},[authContext])
const lastsegment=()=>{
  let url = window.location.href;
  let array = url.split('/');
  return  array[array.length-1];
 }
let route_arr=["", "features", "how-it-works", "pricing", "about-us", "contact-us"];
if(route_arr.includes(lastsegment())){
return (
  <>
  <Header />
  <Router/>
  </>
)
}
else{
  return (
    <>
    {(authContext.isAuthenticated)?<Loggednav active={()=>lastsegment}/>:<Nav/>}
    <Router/>
  </>
   );
}

}
export default App;

