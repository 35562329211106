import React from "react";

import lshape2 from '../../../assets/images/landing/shape/line-shape-2.svg';
import logo9 from '../../../assets/images/landing/logo/09.png';
import logo10 from '../../../assets/images/landing/logo/10.png';
import logo11 from '../../../assets/images/landing/logo/11.png';
import logo12 from '../../../assets/images/landing/logo/12.png';
import logo13 from '../../../assets/images/landing/logo/13.png';
import logo14 from '../../../assets/images/landing/logo/14.png';
import logo15 from '../../../assets/images/landing/logo/15.png';
import logo16 from '../../../assets/images/landing/logo/16.png';
import logo17 from '../../../assets/images/landing/logo/17.png';
import logo18 from '../../../assets/images/landing/logo/18.png';
import logo19 from '../../../assets/images/landing/logo/19.png';
import logo20 from '../../../assets/images/landing/logo/20.png';
import logo21 from '../../../assets/images/landing/logo/21.png';
import logo22 from '../../../assets/images/landing/logo/22.png';
import logo23 from '../../../assets/images/landing/logo/23.png';
import logo24 from '../../../assets/images/landing/logo/24.png';

const UseableTools = () => {
    return (
        <>
            <div class="useable-tools-section-two bg-shape mt-150 mb-250 md-mt-100 md-mb-100">
            <div class="bg-wrapper">
                {/* <div class="shapes shape-one"></div>
                <div class="shapes shape-two"></div>
                <div class="shapes shape-three"></div>
                <div class="shapes shape-four"></div> */}
                <div class="container">
                    <div class="title-style-two text-center mb-70 md-mb-10">
                        <div class="row">
                            <div class="col-lg-10 col-md-11 m-auto">
                                <p>Integrates with your tools</p>
                                <h2>Connect Appointly with the software you <span>use every<img src={lshape2} alt="" /></span> day.
                                </h2>
                            </div>
                        </div>
                    </div>
                    {/* <!-- /.title-style-two --> */}

                    <div class="icon-wrapper">
                        <ul class="clearfix">
                            <li>
                                <div class="icon-box d-flex align-items-center justify-content-center">
                                    <img src={logo9} alt="" />
                                </div>
                                <div class="icon-box d-flex align-items-center justify-content-center">
                                    <img src={logo10} alt="" />
                                </div>
                            </li>
                            <li>
                                <div class="icon-box d-flex align-items-center justify-content-center">
                                    <img src={logo11} alt="" />
                                </div>
                                <div class="icon-box d-flex align-items-center justify-content-center">
                                    <img src={logo12} alt="" />
                                </div>
                            </li>
                            <li>
                                <div class="icon-box d-flex align-items-center justify-content-center">
                                    <img src={logo13} alt="" />
                                </div>
                                <div class="icon-box d-flex align-items-center justify-content-center">
                                    <img src={logo14} alt="" />
                                </div>
                            </li>
                            <li>
                                <div class="icon-box d-flex align-items-center justify-content-center">
                                    <img src={logo15} alt="" />
                                </div>
                            </li>
                            <li>
                                <div class="icon-box d-flex align-items-center justify-content-center">
                                    <img src={logo16} alt="" />
                                </div>
                                <div class="icon-box d-flex align-items-center justify-content-center">
                                    <img src={logo17} alt="" />
                                </div>
                            </li>
                            <li>
                                <div class="icon-box d-flex align-items-center justify-content-center">
                                    <img src={logo18} alt="" />
                                </div>
                            </li>
                            <li>
                                <div class="icon-box d-flex align-items-center justify-content-center">
                                    <img src={logo19} alt="" />
                                </div>
                                <div class="icon-box d-flex align-items-center justify-content-center">
                                    <img src={logo20} alt="" />
                                </div>
                            </li>
                            <li>
                                <div class="icon-box d-flex align-items-center justify-content-center">
                                    <img src={logo21} alt="" />
                                </div>
                                <div class="icon-box d-flex align-items-center justify-content-center">
                                    <img src={logo22} alt="" />
                                </div>
                            </li>
                            <li>
                                <div class="icon-box d-flex align-items-center justify-content-center">
                                    <img src={logo23} alt="" />
                                </div>
                                <div class="icon-box d-flex align-items-center justify-content-center">
                                    <img src={logo24} alt="" />
                                </div>
                            </li>
                        </ul>
                    </div>
                    {/* <!-- /.icon-wrapper --> */}
                </div>
                {/* <!-- /.container --> */}
            </div>
            {/* <!-- /.bg-wrapper --> */}
        </div>
        </>
    );
}

export default UseableTools;