import React from "react";
import {Link} from "react-router-dom";

const Faq = () => {
    return (
        <>
            <div class="container">
                <div class="title-style-five text-center mb-80 md-mb-60">
                    <h6>FAQ</h6>
                    <h2><span>Question & Answer</span></h2>
                </div>

                <div class="row">
                    <div class="col-xl-9 col-lg-10 m-auto" data-aos="fade-up" data-aos-duration="1200">
                        <div id="accordionThree" class="accordion accordion-style-four">
                            <div class="card">
                                <div class="card-header" id="headingOne1">
                                    <h5 class="mb-0">
                                        <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseOne1" aria-expanded="true" aria-controls="collapseOne1">
							                How does the free trial work?
                                            <i class="far fa-chevron-down"></i>
							          </button>
                                    </h5>
                                </div>
                                <div id="collapseOne1" class="collapse" aria-labelledby="headingOne1" data-parent="#accordionThree">
                                    <div class="card-body">
                                        <p>mea case duis tollit et. Etiam nusquam set minium eu sea, ei tale paulo elab. Noluisse mnesarch Et is vero incorrupte eos deserunt quaeren.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="headingTwo1">
                                    <h5 class="mb-0">
                                        <button class="btn btn-link" type="button" data-toggle="collapse" data-target="#collapseTwo1" aria-expanded="true" aria-controls="collapseTwo1">
							                How do you weigh different criteria in your process?
                                            <i class="far fa-chevron-down"></i>
							          </button>
                                    </h5>
                                </div>
                                <div id="collapseTwo1" class="collapse show" aria-labelledby="headingTwo1" data-parent="#accordionThree">
                                    <div class="card-body">
                                        <p>mea case duis tollit et. Etiam nusquam set minium eu sea, ei tale paulo elab. Noluisse mnesarch Et is vero incorrupte eos deserunt quaeren.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="headingThree1">
                                    <h5 class="mb-0">
                                        <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseThree1" aria-expanded="true" aria-controls="collapsethree1">
							                What does First Round look for in an idea?
                                            <i class="far fa-chevron-down"></i>
							          </button>
                                    </h5>
                                </div>
                                <div id="collapseThree1" class="collapse" aria-labelledby="headingThree1" data-parent="#accordionThree">
                                    <div class="card-body">
                                        <p>mea case duis tollit et. Etiam nusquam set minium eu sea, ei tale paulo elab. Noluisse mnesarch Et is vero incorrupte eos deserunt quaeren.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="headingFour1">
                                    <h5 class="mb-0">
                                        <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseFour1" aria-expanded="true" aria-controls="collapseFour1">
							           	    What do you look for in a founding team?
                                            <i class="far fa-chevron-down"></i>
							          </button>
                                    </h5>
                                </div>
                                <div id="collapseFour1" class="collapse" aria-labelledby="headingFour1" data-parent="#accordionThree">
                                    <div class="card-body">
                                        <p>mea case duis tollit et. Etiam nusquam set minium eu sea, ei tale paulo elab. Noluisse mnesarch Et is vero incorrupte eos deserunt quaeren.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="headingFive1">
                                    <h5 class="mb-0">
                                        <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseFive1" aria-expanded="true" aria-controls="collapseFive1">
							                Do you recommend Pay as you go or Pre pay?
                                            <i class="far fa-chevron-down"></i>
							          </button>
                                    </h5>
                                </div>
                                <div id="collapseFive1" class="collapse" aria-labelledby="headingFive1" data-parent="#accordionThree">
                                    <div class="card-body">
                                        <p>mea case duis tollit et. Etiam nusquam set minium eu sea, ei tale paulo elab. Noluisse mnesarch Et is vero incorrupte eos deserunt quaeren.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="headingSix1">
                                    <h5 class="mb-0">
                                        <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseSix1" aria-expanded="true" aria-controls="collapseSix1">
							                Can I pass the fees on to my customers?
                                            <i class="far fa-chevron-down"></i>
							          </button>
                                    </h5>
                                </div>
                                <div id="collapseSix1" class="collapse" aria-labelledby="headingSix1" data-parent="#accordionThree">
                                    <div class="card-body">
                                        <p>mea case duis tollit et. Etiam nusquam set minium eu sea, ei tale paulo elab. Noluisse mnesarch Et is vero incorrupte eos deserunt quaeren.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="headingSeven1">
                                    <h5 class="mb-0">
                                        <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseSeven1" aria-expanded="true" aria-controls="collapseSeven1">
							                lorem ipsum dummy text here will some questions?
                                            <i class="far fa-chevron-down"></i>
							          </button>
                                    </h5>
                                </div>
                                <div id="collapseSeven1" class="collapse" aria-labelledby="headingSeven1" data-parent="#accordionThree">
                                    <div class="card-body">
                                        <p>mea case duis tollit et. Etiam nusquam set minium eu sea, ei tale paulo elab. Noluisse mnesarch Et is vero incorrupte eos deserunt quaeren.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="text-center mt-60 md-mt-50" data-aos="fade-up" data-aos-duration="1200">
                    <h3 class="font-rubik pb-30">Don’t find your answer?</h3>
                    <Link to="/contact-us" class="theme-btn-five">Contact us</Link>
                </div>
            </div>
        </>
    )
}

export default Faq;