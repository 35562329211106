import React from "react";

const InfoCard = (props) => {
    return(
        <>
            <div class={`block-style-thirteen ${props.class}`}>
                <div class="icon"><img src={props.imgSrc} alt=""/></div>
                <div class="title font-rubik">{props.title}</div>
                <p>{props.desc}</p>
            </div>
        </>
    );
}

export default InfoCard;