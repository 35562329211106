import React from 'react';
import { Avatar } from '@mui/material';

const UploadedProfilePic = (props) => {
return (<>
        <Avatar 
            sx={{width: props.width, height: props.height}}
            alt="Profile Pic"
            src={props.src} />
    </>);
}

export default UploadedProfilePic;
