import React from 'react';
import logo from './../../assets/images/logo.svg'
import {Link} from "react-router-dom";
const Fun=()=>{
return (
<>
<header>
    <div className="site-header">
            <div className="container-fluid">
                <nav className="navbar navbar-expand-sm navbar-light justify-content-center">
                    <a className="navbar-brand" href="/"><img src='/images/logo.svg' alt="Brand Logo" /></a>
                    {/* <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                      <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav ml-auto">
                           
                            <li className="nav-item">
                              <Link to="/login" className="nav-link btn"> Login</Link>
                            </li>
                            <li className="nav-item">
                            <Link to="/register" className="nav-link btn sign-up"> Sign up </Link>
                            </li>
                            
                        </ul>
                    </div> */}
                </nav>
        </div>
    </div>
</header>
</>
)
};
export default React.memo(Fun);