import React from "react";


const BlogCard = (props) => {
    return (
        <>
        <div class="feature-article-meta">
            <div class="img-meta"><img src={props.imgSrc} alt={props.imgInfo}/></div>
            <div class="post-meta">
                <h3><a href={props.link}>{props.description}</a></h3>
                <div class="author_info">{props.author}. <span>{props.authorPost}</span></div>
            </div>
        </div>
        </>
    );
}

export default BlogCard;