const FeatureCardData = [
    {
        id : 1,
        imgSrc: '/images/20.svg',
        title: 'Easily sign-up/login',
        desc : 'Create customized popups and show the right message at the right time.'
    },
    {
        id : 2,
        delay : 100,
        imgSrc: '/images/21.svg',
        title : 'Create a event',
        desc  : 'Collect website leads with embedded forms and integrate easily.'
    },
    {
        id : 3,
        imgSrc: '/images/22.svg',
        title: 'Schedule a meetings',
        desc : 'Send welcome email to your new subscribers with a code.'
    },
    {
        id : 4,
        delay : 100,
        imgSrc: '/images/20.svg',
        title : 'Personalized booking link',
        desc  : 'Collect website leads with embedded forms and integrate easily.'
    },
    {
        id : 5,
        imgSrc: '/images/21.svg',
        title: 'Unlimited lead capture',
        desc : 'Send welcome email to your new subscribers with a code.'
    },
    {
        id : 6,
        delay : 100,
        imgSrc: '/images/22.svg',
        title: 'Real-time lead engagement',
        desc : 'Create customized popups and show the right message at the right time.'
    },
    {
        id : 7,
        imgSrc: '/images/20.svg',
        title: 'One-of meetings',
        desc : 'Send welcome email to your new subscribers with a code.'
    },
    {
        id : 8,
        delay : 100,
        imgSrc: '/images/21.svg',
        title: 'Live chats and instant video calls',
        desc : 'Create customized popups and show the right message at the right time.'
    },
    {
        id : 9,
        imgSrc: '/images/22.svg',
        title: 'Teams scheduling',
        desc : 'Send welcome email to your new subscribers with a code.'
    },
    {
        id : 10,
        delay : 100,
        imgSrc: '/images/20.svg',
        title: 'Remainder and deadlines notifications',
        desc : 'Create customized popups and show the right message at the right time.'
    },
];

export default FeatureCardData;