import React from "react";
import WhyChooseRow from "./WhyChooseRow";

const WhyChooseUs = () => {
    return(
        <>
            <div class="container">
                <div class="title-style-five text-center mb-35">
                    <h6>Why choose us</h6>
                    <h2><span>Why Appointly?</span></h2>
                </div>
                <WhyChooseRow 
                    rowClass='row align-items-center'
                    rowInnerClass='accordion-style-two pr-5'
                    title='More convenient and easy to set up.'
                    text='It only takes 5 minutes. Set-up is smooth & simple, with fully customisable page design to reflect your brand lorem dummy. It only takes 5 minutes. Set-up is smooth & simple, with fully customisable page design to reflect your brand lorem dummy.'
                    animationTextClass='fade-right'
                    animationImgClass='fade-left'
                    imgClass='justify-content-end'
                    imgSrc='images/home/why-us-1.png'
                />
                <WhyChooseRow 
                    rowClass='row align-items-center flex-lg-row-reverse my-5'
                    rowInnerClass='accordion-style-two pl-5'
                    title='Instant book scheduling and better user experience.'
                    text='It only takes 5 minutes. Set-up is smooth & simple, with fully customisable page design to reflect your brand lorem dummy. It only takes 5 minutes. Set-up is smooth & simple, with fully customisable page design to reflect your brand lorem dummy.'
                    animationTextClass='fade-left'
                    animationImgClass='fade-right'
                    imgClass='justify-content-start'
                    imgSrc='images/home/instant-booking.png'
                />
                <WhyChooseRow 
                    rowClass='row align-items-center'
                    rowInnerClass='accordion-style-two pr-5'
                    title='Automates reminder and notifications.'
                    text='It only takes 5 minutes. Set-up is smooth & simple, with fully customisable page design to reflect your brand lorem dummy. It only takes 5 minutes. Set-up is smooth & simple, with fully customisable page design to reflect your brand lorem dummy.'
                    animationTextClass='fade-right'
                    animationImgClass='fade-left'
                    imgClass='justify-content-end'
                    imgSrc='images/home/auto-reminder.png'
                />
            </div>
        </>
    );
}

export default WhyChooseUs;