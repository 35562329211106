import {Route,Redirect} from "react-router-dom";
import Storage from "./helper/Storage"
import React from 'react';
const PrivateRoute = ({component: Component, ...rest}) => {
if(!Storage.getCookies()){
    Storage.removelocalstorage();
}

return (
<>
<Route 
{...rest} 
render={props =>{
    if(!Storage.getCookies()){
    return <Redirect to={`/login`}/>
}
else{
    return <Component {...props}/>
}
}}
/>
</>
    );
};
export default React.memo(PrivateRoute); 