import React from "react"
import logo from './../../assets/images/logo.svg'
import noti from './../../assets/images/notification-icon.svg'
import {Link,NavLink,useHistory} from "react-router-dom";
import Storage from "./../../helper/Storage"
import { useAuthContext } from './../../contexts/AuthContext'
import urlhelper from "./../../helper/urlhelper"
import api from "./../Api"
import DefaultProfilePic from "../AppTheme/DefaultProfilePic";
import UploadedProfilePic from "../AppTheme/UploadedProfilePic";
let Fun=(props)=>{
    const history=useHistory();
    const user_data=useAuthContext();
    const logout=async()=>{
     try {
        await api.logout();
        Storage.removeCookie();
        user_data.updateState(false);
        history.push("/login");

     }catch(e){

     }
    }
   return (
        <>
     <header>
        <div className="site-header dashboard">
            <div className="container-fluid">
                <nav className="navbar navbar-expand-lg navbar-light">
                <a href="/" className="navbar-brand"><img src={logo} alt="Brand Logo" /> </a>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                    <div className="collapse navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav ml-auto">
                            <li className={`nav-item ${(props.active==="dashbaord")?"active":""}`} >
                            <NavLink to={urlhelper.getAbsoluteUrl(user_data.user.username,"dashboard")}  className="nav-link">Events</NavLink>
                              
                            </li>
                            <li className={`nav-item ${(props.active==="availabilty")?"active":""}`}>
                            <NavLink to={urlhelper.getAbsoluteUrl(user_data.user.username,"availabilty")}  className="nav-link">Availability</NavLink>
                               
                            </li>
                            <li className={`nav-item ${(props.active==="mybookings")?"active":""}`}>
                            <NavLink to={urlhelper.getAbsoluteUrl(user_data.user.username,"mybookings")}  className="nav-link">View my bookings</NavLink>
                               
                            </li>
                            <li className="nav-item">
                            <NavLink to={urlhelper.getAbsoluteUrl(user_data.user.username,"mybookedevent")}  className="nav-link">Bookings</NavLink>
                               
                            </li>
                            <li className="nav-item">
                            <NavLink to={urlhelper.getAbsoluteUrl(user_data.user.username,"integrations")}  className="nav-link">Integrations</NavLink>
                             </li>
                        </ul>
                    </div>
                    
                    <div className="dropdown noti-drop">
                        <button className="btn dropdown-toggle p-0" type="button" id="dropdownNotification" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <img src={noti} alt="noti" />
                        </button>
                        <div className="dropdown-menu" aria-labelledby="dropdownNotification">
                            {(user_data.notifications.length !==0)?
                            user_data.notifications.map((value,index)=>{
                               return <Link key={index} to="/#" className="dropdown-item">{value.description}</Link>
                            }):""}
                        
                      
                        </div>
                    </div>
                    <div className="dropdown">
                        <button className="btn dropdown-toggle p-0" type="button" id="dropdownProfile" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <span className="mr-2">
                                {user_data.user.img ? <UploadedProfilePic width={32} height={32} src={user_data.user.img} /> : <DefaultProfilePic width={32} height={32} fontSize={14} />}
                            </span>
                            <span className="d-none d-sm-block mr-2">
                                <p>{(user_data.isAuthenticated && user_data.user.firstName !== null && user_data.user.firstName !== undefined && user_data.user.firstName !== "" ) || (user_data.isAuthenticated && user_data.user.lastName !== null && user_data.user.lastName !== undefined && user_data.user.lastName !== "") ? user_data.user.firstName + " "+ user_data.user.lastName : "Name"}</p>
                            </span>
                            <i className="far fa-chevron-down"></i>
                        </button>
                        <div className="dropdown-menu" aria-labelledby="dropdownProfile">
                            <Link to={urlhelper.getAbsoluteUrl(user_data.user.username,"profile")} className="dropdown-item">Edit Profile</Link>
                            <Link to="/settings" className="dropdown-item">Notification Settings</Link>
                            <button type="button" className="dropdown-item" onClick={logout}>Logout</button>
                         </div>
                    </div>
                </nav>
            </div>
        </div>
    </header>
    </>
    )
}
export default React.memo(Fun);