import React, {useState} from "react";
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';
// Import Images
import icon23 from '../../../assets/images/landing/icon/23.svg';
import icon24 from '../../../assets/images/landing/icon/24.svg';
import icon25 from '../../../assets/images/landing/icon/25.svg';
const CounterSec = () => {
    const [viewPortEntered, setViewPortEntered] = useState(false);
    return(
        <>
        <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-4 col-6" data-aos="fade-up" data-aos-duration="1200">
                        <div class="counter-box-three">
                            <div class="icon"><img src={icon23} alt="" /></div>
                            {/* <h2 class="number"><span class="timer" data-from="0" data-to="13" data-speed="1500" data-refresh-interval="5">0</span>m</h2> */}
                            <h2>
                                <CountUp start={viewPortEntered ? null : 0} end={13} suffix={'m'} duration={1.5} redraw={false}>
                                    {({ countUpRef, start }) => (
                                    <VisibilitySensor active={!viewPortEntered} onChange={isVisible => {if (isVisible) {setViewPortEntered(true);}}} delayedCall>
                                        <span ref={countUpRef} />
                                    </VisibilitySensor>
                                    )}
                                </CountUp>
                            </h2>
                            <p class="font-rubik">Event Sold</p>
                        </div>
                        {/* <!-- /.counter-box-three --> */}
                    </div>
                    <div class="col-lg-4 col-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
                        <div class="counter-box-three">
                            <div class="icon"><img src={icon24} alt="" /></div>
                            {/* <h2 class="number"><span class="timer" data-from="0" data-to="30000" data-speed="1200" data-refresh-interval="5">0</span></h2> */}
                            <h2>
                                <CountUp start={viewPortEntered ? null : 0} end={3000} duration={1.5}>
                                    {({ countUpRef, start }) => (
                                    <VisibilitySensor onChange={isVisible => {if (isVisible) {setViewPortEntered(true);}}} delayedCall>
                                        <span ref={countUpRef} />
                                    </VisibilitySensor>
                                    )}
                                </CountUp>
                            </h2>
                            <p class="font-rubik">Event organisers</p>
                        </div>
                        {/* <!-- /.counter-box-three --> */}
                    </div>
                    <div class="col-lg-4 col-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200">
                        <div class="counter-box-three">
                            <div class="icon"><img src={icon25} alt="" /></div>
                            {/* <h2 class="number"><span class="timer" data-from="0" data-to="134" data-speed="1200" data-refresh-interval="5">0</span></h2> */}
                            <h2>
                                <CountUp start={viewPortEntered ? null : 0} end={134} duration={1.5}>
                                    {({ countUpRef, start }) => (
                                    <VisibilitySensor onChange={isVisible => {if (isVisible) {setViewPortEntered(true);}}} delayedCall>
                                        <span ref={countUpRef} />
                                    </VisibilitySensor>
                                    )}
                                </CountUp>
                            </h2>
                            <p class="font-rubik">Countries</p>
                        </div>
                        {/* <!-- /.counter-box-three --> */}
                    </div>
                </div>
            </div>
        </>
    );
}

export default CounterSec;