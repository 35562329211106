import React from 'react';

const Fun=(props)=>{
return (
<>

<span className="pwd-error">{props.value}</span>
</>
)
};
export default React.memo(Fun);