import React from "react";
import InfoCard from "./LaComponents/InfoCard";
import GetStartCard from "./LaComponents/GetStartCard";
import WhyChooseUs from "./LaComponents/Whychoose";
import PricingTable from "./LaComponents/PricingTable";
import ClientSlider from "./LaComponents/ClientSlider";
import Faq from "./LaComponents/Faq";
import SignupLanding from "./LaComponents/SignupLanding";
import Footer from "./LaComponents/Footer";
import ScrollTopBtn from "./LaComponents/ScrollTopBtn"; 


// Import Css
import '../../assets/css/framework.css';
import '../../assets/css/responsive.css';
import '../../assets/css/custom-animation.css';
// Import Js
import '../../assets/js/theme'
// Import Images
import ils14 from '../../assets/images/landing/assets/ils_14.svg';
import ils141 from '../../assets/images/landing/assets/ils_14.1.svg';
import ils142 from '../../assets/images/landing/assets/ils_14.2.svg';
import ils143 from '../../assets/images/landing/assets/ils_14.3.svg';
import icon65 from '../../assets/images/landing/icon/65.svg';
import media57 from '../../assets/images/landing/media/img_57.png';
import media59 from '../../assets/images/landing/media/img_59.png';
import media60 from '../../assets/images/landing/media/img_60.png';
import media61 from '../../assets/images/landing/media/img_61.png';
import media62 from '../../assets/images/landing/media/img_62.png';
import media63 from '../../assets/images/landing/media/img_63.png';
import media64 from '../../assets/images/landing/media/img_64.png';







const LHome = () => {
    return (
        <>
        <div class="main-page-wrapper p0">
        {/* <!-- =============================================
				Theme Hero Banner
			============================================== --> */}
        <div class="hero-banner-four">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6 order-lg-last">
                        <div class="illustration-holder">
                            <img src={ils14} alt="" class="illustration" />
                            <img src={ils141} alt="" class="shapes shape-one" />
                            <img src={ils142} alt="" class="shapes shape-two" />
                            <img src={ils143} alt="" class="shapes shape-three" />
                        </div>
                        <p class="review-text">Over <span>150,000+ client</span> all over the world.</p>
                    </div>
                    <div class="col-lg-6 order-lg-first">
                        <div class="text-wrapper">
                            <h1><span>Make Meetings</span> with simple Schedueling.</h1>
                            <p class="sub-text">For hassale free event, we are here to help you by creating online event.</p>
                            <a href="/login" class="theme-btn-five">Let’s get started</a>
                        </div>
                        {/* <!-- /.text-wrapper --> */}
                    </div>
                </div>
            </div>

            <div class="fancy-feature-eight mt-160 md-mt-100">
                <div class="container">
                    <div class="bg-wrapper">
                        <div class="row justify-content-center">
                            <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-duration="1200">
                                <InfoCard 
                                    imgSrc='images/home/get_plan.svg'
                                    title='Get the plan'
                                    desc='Create customized popups and show the message at the lorem'
                                />
                                {/* <!-- /.block-style-thirteen --> */}
                            </div>
                            <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
                                <InfoCard
                                    class='style-border'
                                    imgSrc='images/home/create_avail.svg'
                                    title='Create & set your availabilities'
                                    desc='Collect website leads with embedded forms and integrate easily.'
                                />
                                {/* <!-- /.block-style-thirteen --> */}
                            </div>
                            <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200">
                                <InfoCard 
                                    imgSrc='images/home/share_invites.svg'
                                    title='Share your invites'
                                    desc='Send welcome email to your new subscribers with a code.'
                                />
                                {/* <!-- /.block-style-thirteen --> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- /.fancy-feature-eight --> */}
        </div>
        {/* <!-- /.hero-banner-four --> */}
        {/* <!-- 
			=============================================
				Fancy Text Block Nineteen
			============================================== 
			--> */}
            <div class="fancy-text-block-nineteen mt-200 mb-200 md-mb-150 md-mt-150">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-6" data-aos="fade-right" data-aos-duration="1200">
                        <img src={media57} alt="" class="m-auto" />
                    </div>
                    <div class="col-lg-6" data-aos="fade-left" data-aos-duration="1200">
                        <div class="text-wrapper">
                            <div class="client-info font-rubik">Over <span>150,000+ client</span></div>
                            <div class="title-style-five">
                                <h2><span>Start engaging</span> with your best leads.</h2>
                            </div>
                            <p class="font-rubik">Things go wrong. You’ll have questions. We understand. So we have people, not bots, on hand to help.</p>
                            <p class="font-rubik">We aim to answer any query in less than 10 minutes.</p>
                        </div>
                        {/* <!-- /.text-wrapper --> */}
                    </div>
                </div>
            </div>
            </div>
        {/* <!-- /.fancy-text-block-nineteen --> */}
        {/* <!--
			=====================================================
				Fancy Feature Nine
			=====================================================
			--> */}
        <div class="fancy-feature-nine">
            <div class="container">
                <div class="title-style-five text-center mb-60 md-mb-30">
                    <h6>GET STARTED IN MINUTES</h6>
                    <h2><span>Simple & easy steps for smart Schedueling.</span></h2>
                </div>

                <div class="row justify-content-center">
                    <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-duration="1200">
                        <GetStartCard 
                            imgSrc='images/home/join_platform.svg'
                            imgInfo='Join Platform illustration'
                            title='Join Our Platform'
                            desc='It only takes 5 minutes. Set up is smooth and simple fully lorem qui.'
                        />
                        {/* <!-- /.block-style-fourteen --> */}
                    </div>
                    <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
                        <GetStartCard
                            class='arrow-shape'
                            imgSrc='images/home/creat_manage_event.svg'
                            imgInfo='Create or Manage Event illustration'
                            title='Create or Manage your event'
                            desc='Choose a price package to suit you or pay per event. Sell on your website.'
                        />
                        {/* <!-- /.block-style-fourteen --> */}
                    </div>
                    <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200">
                        <GetStartCard 
                            imgSrc='images/home/connect_people.svg'
                            imgInfo='Connect People illustration'
                            title='Connect With People'
                            desc='Get payments, detailed data on events sales & message guests lorem'
                        />
                        {/* <!-- /.block-style-fourteen --> */}
                    </div>
                </div>
                {/* <!-- /.row --> */}
            </div>
        </div>
        {/* <!-- /.fancy-feature-nine --> */}
        {/* <!-- 
			=============================================
				Fancy Text block Six
			============================================== 
			--> */}
            <div class="fancy-text-block-six mt-250 md-mt-200">
            <WhyChooseUs />
        </div>
        {/* <!-- /.fancy-text-block-six --> */}
        {/* <!--
			=====================================================
				Pricing Section Three
			=====================================================
			--> */}
        <div class="pricing-section-three mt-250 mb-200 md-mt-150 md-mb-150">
            {/* <img src={shape107} alt="" class="shapes shape-one" />
            <img src={shape108} alt="" class="shapes shape-two" />
            <img src={shape109} alt="" class="shapes shape-three" />
            <img src={shape110} alt="" class="shapes shape-four" />
            <img src={shape111} alt="" class="shapes shape-five" />
            <img src={shape112} alt="" class="shapes shape-six" /> */}
            <div class="container">
                <div class="title-style-five text-center mb-50 md-mb-40">
                    <h6>Our Pricing</h6>
                    <div class="row">
                        <div class="col-lg-9 m-auto">
                            <h2>Unbeatable prices, no contracts, simple & easy</h2>
                        </div>
                    </div>
                </div>
                {/* Pricing Table Area */}
                <PricingTable />
                {/* <!-- /.pricing-table-area-three --> */}
            </div>
            {/* <!-- ./container --> */}
        </div>
        {/* <!-- /.pricing-section-three --> */}
        {/* <!--
			=====================================================
				Client Feedback Slider Three
			=====================================================
			--> */}
        <div class="client-feedback-slider-three mb-250 md-mb-150">
            <div class="container">
                <div class="title-style-five text-center mb-80 md-mb-50">
                    <div class="row">
                        <div class="col-lg-7 col-md-9 m-auto">
                            <h2><span>Check what’s our client </span>say about us.</h2>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xl-7 col-lg-9 m-auto">
                        <div class="testimonial-slider-wrapper">
                            <img src={icon65} alt="" class="m-auto" />
                            {/* Slide in Component */}
                            <ClientSlider />
                        </div>
                        {/* <!-- /.testimonial-slider-wrapper --> */}
                    </div>
                </div>
            </div>
            {/* <!-- /.container --> */}
            <img src={media59} alt="" class="shapes shape_1" />
            <img src={media60} alt="" class="shapes shape_2" />
            <img src={media61} alt="" class="shapes shape_3" />
            <img src={media62} alt="" class="shapes shape_4" />
            <img src={media63} alt="" class="shapes shape_5" />
            <img src={media64} alt="" class="shapes shape_6" />
        </div>
        {/* <!-- /.client-feedback-slider-three --> */}
        {/* <!--
			=====================================================
				Faq Section Four
			=====================================================
			--> */}
        <div class="faq-section-four">
            {/* <img src={shape120} alt="" class="shapes shape-one" />
            <img src={shape121} alt="" class="shapes shape-two" /> */}
            {/* Faq Section */}
            <Faq />
            {/* <!-- /.container --> */}
        </div>
        {/* <!-- /.faq-section-four --> */}
        {/* <!--
			=====================================================
				Fancy Short Banner Five
			=====================================================
			--> */}
        <div class="fancy-short-banner-five pt-150 pb-150 md-pt-100 md-pb-100">
            <SignupLanding />
        </div>
        {/* <!-- /.fancy-short-banner-five -->
        <!--
			=====================================================
				Footer Style Four
			=====================================================
			--> */}
            <Footer />
        {/* <!-- /.theme-footer-four --> */}
        {/* <!-- Scroll Top Button --> */}
        <ScrollTopBtn />

    </div>
        </>
    );
}
export default LHome;