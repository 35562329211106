import React from "react";

const GetStartCard = (props) => {
    return(
        <>
            <div class={`block-style-fourteen ${props.class}`}>
                <div class="illustration"><img src={props.imgSrc} alt={props.imgInfo} /></div>
                <div class="title">{props.title}</div>
                <p class="font-rubik">{props.desc}</p>
            </div>
        </>
    );
}

export default GetStartCard;