import React from "react";

const PricingCard = (props) => {
    return(
        <div className={`pr-table-wrapper ${props.class}`}>
            <div className={`pack-name font-slab ${props.eventClass}`}><span>{props.eventType}</span></div>
                <div className="price font-slab">{props.price}</div>
                    <p className="user-condition">{props.duration}</p>
                    <img src={props.lineImg} alt="" className="line" />
                    <ul>
                        <li>{props.felist1}</li>
                        <li>{props.felist2}</li>
                        <li className={props.disableClass1}>{props.fedisable1}</li>
                        <li className={props.disableClass2}>{props.fedisable2}</li>
                    </ul>
            <a href={props.link} className="subscribe-btn font-rubik">Subscribe Now</a>
        </div>
    );
}

export default PricingCard;