import { useState, useEffect, useCallback } from "react";
import Api from "../Api";
import Cards from "./Cards";
import { Link } from "react-router-dom";
import Loader from "./../../component/loader";
import base from "./../../helper/base";
import Toaster, { ToasterC } from "./../../component/Toaster";
import urlhelper from "./../../helper/urlhelper";
import { useAuthContext } from './../../contexts/AuthContext';
import ShapeLeft from '../../assets/images/shape_left.svg';
import ShapeRight from '../../assets/images/shape_right.svg';
import { Box, Button, ThemeProvider, Typography } from "@mui/material";
import { theme } from "../AppTheme/Theme";
const Main = () => {
  const [apidata, setapidata] = useState({ loader: true, cards: [] });
  const [check, setCheck] = useState({});
  const user_data = useAuthContext();
  let onHandler = useCallback(async (event) => {
    let key = event.target.name;
    let value = event.target.checked;
    try {
      let ch = await Api.toggle_event(key);
      let update = apidata.cards.map((value, index) => {
        if (parseInt(event.target.getAttribute("data-index")) === index) {
          value.status = value.status === 1 ? 0 : 1
        }
        return value;
      })
      setapidata({ ...apidata, update });
      Toaster.success(ch.error_msg);
      setCheck((pre) => {
        return { ...pre, [key]: value }
      });

    } catch (e) {
      Toaster.error(e);
    }


  }, [apidata]);


  const delete_card = useCallback(async (id, e_id) => {
    if (window.confirm("are you want you delete this")) {
      try {
        await Api.delete_event(e_id);
        let new_data = apidata.cards.filter((data, index) => {
          return index !== id;
        });
        Toaster.success("Event is deleted");
        setapidata({ ...apidata, cards: new_data });

      } catch (e) {
        console.log(e);
        Toaster.error("error occur! Please Try Again");
      }

    }

  }, [apidata]);
  useEffect(() => {
    let fetchData = async () => {
      try {
        let data = await Api.dashboard_data();

        if (data.length !== 0) {
          if (data.notification.length !== 0) {
            user_data.setNotification(data.notification);
          }
          if (data.event_data.length !== 0) {
            for (let x of data.event_data) {
              let key = base.encrypt(x._id);
              var obj = { ...obj, [key]: (x.status === 1) ? true : false }
            }
          }

        }
        setCheck(obj);
        setapidata({ loader: false, cards: data.event_data });
      } catch (e) {
        console.log(e);
      }
    }
    fetchData();

  }, []);

  useEffect(() => {
    return () => {
      setapidata({ loader: false, cards: [] });
    }
  }, []);


  if (!apidata.loader) {
    var cards
    if (apidata.cards !== undefined && apidata.cards.length !== 0) {
      cards = apidata.cards.map((key, index) => {
        let i = base.encrypt(key._id);
        return <Cards key={index} index={index} data={key} che={check[i]} fun={onHandler} delete={() => delete_card(index, i)} />
      });
    }
    else {
      cards = "there is no Events"
    }
    return (
      <>

        <section className="site-main py-5">
          <img className="shape left" src={ShapeLeft} alt="Left Shape" />
          <img className="shape right" src={ShapeRight} alt="Right Shape" />
          <div className="container">
          <ThemeProvider theme={theme}>
            <Box className="section-title" mb={6}>
              <div className="d-flex justify-content-between align-items-center flex-wrap">
                <Typography component="h1" variant="h1">Events</Typography>
                {/* <Link to={() => urlhelper.getAbsoluteUrl(user_data.user.username, "createevent")} className="btn green-btn mb-4">Create New Event Type</Link> */}
                <Button variant="contained" size="large" sx={{width: 'auto'}}><Link to={() => urlhelper.getAbsoluteUrl(user_data.user.username, "createevent")} style={{color: '#fff'}}>Create New Event Type</Link></Button>
              </div>
            </Box>
            </ThemeProvider>
            <div className="dashboard-content">
              <div className="row">

                {
                  cards
                }
              </div>
            </div>
          </div>
        </section>
        <ToasterC />

      </>
    )
  }
  else {
    return <Loader />
  }

};
export default Main;