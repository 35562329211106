import React, { useState, useEffect } from "react"
import { useParams } from "react-router-dom";
import Loader from "./../../component/loader"
import Days from "./eventsday"
import urlhelper from './../../helper/urlhelper';
import Api from "./../Api"
import time from "./../../helper/time"
import toaster, { ToasterC } from "./../../component/Toaster"
import { toast } from "react-toastify";
import { timeFromInt, timeToInt } from 'time-number';
import validate from './../../helper/validation';
import Er from "./../Error"
import { useHistory } from "react-router-dom";
import { useAuthContext } from './../../contexts/AuthContext'
import { Box, Button, Checkbox, FormControl, FormControlLabel, IconButton, InputAdornment, InputLabel, OutlinedInput, Stack, TextField, ThemeProvider, Typography } from "@mui/material";
import { theme } from "../AppTheme/Theme";


const Ques = (props) => {
    console.log(props)
    return (
        <>
            <Box mt={2} p={2} sx={{ border: '1px solid #CBD5E1', borderRadius: '5px' }}>
                <Stack direction="row" alignItems='center' spacing={2}>
                    {/* <input type="text" className="form-control" name="questions" value={props.v.ques} data-index={props.i} onChange={props.onHandler} placeholder={`Question ${props.i + 1}`} /> */}
                    <TextField sx={{ marginBottom: 0 }} label={`Question ${props.i + 1}`} name="questions" value={props.v.ques} onChange={props.onHandler} placeholder={`Question ${props.i + 1}`} inputProps={{ 'data-index': `${props.i}` }} fullWidth />
                    <FormControlLabel control={<Checkbox checked={props.v.required} />} label="Required" onChange={()=>props.requiredQuesToogle(props.i)}/>
                    <IconButton aria-label="delete" onClick={() => props.delete_question(props.i)}>
                        <i className="far fa-trash-alt text-error" ></i>
                    </IconButton>
                </Stack>
              
            </Box>

        </>
    )
}
const Fun = (props) => {
    const param = useParams();
    const history = useHistory();
    const user_data = useAuthContext();
    const [input, setinput] = useState({
        name: "",
        description: "",
        event_duration: "",
        meeting_pending: "",
        schedule_timings: { monday: [], tuesday: [], wednesday: [], thursday: [], friday: [], saturday: [], sunday: [] },
        questions: [],
        loader: true,
        time_limit: {},
        event_time_limit: { monday: {}, tuesday: {}, wednesday: {}, thursday: {}, friday: {}, saturday: {}, sunday: {} },
        id: "",
        loading: false,
        dayName:{ monday: [], tuesday: [], wednesday: [], thursday: [], friday: [], saturday: [], sunday: []},
        slotsInfo: { monday: {}, tuesday: {}, wednesday: {}, thursday: {}, friday: {}, saturday: {}, sunday: {} },
        allSlots:[]
    });
    const [error, setError] = useState({
        name: false,
        description: false,
        event_duration: false,
        meeting_pending: false,
    });
    const discard = () => {
        if (window.confirm("are you want to discard?")) {
            history.push(urlhelper.getAbsoluteUrl(user_data.user.username, "dashboard"));
        }
    }
    const onHandler = (event) => {
        let key = event.target.name;
        let value = event.target.value;
        if (key !== "questions") {
            setinput((pre) => {
                return { ...pre, [key]: value };
            })
        }
        else {

            let index = event.target.getAttribute("data-index");
            let new_v = input.questions;
            new_v[index] = {...new_v[index],ques:value};
            setinput((pre) => {
                return { ...pre, [key]: new_v };
            })
        }

    }
    const submit = async (event) => {
        event.preventDefault();
        let edit = false;
        if (param.id !== undefined) {
            edit = true
        }
        try {
            setinput((pre) => {
                return { ...pre, loading: true }
            })
            await validate.event.validate(input, { abortEarly: false });
            let data = await Api.create_event(input, edit);
            if (data.code === 200) {
                setinput((pre) => {
                    return { ...pre, loading: false }
                })
                toaster.success(data.error_msg);
            }
            else {
                setinput((pre) => {
                    return { ...pre, loading: false }
                })
                toaster.error(data);
            }
        } catch (e) {
            let obj = {
                name: false,
                description: false,
                event_duration: false,
                meeting_pending: false,
            };
            if (e.error !== undefined && e.errors.length !== 0) {
                for (let val of e.errors) {
                    let [key, value] = Object.entries(val)[0];
                    obj[key] = value;
                }
                setError((pre) => {
                    return { ...pre, ...obj }
                });
                setinput((pre) => {
                    return { ...pre, loading: false }
                })

            }
            else {
                setinput((pre) => {
                    return { ...pre, loading: false }
                })
                toaster.error();
            }


        }



    }

    const slot_check = (event) => {
        let name=event.target.name;
        if(input.schedule_timings[name].length){
           input.schedule_timings[name]=[];
           input.time_limit[name]={};
           input.slotsInfo[name]={occupyTime:{},availableTime:{}};
        }
        else{
            input.schedule_timings[name].push({from:"9:00 Am",to:"5:30 Pm"})
            input.time_limit[name]={from:"9:00 Am",to:"5:30 Pm"};
            input.slotsInfo[name]=time.getSlotsInfo(input.allSlots,input.schedule_timings[name],input.time_limit[name])
        }
        setinput((pre)=>{
           return {...pre,...input}
        })
        
    }
    const addSlot = (key) => {
        if(input.slotsInfo[key].availableTime.length){
          let newSlotInfo=time.addNewSlot(input.slotsInfo[key].availableTime,input.time_limit[key]);
          let slotInfo={...input.slotsInfo[key]};
          slotInfo={...slotInfo,availableTime:newSlotInfo.slotInfo.availableTime,occupyTime:[...slotInfo.occupyTime,newSlotInfo.slotInfo.occupyTime]}
          let scheduleTiming=[...input.schedule_timings[key]];
          scheduleTiming.push(newSlotInfo.newTimeSlot);
          setinput((pre)=>{
            return {...pre,
            slotsInfo:{...pre.slotsInfo,[key]:slotInfo},
            time_limit:{...pre.time_limit,[key]:newSlotInfo.timeLimit},
            schedule_timings:{...pre.schedule_timings,[key]:scheduleTiming}
        }
         })
        }
        else{
            toast.error("all the slot are ocupied againts this day");
        }
     }
    const deleteSlot = (name,index) => {
    let updatedValues=time.deleteSlot(input,name,index);
    setinput((pre)=>{
        return {...pre,...updatedValues}
     })
 }
    const sets = (dir,value,index,name) => {
        let updatedValues=time.setSlot(input,name,index,value,dir);
        setinput((pre)=>{
            return {...pre,...updatedValues}
         })
    };

    const onDuplicate=(event,day,index)=>{
        let newState={...input};
        
        if(newState.dayName[day].includes(event.target.value[0])){
            newState.dayName[day]=newState.dayName[day].filter((val)=>val !==event.target.value[0])
            newState.schedule_timings[event.target.value[0]]=[]
            newState.time_limit[event.target.value[0]]={}
            newState.slotsInfo[event.target.value[0]]={occupyTime:[],availableTime:[]}
            
        }
        else{
            newState.dayName[day].push(event.target.value[0])
            newState.schedule_timings[event.target.value[0]]=newState.schedule_timings[day]
            newState.time_limit[event.target.value[0]]=newState.time_limit[day]
            newState.slotsInfo[event.target.value[0]]=newState.slotsInfo[day]
        }
        for(let x in newState.dayName){
            if(x !==day){
                newState.dayName[x]=newState.dayName[x].filter(val=>val !==event.target.value[0])
            }
        }
         setinput((pre) => {
            return { ...pre, ...newState }
        })
    }
    const addquestion = () => {
        let new_v = [...input.questions, { ques: "",required:true }];
        
        setinput((pre) => {
            return { ...pre, questions: new_v };
        })
    }
    const delete_question = (index) => {
        if (input.questions.length !== 0) {
            let new_arr = input.questions.filter((v, i) => {
                return i !== index;
            });
            setinput((pre) => {
                return { ...pre, questions: new_arr };
            })
        }
    }
    const requiredQuesToogle=(index)=>{
    input.questions[index].required=!input.questions[index].required
    setinput((pre)=>{
        return {...pre,...input}
      })
    }

    useEffect(() => {
        Api.edit_event_data(param.id).then((data) => {
            if (data.code === 200) {
                if (data.edit_data) {
                    setinput((pre) => {
                        return {
                            ...pre,
                            time_limit: JSON.parse(data.availabilty.time_limit),
                            loader: false,
                            schedule_timings: JSON.parse(data.edit_data.schedule_timings),
                            id: data.edit_data._id,
                            name: data.edit_data.name,
                            meeting_pending: data.edit_data.meeting_pending,
                            description: data.edit_data.description,
                            event_duration: data.edit_data.event_duration,
                            questions: (data.edit_data.questions) ? JSON.parse(data.edit_data.questions) : [{ ques: "" }],
                            event_time_limit: JSON.parse(data.edit_data.event_time_limit),
                            slotsInfo:JSON.parse(data.edit_data.slotsInfo),
                            allSlots:data.slots

                        };
                    });
                }
                else {
                    setinput((pre) => {
                        return {
                            ...pre,
                            time_limit: JSON.parse(data.availabilty.time_limit),
                            event_time_limit: JSON.parse(data.availabilty.time_limit),
                            slotsInfo:JSON.parse(data.availabilty.slotsInfo),
                            allSlots:data.slots,
                            schedule_timings: JSON.parse(data.availabilty.schedule_timings),
                            loader: false,
                        }
                    });
                }

            }
            else {
                setinput((pre) => {
                    return { ...pre, loader: false };
                });
            }
        }).catch((e) => {
            console.log(e);
        })
    }, [param])
    if (!input.loader) {
        return (
            <>
                <section className="site-main py-5">
                    <div className="container">
                        <ThemeProvider theme={theme}>
                            <div className="section-title text-center">
                                <Typography component='h1' variant="h1">Create New Event</Typography>
                            </div>
                            <div className="creat-event-content">
                                <Box p={4} sx={{ maxWidth: 590, margin: '30px auto 0', borderRadius: '5px' }}>
                                    <Box className="form-col">
                                        <Box component='form' onSubmit={submit}>

                                            <TextField label='Event Name' placeholder="Event Name" name="name" value={input.name} onChange={onHandler} required={true} fullWidth />
                                            {(error.name) ? <Er value={error.name} /> : ""}
                                            <TextField label='Description' placeholder="Description:" name="description" value={input.description} onChange={onHandler} required={true} rows={4} fullWidth multiline />
                                            {(error.description) ? <Er value={error.description} /> : ""}

                                            {/* <input className="form-control mb-4" type="text" placeholder="Event Name" name="name" value={input.name} onChange={onHandler} required={true} /> */}
                                            {/* <textarea className="form-control mb-4" name="description" id="" rows="4" placeholder="Description:" value={input.description} onChange={onHandler} required={true}>{input.description}</textarea> */}
                                            <div className="input-icon d-flex flex-wrap justify-content-between">
                                                <div className="col-md-6 px-0 pr-md-2">
                                                    <div className="label-top form-group position-relative mb-4">
                                                        <input className="form-control pl-5" name="event_duration" type="number" placeholder="30 Mints" value={input.event_duration} onChange={onHandler} required={true} />
                                                        <label htmlFor="">Event duration(Mints):</label>
                                                        <i className="fal fa-clock"></i>
                                                    </div>
                                                </div>
                                                {(error.event_duration) ? <Er value={error.event_duration} /> : ""}
                                                <div className="col-md-6 px-0 pl-md-2">
                                                    <div className="label-top form-group position-relative mb-4">
                                                        <input className="form-control pl-5" type="number" placeholder="15 Mints" name="meeting_pending" value={input.meeting_pending} onChange={onHandler} required={true} />
                                                        <label htmlFor="">Meeting pending:</label>
                                                        <i className="fal fa-clock"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            {(error.meeting_pending) ? <Er value={error.meeting_pending} /> : ""}
                                            <div className="event-days">
                                                {

                                                    Object.keys(input.schedule_timings).map((val, index) => {
                                                        
                                                        return <Days key={index} name={val} scheduleTiming={input.schedule_timings[val]} slotInfo={input.slotsInfo[val]} slot_check={slot_check} addSlot={addSlot} deleteSlot={deleteSlot} sets={sets} days={input.dayName} onDuplicate={onDuplicate}/>
                                                    })
                                                }
                                                <div className="event-quest">
                                                    <Typography component='h3' variant="body2" color='secondary' sx={{ fontWeight: 500, maxWidth: '480px' }}>Ask one or more questions to the person scheduling this event. (optional)</Typography>
                                                    <Button sx={{ marginTop: 2, textTransform: 'capitalize' }} startIcon={<i className="fal fa-plus"></i>} onClick={addquestion}>Add Question</Button>
                                                    {
                                                        (input.questions.length !== 0) ?
                                                            input.questions.map((v, i) => {
                                                             return <Ques key={i} v={v} i={i} delete_question={delete_question} onHandler={onHandler} requiredQuesToogle={requiredQuesToogle}/>
                                                            }) : ""
                                                    }

                                                </div>
                                                <div className="create-btn text-right">
                                                    <button type="button" className="btn btn-delete" onClick={discard}>Discard</button>
                                                    {/* <LoadingButton
                                                        endIcon={<SendIcon />}
                                                        loading={(input.loading) ? true : false }
                                                        loadingPosition="end"
                                                        variant="contained"
                                                    ></LoadingButton> */}
                                                    <button type="submit" className="btn btn-edit" disabled={(input.loading) ? true : false}>Create Event{(input.loading) ? <div className="spinner-border"></div> : ""} </button>
                                                </div>
                                            </div>
                                        </Box>
                                    </Box>
                                </Box>
                            </div>
                        </ThemeProvider>
                    </div>
                </section>
                <ToasterC />
            </>
        )
    }
    else {
        return (
            <>
                <Loader />
            </>
        )
    }
}
export default Fun;