import google from "../../assets/images/google-calendar.svg";
import outlook from "../../assets/images/outlook-calendar.svg";
import googleMeet from '../../assets/images/google-meet-icon.svg';
import ZoomIcon from "../../assets/images/zoom-icon.svg";
import toaster, { ToasterC } from "./../../component/Toaster"
import { useAuthContext } from './../../contexts/AuthContext'
import { useLocation } from "react-router-dom";
import urlhelper from './../../helper/urlhelper';
import Loader from "./../../component/loader"
import api from "./../Api"
import { useState, useEffect } from "react";
import { Box, Button, Checkbox, Chip, FormControlLabel, FormGroup, Grid, Radio, Stack, Switch, ThemeProvider, Typography, useMediaQuery, useTheme } from '@mui/material';
import { theme } from "../AppTheme/Theme";
import Storage from "../../helper/Storage"
function useQuery() {
    return new URLSearchParams(useLocation().search);
}
const Fun = () => {
    const [googleToken,setGoogletoken]=useState("");
    const handler = async(event,email) => {
        try{
            event.preventDefault();
            let result=await api.setPrimaryCalender(email);
            let obj=Storage.setlocalstorage(result.data)
             user_data.updateState(obj);
            toaster.success("Primary Account has been updated");
        }catch(e){
          toaster.error("error occur")
        }
        
    }
    const toggleMeet = async(event) => {
        try{
            event.preventDefault();
            let result=await api.toggleGoogleMeet();
            let obj=Storage.setlocalstorage(result.data)
             user_data.updateState(obj);
            toaster.success(result.msg);
        }catch(e){
            console.log(e)
          toaster.error("error occur")
        }
        
    }
    let query = useQuery();
    const [loader, setloader] = useState(true);
    let par = query.get("code");
    const removeCalender = async(email) => {
       try{
        let result=await api.removeCalender(email);
        let obj=Storage.setlocalstorage(result.data)
        user_data.updateState(obj);
        toaster.success("calender has been removed")

       }catch(e){
        toaster.error("error occur")
       }
    }
    const zoom_int = () => {
        window.location.href = `https://zoom.us/oauth/authorize?response_type=code&client_id=${process.env.REACT_APP_zoom_client_id}&redirect_uri=${process.env.REACT_APP_App_url}${urlhelper.getAbsoluteUrl(user_data.user.username, "integrations")}`;
    }
    const user_data = useAuthContext();
     
   
    useEffect(() => {
        if (par !== null && par !== undefined) {
            api.zoom_integration(par).then((data) => {
                
                user_data.updateState(data);
                toaster.success("Zoom has been integrated");
            }).catch((e) => {
                toaster.error("Error Occur! Please Try Again");
            })
            setloader(false);
        }
        else {
            setloader(false);
        }
    }, []);
    useEffect(()=>{
        let script1=document.createElement("script");
        let script2=document.createElement("script");
        script1.src="https://apis.google.com/js/api.js"
        script2.src="https://accounts.google.com/gsi/client"
        script1.async=true;
        script2.async=true;
        document.body.appendChild(script1);
        document.body.appendChild(script2);
        script1.addEventListener("load",()=>{
            window.gapi.load('client', ()=>{
                window.gapi.client.init({
                    apiKey: process.env.REACT_APP_google_api,
                    discoveryDocs:["https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"],
                    }).then((data)=>{
                    console.log(data);
                  }).catch((e)=>{
                    alert(e);
                  });
            });
            
        })
        script2.addEventListener("load",()=>{
        
              let tokenClient = window.google.accounts.oauth2.initTokenClient({
                client_id: process.env.REACT_APP_google_client_id,
                scope: "https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/calendar",
                plugin_name: "chat",
                callback: '', 
              });
              setGoogletoken(tokenClient);
              
         })
    },[])

    const signin = (e) => {
        e.preventDefault();
        
        googleToken.requestAccessToken({prompt: 'consent'});
        googleToken.callback = async (data) => {
            try{
            if (data.error !== undefined) {
                    throw (data.error);
                  }
                let res=await api.addCalender("google",data.access_token);
                let obj= Storage.setlocalstorage(res.data);
                user_data.updateState(obj);
                 toaster.success(res.msg);
            }catch(e){
                console.log(e)
                toaster.error(e);
            }
           
            
            
          };
  
      
    }
    
    const resTheme = useTheme();
    const isMob = useMediaQuery(theme.breakpoints.down('md'));
    if (!loader) {
        return (
            <>
                <section className="site-main">
                    <div className="container">

                        <div className="page-content connect-calnder">
                            <div className="main-row row justify-content-center align-items-center">
                                <div className="page-col mw-100 pt-md-4 mt-md-4">

                                    <ThemeProvider theme={theme}>
                                        <Box className="section-title">
                                            <Typography component="h1" variant="h1">Integerations</Typography>
                                        </Box>
                                        <Box className="form-col" mt={3}>
                                            <Box component='form' action="#">
                                                {/* Connect Calender */}
                                                <Box className="connect-calnder2" p={isMob ? 2 : 5} sx={{ boxShadow: '0px 6px 30px rgba(0, 0, 0, 0.06)', borderRadius: '5px' }}>
                                                    {/* COnnect Calenders */}
                                                    <Grid container m={0} justifyContent='space-between' sx={{ width: '100%' }}>
                                                        {/* COlumn 1 */}
                                                        <Grid md={6} m={0} sx={{ width: '100%', marginBottom: { xs: '30px', md: '0' } }}>
                                                            <button className="btn text-left d-block p-0 w-100" type="button" onClick={signin} >
                                                                <Grid container spacing={2} p={2} m={0} justifyContent='space-between' alignItems={isMob ? null : 'center'} sx={{ border: '2px solid #1155ED', borderRadius: '5px', width: '100%' }}>
                                                                    <Grid xs={2}>
                                                                        <img src={google} alt="google" className="w-100" />
                                                                    </Grid>
                                                                    <Grid xs={10} pl={1}>
                                                                        <Typography color='secondary' component='h3' variant="h3">Add Google Account</Typography>
                                                                        <Typography color='secondary' component='p' variant="body1">Connect to google Account</Typography>
                                                                    </Grid>
                                                                </Grid>
                                                            </button>
                                                        </Grid>
                                                        {/* Column 2 */}
                                                        <Grid md={6} m={0} sx={{ width: '100%' }}>
                                                            <button className="btn text-left d-block p-0 w-100" type="button" disabled>
                                                                <Grid container spacing={2} p={2} m={0} justifyContent='space-between' alignItems={isMob ? null : 'center'} sx={{ border: '1px solid #CBD5E1', borderRadius: '5px', width: '100%' }}>
                                                                    <Grid md={2} xs={2}>
                                                                        <img src={outlook} alt="Outlook" className="w-100" />
                                                                    </Grid>
                                                                    <Grid md={6} xs={10} pl={1}>
                                                                        <Typography color='secondary' component='h3' variant="h3">Outlook</Typography>
                                                                        <Typography color='secondary' component='p' variant="body1">Outlook coming soon</Typography>
                                                                    </Grid>
                                                                    <Grid sm={4} mt={isMob ? 3: 0} mx={isMob ? 'auto' : null}>
                                                                        <Button variant="contained" color="secondary" sx={{ marginLeft: 'auto', display: 'block' }}>Coming soon</Button>
                                                                    </Grid>
                                                                </Grid>
                                                            </button>
                                                        </Grid>
                                                    </Grid>
                                                    {/* Connected Accounts */}
                                                    <Grid container spacing={3} m={0} mt={5} justifyContent='space-between' sx={{ width: '100%' }}>
                                                        <Grid xs={12}>
                                                            <Typography component='h2' variant="h3" sx={{ fontWeight: 400 }}>Connected Accounts</Typography>
                                                        </Grid>
                                                        {user_data.user.google_calender.map((val)=>{
                                                            return <>
                                                            <Grid container spacing={3} m={0} mt={3} justifyContent='space-between' alignItems='center' sx={{ width: '100%', border: '1px solid #CBD5E1', borderRadius: '5px' }}>
                                                            <Grid md={6} sm={7} m={0} sx={{ width: '100%' }}>
                                                                <Grid container spacing={2} p={2} m={0} justifyContent='space-between' alignItems={isMob ? null : 'center'} sx={{ width: '100%' }}>
                                                                    <Grid xs={2} sx={{ maxWidth: { sm: '47px', md: 'unset' } }}>
                                                                        <img src={google} alt="google" className="w-100" />
                                                                    </Grid>
                                                                    <Grid xs={10} pl={1}>
                                                                        <Typography color='secondary.dark' component='h3' variant="h5" sx={{ fontWeight: '400' }}>{val.email}</Typography>
                                                                        <Typography color='primary.dark' component='p' variant="body1">{val.primary && "Your events will be added to this calendar"}</Typography>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid md={6} sm={5} m={0} pr={2} pb={isMob ? 2 : 0} pl={isMob ? 2 : 0} sx={{ width: '100%' }}>
                                                                <Stack spacing={2} direction="row" justifyContent='flex-end'>
                                                                    <FormGroup>
                                                                        <FormControlLabel value="First Account" color="primary.dark" name="accountSelect" onChange={(e)=>handler(e,val.email)} control={<Radio  checked={val.primary} />} label="Primary" />
                                                                    </FormGroup>
                                                                    <Button variant="outlined" color="error" onClick={() => removeCalender(val.email)} sx={{ textTransform: 'unset', fontWeight: 600 }} startIcon={<i className="far fa-trash"></i>}> Delete</Button>
                                                                </Stack>
                                                            </Grid>
                                                        </Grid>
                                                            </>
                                                        })}
                                                        
                                                       
                                                    </Grid>
                                                    {/* <div className="sign-with-client mt-0 pt-0 border-0">
                                                        <div className="client-btn d-flex justify-content-between flex-wrap align-items-start">
                                                            <button className="btn text-center" type="button" onClick={signin} disabled={(user_data.user.default_calender !== undefined && user_data.user.default_calender === "google") ? true : false}><img src={google} alt="google" />
                                                                <span className="d-bock">{(user_data.user.google_calender_email !== null) ? user_data.user.google_calender_email : "Connect to Google Calender"}</span>
                                                                <div>
                                                                    {(user_data.user.google_calender_email !== null) ? <button type="button" className="btn btn-delete mt-4 px-4" onClick={() => canecl_calender("google")}>cancel</button> : ""}

                                                                </div>
                                                            </button>


                                                            <button className="btn text-center" type="button"><img src={outlook} alt="outlook" />
                                                                <span className="d-bock">Connect to OutLook Calender</span>
                                                            </button>

                                                        </div>
                                                    </div> */}

                                                </Box>
                                                {/* Conference Apps */}
                                                <Box className="conference_apps" mt={isMob ? 4 : 6} p={isMob ? 2 : 5} sx={{ boxShadow: '0px 6px 30px rgba(0, 0, 0, 0.06)', borderRadius: '5px' }}>
                                                    <Grid container spacing={3} m={0} justifyContent='space-between' sx={{ width: '100%' }}>
                                                        <Grid xs={12}>
                                                            <Typography component='h2' variant="h3" sx={{ fontWeight: 400 }}>Enable Conferences</Typography>
                                                        </Grid>
                                                        {/* App 1 */}
                                                        <Grid container spacing={3} m={0} mt={3} justifyContent='space-between' alignItems='center' sx={{ width: '100%', border: '1px solid #CBD5E1', borderRadius: '5px' }}>
                                                            <Grid lg={6} sm={9} m={0} sx={{ width: '100%' }}>
                                                                <Grid container spacing={2} p={2} m={0} justifyContent='space-between' alignItems={isMob ? null : 'center'} sx={{ width: '100%' }}>
                                                                    <Grid md={2} xs={2}>
                                                                        <img src={googleMeet} alt="google" className="w-100" />
                                                                    </Grid>
                                                                    <Grid md={10} xs={10} pl={1} sx={{ paddingLeft: { sm: '15px' } }}>
                                                                        <Typography color='secondary.dark' component='h3' variant="h5" sx={{ fontWeight: '600' }}>Google Meet <Chip label="Enable" color="success" sx={{ color: '#fff', marginLeft: '15px' }} /></Typography>
                                                                        <Typography color='secondary.dark' component='p' variant="body1">Enable video conferences in your booking</Typography>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid lg={6} sm={3} m={0} pr={2} pb={isMob ? 2 : 0} sx={{ width: '100%' }}>
                                                                <Stack spacing={2} direction="row" justifyContent='flex-end'>
                                                                    <FormControlLabel control={<Switch checked={user_data.user.googleMeet===1}  onChange={toggleMeet} disabled/>} sx={{ margin: 0 }} />
                                                                </Stack>
                                                            </Grid>
                                                        </Grid>
                                                        {/* app 2 */}
                                                        <Grid container spacing={3} m={0} mt={3} justifyContent='space-between' alignItems='center' sx={{ width: '100%', border: '1px solid #CBD5E1', borderRadius: '5px', pointerEvents: 'none', opacity: '0.5' }}>
                                                            <Grid lg={6} sm={9} m={0} sx={{ width: '100%' }}>
                                                                <Grid container spacing={2} p={2} m={0} justifyContent='space-between' alignItems='center' sx={{ width: '100%' }}>
                                                                    <Grid xs={2}>
                                                                        <img src={ZoomIcon} alt="google" className="w-100" />
                                                                    </Grid>
                                                                    <Grid xs={10} pl={1} sx={{paddingLeft: {sm: '15px'}}}>
                                                                        <Typography color='secondary.dark' component='h3' variant="h5" sx={{ fontWeight: '600' }}>Zoom</Typography>
                                                                        <Typography color='secondary.dark' component='p' variant="body1">Zoom coming soon</Typography>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid lg={6} sm={3} m={0} pr={2} pl={isMob ? 2 : 0} pb={isMob ? 2 : 0} sx={{ width: '100%' }}>
                                                                <Stack spacing={2} direction="row" justifyContent='flex-end'>
                                                                    <Button variant="contained" color="secondary">Coming soon</Button>
                                                                </Stack>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </ThemeProvider>
                                </div>
                            </div>
                            {/* <div className="main-row row justify-content-center align-items-center">
                                <div className="page-col">

                                    <div className="section-title text-center">
                                        <h1><span className="text-green d-block">Awesome!</span> Now connect your Zoom And Google Meet</h1>
                                        <p>Connect one or more calendars to automatically check for availability and register your new bookings into your calendars.</p>
                                    </div>

                                    <div className="form-col connect-calnder2">
                                        <form action="#">
                                            <div className="sign-with-client mt-0 pt-0 border-0">
                                                <div className="client-btn d-flex justify-content-between flex-wrap align-items-start">
                                                    <button className="btn text-center" type="button" onClick={zoom_int} disabled={(user_data.user.meeting_integration === "zoom") ? true : false}><img src="https://d24cgw3uvb9a9h.cloudfront.net/static/92961/image/new/ZoomLogo.png" alt="google" /> */}
                            {/* <span className="d-bock">{(user_data.user.google_calender_email !==null)?user_data.user.google_calender_email:"Connect to Google Calender"}</span>
                                        <div>
                                            {(user_data.user.google_calender_email !==null)?<button type="button" className="btn btn-delete mt-4 px-4" onClick={()=>canecl_calender("google")}>cancel</button>:""}
                                       
                                        </div> */}
                            {/* </button>


                                                    <button className="btn text-center" type="button"><img src={outlook} alt="outlook" />
                                                        <span className="d-bock">Connect to OutLook Calender</span> */}
                            {/* <div>
                                        <button type="button" className="btn btn-delete">cancel</button>
                                        </div> */}
                            {/* </button>

                                                </div>
                                            </div>
                                        </form>
                                    </div>

                                </div>
                            </div> */}

                        </div>
                    </div>
                </section>
                <ToasterC />
            </>
        )
    }
    else {
        return <Loader />
    }
}
export default Fun;