import React, { useState, useCallback, useEffect } from 'react';
import validate from './../helper/validation';
import urlhelper from './../helper/urlhelper';
import Er from "./Error";
import api from "./Api";
import GoogleSocialLogin from "./../helper/GoogleSocialLogin";
// import FacebookSocialLogin from "./../helper/FacebookSocialLogin"
import Storage from "./../helper/Storage";
import { useAuthContext } from './../contexts/AuthContext';
import { Link, NavLink, useHistory, useParams } from "react-router-dom";
import Toaster, { ToasterC } from "./Toaster";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { ThemeProvider } from '@emotion/react';
import { theme } from './AppTheme/Theme';
import { Box, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const Fun = () => {
    const { email } = useParams();
    const history = useHistory();
    const user_data = useAuthContext();
    const [btn_dis, setbtndis] = useState(false);
    const [input, setIput] = useState({ email: (email !== undefined ? email : ""), password: "" });
    const [error, setError] = useState({ email: false, password: false })
    const [type, settype] = useState("password");
    const changetype = useCallback(() => {
        settype((prevState) => {
            if (prevState === "password") {
                return "text";
            }
            else {
                return "password"
            }
        })
    }, []);
    const onsubmit = async (e) => {
        e.preventDefault();
        setbtndis(true);
        try {
            let valid = await validate.login.validate(input, { abortEarly: false });
            let result = await api.login(valid);
            Storage.setCookies(result);
            let local = Storage.setlocalstorage(result.data);
            user_data.updateState(local);
            Toaster.success("login Successfully");
            history.push(urlhelper.getAbsoluteUrl(local.username, "dashboard"));
        } catch (e) {
            console.log(e);
            let obj = {
                email: false,
                password: false,
            };
            for (let val of e.errors) {
                let [key, value] = Object.entries(val)[0];
                obj[key] = value;
            }
            // Toaster.error("");
            setError((pre) => {
                return { ...pre, ...obj }
            });
        }
        setbtndis(false);
    }
    const handler = (event) => {
        let { name, value } = event.target;
        setIput((prevState) => {
            return { ...prevState, [name]: value }
        });
    };
    useEffect(() => {
        return () => {
            setbtndis(false);
            settype(false);
            setIput(false);
            setError(false);

        }
    }, [])

    return (<>
        <section className="site-main">
            <div className="container">
                <div className="page-content">
                    <div className="main-row row justify-content-center">
                        <div className="page-col">
                            <div className="section_link d-flex justify-content-between px-5">
                                <NavLink to='/login'>Login</NavLink>
                                <NavLink to='/register'>Sign up</NavLink>
                            </div>
                            <div className="sign-with-client">
                                <div className="client-btn d-flex justify-content-between flex-wrap">
                                    <GoogleSocialLogin fun={setError} />
                                    {/* <FacebookSocialLogin fun={setError} /> */}
                                </div>
                                <p>OR</p>
                            </div>
                            <ThemeProvider theme={theme}>
                                <Box className="form-col login">
                                    <Box component='form' onSubmit={onsubmit}>
                                        {/* Email */}
                                        <TextField label='Email' type="email" placeholder="Email" style={(error.email) ? { borderColor: "red" } : {}} value={input.email} onChange={handler} name="email" required fullWidth />
                                        {(error.email) ? <Er value={error.email} /> : ""}
                                        {/* Password */}
                                        <FormControl variant='outlined' fullWidth>
                                            <InputLabel htmlFor="password">Password</InputLabel>
                                            <OutlinedInput
                                                type={type}
                                                label='Password'
                                                placeholder="Password"
                                                value={input.password}
                                                onChange={e => { handler(e) }}
                                                sx={(error.password) ? { borderColor: "red" } : {}}
                                                name="password"
                                                required
                                                fullWidth
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={changetype}
                                                            // onMouseDown={handleMouseDownPassword}
                                                            edge="end"
                                                        >
                                                            {(type === 'password') ? <Visibility /> : <VisibilityOff />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                            />
                                        </FormControl>
                                        {(error.password) ? <Er value={error.password} /> : ""}
                                        <Box className="forget-pwd d-flex justify-content-between align-items-center mb-4 pb-md-1">
                                            <FormGroup>
                                                <FormControlLabel sx={{ color: '#415980 !important', fontSize: '14px', fontWeight: 500 }} control={<Checkbox />} label="Remember Me" />
                                            </FormGroup>
                                            <Link to="/reset-password">Forget Password?</Link>
                                        </Box>
                                        {(btn_dis) ? <div className="spinner-border" style={{ display: "flex", position: "relative", marginLeft: "90%", top: "40px" }}></div> : ""}
                                        <input className="form-control" type="submit" value="Login" disabled={btn_dis ? "disabled" : ""} />
                                    </Box>

                                    {/* <form action="#" onSubmit={onsubmit}>
                                    <label htmlFor="email">Email</label>
                                    <input className="form-control mb-3" type="email" placeholder="Email" style={(error.email) ? { borderColor: "red" } : {}} value={input.email} onChange={handler} name="email" required />
                                    {(error.email) ? <Er value={error.email} /> : ""}
                                    <div className="pwd-field-eye mb-3">
                                        <label htmlFor="password">Password</label>
                                        <div className="position-relative">
                                            <input className="form-control" type={type} value={input.password} onChange={e => { handler(e) }} placeholder="Password" style={(error.password) ? { borderColor: "red" } : {}} name="password" required />
                                            <button type="button" className="btn p-0" onClick={changetype}><i className="fal fa-eye"></i></button>
                                        </div>
                                    </div>
                                    {(error.password) ? <Er value={error.password} /> : ""}
                                    <div className="forget-pwd d-flex justify-content-between align-items-center mt-2 mb-3 pb-1">
                                        <ThemeProvider theme={theme}>
                                            <FormGroup>
                                                <FormControlLabel control={<Checkbox />} label="Remember Me" sx={{color : '#415980 !important', fontSize: '14px'}} />
                                            </FormGroup>
                                        </ThemeProvider>
                                        <Link to="/reset-password">Forget Password?</Link>
                                    </div>
                                    {(btn_dis) ? <div className="spinner-border" style={{ display: "flex", position: "relative", marginLeft: "90%", top: "40px" }}></div> : ""}
                                    <input className="form-control" type="submit" value="Login" disabled={btn_dis ? "disabled" : ""} />
                                </form> */}
                                </Box>
                            </ThemeProvider>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <ToasterC />
    </>)
};
export default Fun;