import React from "react";
import { Link } from "react-router-dom";

const TeamCard = (props) => {
    return (
        <>
            <div class="team-member">
                <Link className='d-block' href={props.link}>
                    <div class="img-holder"><img src={props.imgSrc} alt={props.imgInfo} /></div>
                    <h6 class="name">{props.name}</h6>
                    <div class="position">{props.position}</div>
                </Link>
            </div>
        </>
    );
}

export default TeamCard;