import React from "react";

const SignupLanding = () => {
    return (
        <>
            <div class="container">
                <div class="title-style-five text-center">
                    <h2><span>Sign up & get started</span></h2>
                </div>
                <div class="row">
                    <div class="col-xl-7 col-lg-8 col-md-9 m-auto">
                        <p class="font-rubik sub-heading">Our event platform is made for you - & loved by everyone lorem ipsum.</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xl-7 col-lg-8 col-md-10 m-auto">
                        <form action="#">
                            <input type="text" placeholder="Email address" />
                            <button><span>Start Trial</span></button>
                        </form>
                        <p class="info-text">Already a member? <a href="/">Sign in.</a></p>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SignupLanding;