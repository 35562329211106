import TimePicker from './../TimePicker';
import React from "react"
import { Checkbox, FormControlLabel } from '@mui/material';
import moment from 'moment';
const Fun = (props) => {
    console.log(props);
    return (
        <>
            <div className={`event-day-${props.index}`}>
                <div className="day-time-container d-flex justify-content-between align-items-center flex-wrap">
                    {/* <input type="checkbox" name={props.name} className="d-none" id={`day${props.index}`} onChange={props.slot_check} checked={(props.data.length === 0) ? false : true} />
                    <label htmlFor={`day${props.index}`} className="d-block">
                        <p style={{ textTransform: "capitalize" }}>{props.name}</p>
                    </label> */}
                    <FormControlLabel className='text-capitalize' sx={{color: '#0F172A', fontSize: 14}} label={props.name} control={<Checkbox name={props.name} id={`day${props.index}`} onChange={props.slot_check} checked={props.slotInfo.occupyTime.length ?true:false} />} />
                    {props.slotInfo.occupyTime.length > 0 ?
                     props.slotInfo.occupyTime.map((v, i) => {
                        let newArr=[...v,...props.slotInfo.availableTime];
                        newArr.sort((a,b)=>a-b);
                       
                          return <>
                         <div className="day-time d-flex">
                         <TimePicker index={i} value={newArr} from={moment(`${moment(new Date('2019/06/01')).startOf("day").format("MM-DD-YYYY")} ${props.scheduleTiming[i].from}`).unix()} to={moment(`${moment(new Date('2019/06/01')).startOf("day").format("MM-DD-YYYY")} ${props.scheduleTiming[i].to}`).unix()} dir={"from"} name={props.name} sets={props.sets}/>
                         <span>to</span>
                         <TimePicker index={i} value={newArr} from={moment(`${moment(new Date('2019/06/01')).startOf("day").format("MM-DD-YYYY")} ${props.scheduleTiming[i].from}`).unix()} to={moment(`${moment(new Date('2019/06/01')).startOf("day").format("MM-DD-YYYY")} ${props.scheduleTiming[i].to}`).unix()} dir="to"  name={props.name} sets={props.sets}/>
                         {i === 0 ?
                         <button type="button" className="btn plus-btn p-0" onClick={()=>props.addSlot(props.name)}><i className="fal fa-plus"></i></button> :
                         <button type="button" className="btn plus-btn p-0" onClick={()=>props.deleteSlot(props.name,i)}><i className="far fa-trash-alt text-error"></i></button>
                       }
                        </div>
                        </>
                        })
                        : <div className="day-time">
                            <p>Unavailable</p>
                        </div>

                    }


                </div>

            </div>





        </>
    )
}
export default React.memo(Fun);