import React from "react";
import { Fancybox } from "@fancyapps/ui/src/Fancybox/Fancybox.js";
// import "@fancyapps/ui/src/Fancybox/Fanxybox.scss";
import CounterSec from "./LaComponents/CounterSec";
import TeamWrapper from "./LaComponents/TeamWrapper";
import WhyChooseUs from "./LaComponents/Whychoose";
import BlogCard from "./LaComponents/BlogCard";
import SignupLanding from "./LaComponents/SignupLanding";
import Footer from "./LaComponents/Footer";
import ScrollTopBtn from "./LaComponents/ScrollTopBtn";

// Import Css
import '../../assets/css/framework.css';
import '../../assets/css/responsive.css';
import '../../assets/css/custom-animation.css';
// Import Js
import '../../assets/js/theme'
// Import Images
// import shape124 from '../../assets/images/landing/shape/124.svg';
// import shape125 from '../../assets/images/landing/shape/125.svg';
// import shape129 from '../../assets/images/landing/shape/129.svg';
// import shape130 from '../../assets/images/landing/shape/130.svg';
import icon66 from '../../assets/images/landing/icon/66.svg';
import blog32 from '../../assets/images/landing/blog/media_32.jpg';
import media65 from '../../assets/images/landing/media/img_65.png';






const About = () => {
    return (
        <>
        <div class="main-page-wrapper p0">
        {/* <!-- 
			=============================================
				Fancy Text block Twenty
			============================================== 
		--> */}
            <div class="fancy-text-block-twenty">
                {/* <img src={shape124} alt="" class="shapes shape-one" />
                <img src={shape125} alt="" class="shapes shape-two" /> */}
                <div class="container">
                    <h1 class="title font-slab">About us</h1>
                    <div class="fancy-video-box-one mb-130 md-mb-70">
                        <img src={media65} alt="" class="main-img" />
                        <a data-fancybox="" href="https://www.youtube.com/embed/aXFSJTjVjw0" class="fancybox video-button d-flex align-items-center"><img src={icon66} alt="" /></a>
                    </div>
                    {/* <!-- /.fancy-video-box-one --> */}

                    <div class="row">
                        <div class="col-lg-5" data-aos="fade-right" data-aos-duration="1200">
                            <div class="client-info font-rubik">Over <span>150,000+ client</span></div>
                            <div class="title-style-five">
                                <h2><span>Best event</span><br /> & event platform platform.</h2>
                            </div>
                        </div>
                        <div class="col-lg-6 ml-auto" data-aos="fade-left" data-aos-duration="1200">
                        {/* <!-- Nav tabs --> */}
                        <ul class="nav nav-tabs md-mt-50">
                            <li class="nav-item">
                                <a class="nav-link font-slab active" data-toggle="tab" href="#our-story">Our Story</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link font-slab" data-toggle="tab" href="#our-mission">Our Mission </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link font-slab" data-toggle="tab" href="#our-vision">Our Vision</a>
                            </li>
                        </ul>
                        {/* <!-- Tab panes --> */}
                        <div class="tab-content mt-20">
                            <div class="tab-pane active" id="our-story">
                                <p class="font-rubik">Lorem ipsum dolor sit amet, consecteur adipiscng elit sed do eiusmod tempor non sunt</p>
                                <p class="font-rubik">aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore nulla pariaturExcepteur sint occaecat cu proident, sunter in culpa qui officia deserunt mollit .</p>
                            </div>
                            <div class="tab-pane fade" id="our-mission">
                                <p class="font-rubik">Lorem Ipsum is simply dummy text of the printing and typesetting industry</p>
                                <p class="font-rubik">Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor.</p>
                            </div>
                            <div class="tab-pane fade" id="our-vision">
                                <p class="font-rubik">There are many variations of passages of Lorem Ipsum available, but the majority,</p>
                                <p class="font-rubik">Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* <!-- /.fancy-text-block-twenty --> */}
        {/* <!--
			=====================================================
				Counter With Icon One
			=====================================================
			--> */}
        <div class="counter-with-icon-one">
            <div className='container'>
                <div class="border-top pt-50 md-pt-10"></div>
            </div>
            <CounterSec />
        </div>
        {/* <!-- 
			=============================================
				Team Section
			============================================== 
			--> */}
        <div class="team-section-four mt-250 md-mt-150">
            {/* <img src={shape129} alt="" class="shapes shape-one" />
            <img src={shape130} alt="" class="shapes shape-two" /> */}
            <div class="container">
                <div class="title-style-five text-center mb-90 md-mb-60">
                    <h6>Our Team</h6>
                    <h2>The team behind deski </h2>
                </div>
            {/* <!-- /.team-wrapper --> */}
            <TeamWrapper />
            </div>
        </div>
        {/* <!-- 
			=============================================
				Why Choose Us
			============================================== 
			--> */}
            <div class="fancy-text-block-six mt-250 pt-50 md-mt-130">
                <WhyChooseUs />
            </div>
            {/* <!--
			=====================================================
				Feature Blog Four
			=====================================================
			--> */}
        <div class="feature-blog-four mt-250 md-mt-200">
            <div class="container">
                <div class="title-style-five text-center mb-70 md-mb-50">
                    <h6>News & Updates</h6>
                    <h2>Latest news & articles</h2>
                </div>
                {/* Blog Posts */}
                <div class="row">
                    <div class="col-lg-4" data-aos="fade-up" data-aos-duration="1200">
                        <BlogCard 
                            imgSrc={blog32}
                            imgInfo='Blog feature Img'
                            link='/about-us'
                            description='7 reasons Deski is best deski...'
                            author='Rashed kabir'
                            authorPost='Design Guru'
                        />
                        {/* <!-- /.feature-article-meta --> */}
                    </div>
                    <div class="col-lg-4" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
                        <BlogCard 
                            imgSrc={blog32}
                            imgInfo='Blog feature Img'
                            link='/about-us'
                            description='What’s the process of our selling event.'
                            author='Alison Ja'
                            authorPost='CEO'
                        />
                        {/* <!-- /.feature-article-meta --> */}
                    </div>
                    <div class="col-lg-4" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200">
                        <BlogCard 
                            imgSrc={blog32}
                            imgInfo='Blog feature Img'
                            link='/about-us'
                            description='Managment & security process..'
                            author='Hassan'
                            authorPost='Design Guru'
                        />
                        {/* <!-- /.feature-article-meta --> */}
                    </div>
                </div>
            </div>
        </div>
        {/* <!-- /.feature-blog-four --> */}
        {/* <!--
			=====================================================
				Fancy Short Banner Five
			=====================================================
			--> */}
            <div class="fancy-short-banner-five pt-150 pb-150 md-pt-100 md-pb-100">
            <SignupLanding />
        </div>
        {/* <!-- /.fancy-short-banner-five -->
        {/* <!--
			=====================================================
				Footer Style Four
			=====================================================
			--> */}
        <Footer />
        {/* <!-- Scroll Top Button --> */}
        <ScrollTopBtn />
        </div>
        </>
    );
}

export default About;