import { Box, FormControlLabel, FormGroup, Switch, ThemeProvider, Typography } from '@mui/material';
import React, { useState } from 'react';
import { theme } from '../AppTheme/Theme';
import Api from "../Api";
import { useAuthContext } from '../../contexts/AuthContext';
import Toaster, {ToasterC} from '../Toaster';
import Storage from "../../helper/Storage"

const Settings = () => {
    const user_data = useAuthContext();
    const [input, setInput] = useState({
        firstName: (user_data.user !== undefined && user_data.user.firstName !== undefined && user_data.user.firstName !== null) ? user_data.user.firstName : "",
        lastName: (user_data.user !== undefined && user_data.user.lastName !== undefined && user_data.user.lastName !== null) ? user_data.user.lastName : "",
        email: (user_data.user !== undefined && user_data.user.email !== undefined && user_data.user.email !== null) ? user_data.user.email : "",
        web_link: (user_data.user !== undefined && user_data.user.web_link !== undefined && user_data.user.web_link !== null) ? user_data.user.web_link : "",
        profile_img: null,
        preview_img:(user_data.isAuthenticated && user_data.user.img !== null && user_data.user.img !== undefined && user_data.user.img !== "")?user_data.user.img:null,
        about: (user_data.user.about !== undefined && user_data.user.about !== undefined && user_data.user.about !== null) ? user_data.user.about : "",
        time_zone: (user_data.time_zone !== undefined && user_data.user.time_zone !== undefined && user_data.user.time_zone !== null) ? user_data.user.time_zone : Intl.DateTimeFormat().resolvedOptions().timeZone,
        time_format: (user_data.user.time_format !== undefined && user_data.user.time_format !== undefined && user_data.user.time_format !== null) ? user_data.user.time_format : "",
        booking_page_title : (user_data.booking_page_title !== undefined && user_data.user.booking_page_title !== undefined && user_data.user.booking_page_title !== null) ? user_data.user.booking_page_title : "",
        notificationSetting: {
            inApp : (user_data.user !== undefined && user_data.user.notificationSetting.inApp !== undefined && user_data.user.notificationSetting.inApp !== null) ? user_data.user.notificationSetting.inApp : 0,
            email : (user_data.user !== undefined && user_data.user.notificationSetting.email !== undefined && user_data.user.notificationSetting.email !== null) ? user_data.user.notificationSetting.email : 0,
        }
    });
    // console.log(input)
    const handler = (e) => {
        let value= e.target.value;
        let key= e.target.name;
        if (key === "appNotification") {
            if (input.notificationSetting.inApp === 1) {
                input.notificationSetting.inApp=0
               
            } else {
                input.notificationSetting.inApp=1
               
            }
        } else if (key === "emailNotification") {
            if (input.notificationSetting.email === 1) {
                input.notificationSetting.email=0
                
            } else {
                input.notificationSetting.email=1
               
            }
        }
       
       let AA={...input,notificationSetting:JSON.stringify(input.notificationSetting)}
        Api.edit_profile(AA).then((data) => {
            
            Storage.setCookies(data);
            let local = Storage.setlocalstorage(data.data);
            user_data.updateState(local);
            setInput((pre)=> {
                    return {...pre, ...input};
                })
            Toaster.success("Settingd Updated successfully");
        }).catch((e) => {
             console.log(e);
            Toaster.error(e.error_msg);
        })
    }
    // const submit = (event) => {
    //     event.preventDefault();
    //     setInput((pre) => {
    //         return { ...pre, loading: true }
    //     });
        
    //     Api.edit_profile(input).then((data) => {
    //         //console.log(data);
    //         Storage.setCookies(data);
    //         let local = Storage.setlocalstorage(data.data);
    //         user_data.updateState(local);
    //         // Toaster.success("Profile Updated successfully");
    //         setInput((pre) => {
    //             return { ...pre, loading: false }
    //         });
    //     }).catch((e) => {
    //         setInput((pre) => {
    //             return { ...pre, loading: false }
    //         });
    //         // Toaster.error(e.error_msg);
    //     })


    // }
    return (<>
        <section className="site-main py-5">
            <div className="container">

                <div className="page-content">
                    <div className="page-col mw-100">
                        <ThemeProvider theme={theme}>
                            <Box className="section-title">
                                <Typography variant='h1' color="secondary">Notification Setting</Typography>
                            </Box>
                            <Box className="form-col settings" sx={{boxShadow: '0px 6px 30px rgba(0, 0, 0, 0.06)', borderRadius: '5px', minHeight: 'calc(100vh - 260px)'}} mt={6} px={3}>
                                <Box component="form">
                                    <FormGroup>
                                        <FormControlLabel label="In app Notification" labelPlacement="start" control={<Switch checked={(input.notificationSetting.inApp === 1) ? true: false} value={input.notificationSetting.inApp} />} sx={{borderBottom: '1px solid #F1F5F9', fontSize: '20px'}} name="appNotification" onChange={handler} />
                                        <FormControlLabel label="E mail Notification" labelPlacement="start" control={<Switch checked={(input.notificationSetting.email === 1) ? true: false} value={input.notificationSetting.email} />} sx={{borderBottom: '1px solid #F1F5F9', fontSize: '20px'}} name="emailNotification" onChange={handler} />
                                    </FormGroup>
                                </Box>
                            </Box>
                        </ThemeProvider>
                    </div>
                </div>
            </div>
        </section>
        <ToasterC />
    </>);
}

export default Settings;