import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import react from "react";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { CalendarPicker } from '@mui/x-date-pickers/CalendarPicker';
const Fun = (props) => {
  
    function disableWeekends(date) {
      
    return props.disabledDates.includes(date.getDay())  ;
  }
  return (
    <>
      <div className="col-md-6">
        <div className="book-event-daytable">
          {/* <div className="text-center table-border">
            <h2 className="">{(props.edit) ? "Reschedule" : "Select Date"}</h2>
          </div> */}

          {/* <Calendar
            minimumDate={utils().getToday()}
            value={props.value}
            onChange={props.fun}
            disabledDays={props.disabled_d}
            shouldHighlightWeekends
          /> */}
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <CalendarPicker shouldDisableDate={disableWeekends}  date={props.value || new Date()} onChange={props.fun} minDate={new Date()}/>
          </LocalizationProvider>
        </div>
      </div>
    </>
  )


}
export default react.memo(Fun);