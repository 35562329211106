import React from "react";

const Newsletter = () => {
    return (
        <>
            <div class="fancy-short-banner-three mt-250 md-mt-150 mb-250 md-mb-150">
            <div class="container">
                <div class="bg-wrapper">
                    <div class="row align-items-end">
                        <div class="col-lg-6">
                            <div class="title-style-one">
                                <h6 class="font-rubik" style={{color:'#F96F60'}}>Newsletter</h6>
                                <h2>New here? Subscribe to our Newsletter.</h2>
                            </div>
                            {/* <!-- /.title-style-one --> */}
                        </div>
                        <div class="col-lg-6">
                            <div class="form-wrapper">
                                <form action="#">
                                    <input type="text" placeholder="Email address" />
                                    <button><span>Subscribe</span></button>
                                </form>
                            </div>
                            {/* <!-- /.form-wrapper --> */}
                        </div>
                    </div>
                </div>
                {/* <!-- /.bg-wrapper --> */}
            </div>
            {/* <!-- /.container --> */}
        </div>
        </>
    );
}

export default Newsletter;