import icon from "./../../assets/images/selecticon.svg"
import moment from "moment";
import {useHistory} from "react-router-dom";
import urlhelper from "./../../helper/urlhelper"
import base from "./../../helper/base"
const Fun=(props)=>{
    const history = useHistory();
    return (
        <>
          <div className="modal event-detail-popup event-confirm fade show" id="confirm_event" style={{display:"block",paddingRight:"16px"}} tabIndex="-1" aria-labelledby="confirmEventModalLabel" aria-hidden="true">
        <div className="modal-dialog">
            <div className="modal-content">
                <div className="modal-header border-0 p-0 d-block">
                    <button type="button" className="close float-none" data-dismiss="modal" aria-label="Close" onClick={()=>{
                        props.fun((pre)=>{
                        return {...pre,same_page:false,response_data:{}}
                        });
                        window.$(".modal-backdrop").remove();
                        window.$("#confirm_event").modal("hide");
                       }}>
                    <span aria-hidden="true">&times;</span>
                </button>
                    <h2>Confirmation</h2>
                </div>
                <div className="modal-body border-0 p-0">
                   
                    <p>{props.eventname.name}  with {props.name.name}</p>
                    
                    <p><i className="fal fa-calendar"></i> <span>{moment(props.date).format('dddd, MMMM Do')} at {props.time}</span></p>
                    
                    <p><img src={icon} alt="icon"/> <span>Asia/Karachi</span></p>
                   
                    <p><i className="fal fa-clock"></i> <span>{props.event_duration} Minutes</span></p>
                    {/* <Link to={urlhelper.getAbsoluteUrl(user_data.user.username,"mybookedevent")}  className="nav-link">Download Ics</Link> */}
                    <button onClick={()=>{
                        props.fun((pre)=>{
                        return {...pre,same_page:false,response_data:{}}
                        });
                        window.$(".modal-backdrop").remove();
                        window.$("#confirm_event").modal("hide");
                        history.push(urlhelper.getAbsoluteUrl(props.name.username,props.eventname.event_name,base.encrypt(props.id)));
                    }}  className="nav-link">Reschedule</button>
                </div>
            </div>
        </div>
    </div>
        </>
    )
}
export default Fun;