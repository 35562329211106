import {Route,Redirect} from "react-router-dom";
import Storage from "./helper/Storage"
import React from 'react';
import { useAuthContext } from "./contexts/AuthContext";
const RestrictedRoute = ({component: Component, ...rest}) => {
    const userAuth=useAuthContext();
return (
<>
<Route 
{...rest} 
render={props =>{
if(!Storage.getCookies()){
return <Component {...props}/>
}
else{
    return <Redirect to={`/user/${userAuth.user.username}/dashboard`}/>
    }
    }}
/>
</>
    );
};
export default React.memo(RestrictedRoute); 