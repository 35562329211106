import {Switch,Route,Redirect} from "react-router-dom";
import urls from './config/Urls'
import Login from'./component/Login'
import Register from'./component/Register'
import home from'./component/home/Home'
import dashboard from'./component/dashboard/Main'
import mybookings from'./component/mybookings/Main'
import createevent from './component/createevent/Main'
import bookingschedule from './component/bookingschedule/Main'
import availabilty from "./component/availability/index"
import RestrictedRoute from "./RetrictedRoutes"
import PrivateRoute from "./PrivateRoutes";
import bookinglist from "./component/bookinglist/Main"
import integrations from "./component/integrations/Main"
import profile from "./component/profile/Home"
import ResetPassword from "./component/ResetPassword";
import Verify from "./component/NewPassword"
// Landing Pages
import LHome from "./component/landing/LHome";
import Features from "./component/landing/Features";
import Howitworks from "./component/landing/Howitworks";
import Pricing from "./component/landing/Pricing";
import About from "./component/landing/About";
import Contact from "./component/landing/Contact";
import PrivacyPolicy from "./component/landing/PrivacyPolicy";
import Terms from "./component/landing/Terms";
import Settings from "./component/nav/Settings";

const Fun=()=>{
   return <>
    <Switch>
    {/* <Route path="/"component={() => <Redirect to={urls.landing.home} />} exact /> */}
    <Route exact path={urls.landing.base} component={LHome} />
    <RestrictedRoute exact path={urls.unathorize.login} component={Login} />
    <RestrictedRoute exact path={urls.unathorize.register} component={Register} />
    <RestrictedRoute exact path={urls.unathorize.verify_reset} component={Verify} />
    <PrivateRoute exact path={urls.authorize.dashboard} component={dashboard} />
    <PrivateRoute exact path={urls.authorize.bookinglist} component={bookinglist}/>
    <PrivateRoute exact path={urls.authorize.createevent} component={createevent} />
    <PrivateRoute exact path={urls.authorize.availabilty} component={availabilty} />
    <PrivateRoute exact path={urls.authorize.mybookings} component={mybookings} />
    <PrivateRoute exact path={urls.authorize.integrations} component={integrations} />
    <PrivateRoute exact path={urls.authorize.profile} component={profile} />
    <Route exact path={urls.authorize.setbookings} component={bookingschedule} />
    
    <Route exact path='/reset-password' component={ResetPassword} />
    <Route exact path='/settings' component={Settings} />
    <Route exact path='/features' component={Features} />
    <Route exact path='/how-it-works' component={Howitworks} />
    <Route exact path='/pricing' component={Pricing} />
    <Route exact path='/about-us' component={About} />
    <Route exact path='/contact-us' component={Contact} />
    <Route exact path='/privacy' component={PrivacyPolicy} />
    <Route exact path='/terms' component={Terms} />
    <Route component={()=>{return <h1>Page not found</h1>}} />
    </Switch>
    </>

};
export default Fun;
