import Loader from "./../../component/loader"
import Days from "./eventsday"
import TimeZone from "./timezone"
import { useState, useEffect } from "react";
import Api from "./../Api"
import time from "./../../helper/time"
import toaster, { ToasterC } from "./../../component/Toaster"
import { toast } from "react-toastify";
import { ThemeProvider, Typography, useMediaQuery, useTheme } from "@mui/material";
import { theme } from "../AppTheme/Theme";
const Fun = () => {
    const [input, setinput] = useState({
        id: 0,
        time_limit: {},
        loader: true,
        time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        schedule_timings: { monday: [], tuesday: [], wednesday: [], thursday: [], friday: [], saturday: [], sunday: [] },
        slotsInfo: { monday: {}, tuesday: {}, wednesday: {}, thursday: {}, friday: {}, saturday: {}, sunday: {} },
        time_limit:{ monday: {}, tuesday: {}, wednesday: {}, thursday: {}, friday: {}, saturday: {}, sunday: {} },
        allSlots:[]
        
    },
    );
//    let check= Array(24*4).fill(new Date()).map((item, index)=>{
//         return {
//             startDateTime: new Date(new Date(item.getYear(),item.getMonth(), item.getDate(), 0,0,0).getTime()+ (index*30*60*1000)),
//             endDateTime: new Date(new Date(item.getYear(),item.getMonth(), item.getDate(), 0,0,0).getTime()+ ((index+1)*30*60*1000))
//         }
//     }).map((item)=>{
//         return {
//             start: `${item.startDateTime.getHours()}:${item.startDateTime.getMinutes()}`,
//             end: `${item.endDateTime.getHours()}:${item.endDateTime.getMinutes()}`
//         }
//     })
//     console.log(check);
    const timezonechange = (time) => {
        setinput((pre) => {
            return { ...pre, time_zone: time };
        })
       
    }
    const submit = async (event) => {
        event.preventDefault();
        try {
            let data = await Api.update_avail(input);
            if (data.code === 200) {
                toaster.success("Data Updated");
            }
            else {
                toaster.error("error occur! try again");
            }
        } catch (e) {
            toaster.error("error occur! try again");
        }



    }

    const slot_check = (event) => {
        let name=event.target.name;
        if(input.schedule_timings[name].length){
           input.schedule_timings[name]=[];
           input.time_limit[name]={};
           input.slotsInfo[name]={occupyTime:{},availableTime:{}};
        }
        else{
            input.schedule_timings[name].push({from:"9:00 Am",to:"5:30 Pm"})
            input.time_limit[name]={from:"9:00 Am",to:"5:30 Pm"};
            input.slotsInfo[name]=time.getSlotsInfo(input.allSlots,input.schedule_timings[name],input.time_limit[name])
        }
        setinput((pre)=>{
           return {...pre,...input}
        })
        
    }
    const addSlot = (key) => {
        if(input.slotsInfo[key].availableTime.length){
          let newSlotInfo=time.addNewSlot(input.slotsInfo[key].availableTime,input.time_limit[key]);
          let slotInfo={...input.slotsInfo[key]};
          slotInfo={...slotInfo,availableTime:newSlotInfo.slotInfo.availableTime,occupyTime:[...slotInfo.occupyTime,newSlotInfo.slotInfo.occupyTime]}
          let scheduleTiming=[...input.schedule_timings[key]];
          scheduleTiming.push(newSlotInfo.newTimeSlot);
          setinput((pre)=>{
            return {...pre,
            slotsInfo:{...pre.slotsInfo,[key]:slotInfo},
            time_limit:{...pre.time_limit,[key]:newSlotInfo.timeLimit},
            schedule_timings:{...pre.schedule_timings,[key]:scheduleTiming}
        }
         })
        }
        else{
            toast.error("all the slot are ocupied againts this day");
        }
        // let old = input.schedule_timings[key];
        // let time_limit = input.time_limit[key];
        // let new_data = time.new_add(time_limit);
        // if (new_data) {
        //     let new_arr = [...old, new_data.initial_value];
        //     let new_v = { ...input.schedule_timings, [key]: new_arr };
        //     let new_d = { ...input.time_limit, [key]: new_data.new_time_limit };
        //     setinput((pre) => {
        //         return { ...pre, schedule_timings: new_v, time_limit: new_d };
        //     })
        // }
        // else {
        //     toast.error("all the slot are ocupied againts this day");
        // }

        // let new_v={...input.schedule_timings,[key]:new_arr}
        //     setinput((pre)=>{
        //         return {...pre,schedule_timings:new_v,time_limit:new_time_limit};
        //     })



    }
    const deleteSlot = (name,index) => {
    let updatedValues=time.deleteSlot(input,name,index);
    setinput((pre)=>{
        return {...pre,...updatedValues}
     })
 }
    const sets = (dir,value,index,name) => {
        let updatedValues=time.setSlot(input,name,index,value,dir);
        setinput((pre)=>{
            return {...pre,...updatedValues}
         })
    };

    useEffect(() => {
        Api.get_latest_avail().then((data) => {
            if (data.code === 200) {
                
setinput((pre) => {
                    return { ...pre,
                         time_limit: JSON.parse(data.data.time_limit), 
                        loader: false, time_zone: data.data.time_zone, 
                        schedule_timings: JSON.parse(data.data.schedule_timings),
                        id: data.data._id,
                        slotsInfo:JSON.parse(data.data.slotsInfo),
                        allSlots:data.slots
                    };
                });
            }
            else {
                setinput((pre) => {
                    return { ...pre, loader: false };
                });
            }
        }).catch((e) => {
            console.log(e);
        })
    }, [])
    const resTheme = useTheme();
    const isMob = useMediaQuery(theme.breakpoints.down('md'));
    if (!input.loader) {
        return (
            <>
                <section className="site-main py-5">
                    <div className="container">
                        <ThemeProvider theme={theme}>
                            <div className="section-title text-center">
                                <Typography component="h1" variant="h1">General Availablity</Typography>
                            </div>
                        </ThemeProvider>
                        <div className="creat-event-content general_avail">
                            <div className="page-col">
                                <div className="form-col">
                                    <form onSubmit={submit}>
                                        <div className="event-days" style={{ boxShadow: '0px 6px 30px rgba(0, 0, 0, 0.06)', padding: isMob ? 0 : '30px' }}>

                                            <TimeZone data={{ value: input.time_zone, fun: timezonechange }} />

                                            {
                                                Object.keys(input.schedule_timings).map((val, index) => {
                                                    return <Days key={index} name={val} scheduleTiming={input.schedule_timings[val]} slotInfo={input.slotsInfo[val]} slot_check={slot_check} addSlot={addSlot} deleteSlot={deleteSlot} sets={sets}/>
                                                })
                                            }
                                            <div className="create-btn text-right">
                                                <button type="submit" className="btn btn-edit">Save</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <ToasterC />
            </>
        )
    }
    else {
        return (
            <>
                <Loader />
            </>
        )
    }
}
export default Fun;