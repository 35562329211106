import React from "react";
import FeatureTwoColumns from "./LaComponents/FeatureTwoColumns";
// import CounterSec from "./LaComponents/CounterSec";
import FeatureCard from "./LaComponents/FeatureCard";
import FeatureCardData from "./LaComponents/FeatureCardData";
import FeatureSmallCard from "./LaComponents/FeatureSmallCard";
import UseableTools from "./LaComponents/UseableTools";
import Newsletter from "./LaComponents/Newsletter";
import ScrollTopBtn from "./LaComponents/ScrollTopBtn";
import Footer from "./LaComponents/Footer";

// Import Css
import '../../assets/css/framework.css';
import '../../assets/css/responsive.css';
import '../../assets/css/custom-animation.css';
// Import Js
import '../../assets/js/theme'
// Import Images
import lshape2 from '../../assets/images/landing/shape/line-shape-2.svg';
import lshape5 from '../../assets/images/landing/shape/line-shape-5.svg';
import lshape6 from '../../assets/images/landing/shape/line-shape-6.svg';
import lshape7 from '../../assets/images/landing/shape/line-shape-7.svg';
import shape49 from '../../assets/images/landing/shape/49.svg';
import shape50 from '../../assets/images/landing/shape/50.svg';
// import shape51 from '../../assets/images/landing/shape/51.svg';
// import shape52 from '../../assets/images/landing/shape/52.svg';
// import shape53 from '../../assets/images/landing/shape/53.svg';
// import shape54 from '../../assets/images/landing/shape/54.svg';
import media35 from '../../assets/images/landing/media/img_35.png';
import media36 from '../../assets/images/landing/media/img_36.png';
import media37 from '../../assets/images/landing/media/img_37.png';
import media38 from '../../assets/images/landing/media/img_38.png';



const Features = () => {
    return(
        <>
        <div class="main-page-wrapper p0">
        {/* <!-- 
			=============================================
				Fancy Hero Three
			============================================== 
			--> */}
        <div class="fancy-hero-three">
            {/* <div class="shapes shape-one"></div>
            <div class="shapes shape-two"></div>
            <div class="shapes shape-three"></div>
            <div class="shapes shape-four"></div>
            <div class="shapes shape-five"></div>
            <div class="shapes shape-six"></div> */}
            <div class="bg-wrapper">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-9 col-lg-10 m-auto">
                            <h1 class="heading">Check our features with details.</h1>
                            <p class="sub-heading">Appointly helps teams of all sizes get better at delivering effortless customer</p>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- /.bg-wrapper --> */}
        </div>
        {/* <!-- /.fancy-hero-three --> */}
        {/* <!-- 
			=============================================
				Fancy Text block Eleven
			============================================== 
			--> */}
        <FeatureTwoColumns 
            classMain='fancy-text-block-eleven mt-225 md-mt-100'
            subtileClass='d-none'
            titleUnderline='Exclusive feature'
            titleLine={lshape5}
            title='with great offers everytime'
            para1='With Benefits from Appointly Pro, Earn rewards & Score discounts on purchases* Foryourself and your customers.'
            paraclass='d-none'
            imgMain={media35}
            imgFloat={media36}
            shape1={shape49}
            shape2={shape50}
            shapeClass='shape-two'
        />
        {/* <!-- /.fancy-text-block-eleven --> */}
        {/* <!--
			=====================================================
				Counter With Icon One
			=====================================================
			--> */}
        {/* <div class="counter-with-icon-one pt-70 pb-45">
            <CounterSec />
        </div> */}
        {/* <!-- /.counter-with-icon-one --> */}
        {/* <!-- 
			=============================================
				Fancy Feature Six
			============================================== 
			--> */}
        <div class="fancy-feature-six mt-140 md-mt-70">
            <div class="bg-wrapper">
                {/* <div class="shapes shape-one"></div>
                <div class="shapes shape-two"></div>
                <div class="shapes shape-three"></div>
                <div class="shapes shape-four"></div>
                <div class="shapes shape-five"></div>
                <img src={shape51} alt="" class="shapes shape-six" /> */}
                <div class="container">
                    <div class="title-style-two text-center mb-85 md-mb-40">
                        <h2>Our
                            <span>Features <img src={lshape2} alt="" /></span> list
                        </h2>
                        <div class="sub-text mt-15">Get to know all Appointly features, that are part of the complex multi-channel</div>
                    </div>

                    <div class="row">
                    {FeatureCardData.map((val) => {
                        return (
                            <FeatureCard 
                                key={val.id}
                                delay={val.delay}
                                imgSrc={val.imgSrc}
                                title={val.title}
                                desc={val.desc}
                            />
                        );
                    })}
                    </div>
                </div>
            </div>
        </div>
        {/* <!-- /.fancy-feature-six --> */}
        {/* <!--
			=====================================================
				Fancy Text block Twelve
			=====================================================
			--> */}
        <div class="fancy-text-block-twelve mt-150 md-mt-100">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6" data-aos="fade-right" data-aos-duration="1200">
                        <div class="text-wrapper">
                            <div class="title-style-three">
                                <h6>Event System</h6>
                                <h2>
                                    <span>How does the event<img src={lshape6} alt="" /></span> software work?
                                </h2>
                            </div>
                            <p class="sub-text">A event system is a tool, primarily for customer service. It helps customer representativ inquiries from one interface without any struggles.</p>
                            <p class="sub-text">Once a customer agent receives a inquiry from any channel it will be transformed into a event.</p>
                        </div>
                        {/* <!-- /.text-wrapper --> */}
                    </div>

                    <div class="col-lg-5 ml-auto" data-aos="fade-left" data-aos-duration="1200">
                        <div class="img-gallery">
                            <img src={media37} alt="" />
                            {/* <img src={shape53} alt="" class="shapes shape-one" />
                            <img src={shape52} alt="" class="shapes shape-two" /> */}
                        </div>
                        {/* <!-- /.img-gallery --> */}
                    </div>
                </div>

                <div class="mt-70 md-mt-50">
                    <div class="row justify-content-center">
                        <FeatureSmallCard 
                            title='Join us now'
                            desc='Keep your clients informed at all times with fully editable, professional Client Reports'
                        />
                        <FeatureSmallCard
                            animDelay='100'
                            title='Instant book scheduling'
                            desc='Keep your clients informed at all times with fully editable, professional Client Reports'
                        />
                        <FeatureSmallCard
                            animDelay='200' 
                            title='Better user experience'
                            desc='Keep your clients informed at all times with fully editable, professional Client Reports'
                        />
                    </div>
                </div>
            </div>
        </div>
        {/* <!-- /.fancy-text-block-twelve --> */}
        {/* <!--
			=====================================================
				Useable Tools
			=====================================================
			--> */}
        <UseableTools />
        {/* <!-- /.useable-tools-section-two --> */}
        {/* <!-- 
			=============================================
				Fancy Text block Eleven
			============================================== 
			--> */}
        <FeatureTwoColumns 
            classMain='fancy-text-block-eleven pt-50'
            classWrapper='pl-3'
            subTitle='Certified by trustpilot'
            titleUnderline='Certified and trusted'
            titleLine={lshape7}
            title='support portal'
            para1="Whether you're a theatre, stadium, intimate music venue, church, gala or concert hall, our seating chart tooi accurately represent your venue and seating layout."
            paraClass='mt-30'
            para2="It's easy to incorporate socialy distanced spaces, add tables, edit the numbering or have."
            imgMain={media38}
            imgFloat={media36}
            // shape1={shape49}
            // shape2={shape54}
            shapeClass='shape-three'
        />
        {/* <!-- /.fancy-text-block-eleven --> */}
        {/* <!--
			=====================================================
				Fancy Short Banner Three
			=====================================================
			--> */}
        <Newsletter />
        {/* <!-- /.fancy-short-banner-three --> */}
        {/* Footer */}
        <Footer />
        {/* <!-- Scroll Top Button --> */}
        <ScrollTopBtn />
        </div>
            
        </>
    );
}

export default Features;