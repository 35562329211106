let obj={
    getAbsoluteUrl:(uname,urlname,addp=false)=>{
        if(!addp){
          if(urlname==="integrations"){
            return `/user/${urlname}`
          }
          return `/user/${uname}/${urlname}`
        }
        else{
          return `/user/${uname}/${urlname}/${addp}`
        }
       
      }
}
export default obj;