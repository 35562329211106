import React from "react";

const FeatureCard = (props) => {
    return (
        <>
            <div class="col-md-6 d-flex" data-aos="fade-up" data-aos-duration="1200" data-aos-delay={props.delay}>
                <div class="block-style-five">
                    <div class="icon"><img src={props.imgSrc} alt={props.imgInfo} /></div>
                    <h6 class="title"><span>{props.title}</span></h6>
                    <p>{props.desc}</p>
                </div>
                {/* <!-- /.block-style-five --> */}
            </div>
        </>
    );
}

export default FeatureCard;