import * as yup from 'yup';
let validate = {
    register: yup.object().shape({
        firstName: yup.string().required({ name: "firstName is required" }).min(2, { name: "firstName atleast 3 alphabet" }),
        lastName: yup.string().required({ name: "lastName is required" }).min(2, { name: "lastName atleast 3 alphabet" }),
        email: yup.string().email().required({ email: "email is required" }),
        password: yup.string().min(3, { password: "password must be greate than 3" }),
        confirmpassword: yup.string()
            .oneOf([yup.ref('password'), null], { confirmpassword: "password must match" })
    }),
    login: yup.object().shape({
        email: yup.string().email().required({ email: "email is required" }),
        password: yup.string().required({ password: "password must be greate than 3" }),
    }),
    event: yup.object().shape({
        name: yup.string().required({ name: "name is required" }).min(2, { name: "name atleast 3 alphabet" }),
        description: yup.string().required({ description: "description is required" }),
        event_duration: yup.number().required({ event_duration: "event duration is required" }),
        meeting_pending: yup.number().required({ meeting_pending: "meeting pending is required" }),
    }),
    bookevent: yup.object().shape({
        name: yup.string().required({ name: "name is required" }).min(2, { name: "name atleast 3 alphabet" }),
        email: yup.string().email().required({ email: "email is required" }),
        start_time: yup.string().required("start_date is required"),
        end_time: yup.string().required("end_date is required"),
        slot: yup.string().required("slot is required"),
        date: yup.string().required("date is required"),

    }),
    reset_password: yup.object().shape({
        password: yup.string().min(3, { password: "password must be greate than 3" }),
        confirmpassword: yup.string()
            .oneOf([yup.ref('password'), null], { confirmpassword: "password must match" })
    }),


}
export default validate;