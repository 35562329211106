import React from "react";
import { Link } from "react-router-dom";
import Footer from "./LaComponents/Footer";
import ScrollTopBtn from "./LaComponents/ScrollTopBtn"; 


// Import Css
import '../../assets/css/framework.css';
import '../../assets/css/responsive.css';
import '../../assets/css/custom-animation.css';
// Import Js
import '../../assets/js/theme'
// Import Images
import icon44 from '../../assets/images/landing/icon/44.svg';
import icon45 from '../../assets/images/landing/icon/45.svg';
import icon46 from '../../assets/images/landing/icon/46.svg';
// import shape64 from '../../assets/images/landing/shape/64.svg';
const Contact = () => {
    return (
        <>
            <div class="main-page-wrapper p0">
                {/* <!-- 
			    =============================================
				    Fancy Hero Four
			    ============================================== 
			    --> */}

                <div class="fancy-hero-four bg-event space-fix">
                    <div class="bg-wrapper">
                        <div class="container">
                            <div class="row">
                                <div class="col-xl-9 col-lg-11 col-md-10 m-auto">
                                    <h6>Contact us</h6>
                                    <h2>Feel free to contact us or just say hi!</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!-- /.bg-wrapper --> */}
                </div>
                {/* <!-- /.fancy-hero-four --> */}
                {/* <!-- 
			    =============================================
				    Contact Style Two
			    ============================================== 
			    --> */}
                <div class="contact-style-two mb-200 md-mb-150">
                    <div class="container">
                        <div class="contact-info-wrapper">
                            <div class="row justify-content-center">
                                <div class="col-lg-4 col-sm-6 d-lg-flex">
                                    <div class="address-info">
                                        <div class="icon d-flex align-items-end"><img src={icon44} alt="" /></div>
                                        <div class="title">Location</div>
                                        <p class="font-rubik">Main Boulevard, Gulberg lll, Lahore, Punjab, Pakistan</p>
                                    </div>
                                    {/* <!-- /.address-info --> */}
                                </div>
                                <div class="col-lg-4 col-sm-6 d-lg-flex">
                                    <div class="address-info">
                                        <div class="icon d-flex align-items-end"><img src={icon45} alt="" /></div>
                                        <div class="title">Contact</div>
                                        <p class="font-rubik">Hello@appointly.online <br />(+92) 300-0000000</p>
                                    </div>
                                    {/* <!-- /.address-info --> */}
                                </div>
                                <div class="col-lg-4 col-sm-6 d-lg-flex">
                                    <div class="address-info">
                                        <div class="icon d-flex align-items-end"><img src={icon46} alt="" /></div>
                                        <div class="title">Social Media</div>
                                        <p class="font-rubik">Find on social media</p>
                                        <ul class="d-flex justify-content-center">
                                            <li><Link to="/contact-us"><i class="fab fa-facebook" aria-hidden="true"></i></Link></li>
                                            <li><Link to="/contact-us"><i class="fab fa-twitter" aria-hidden="true"></i></Link></li>
                                            <li><Link to="/contact-us"><i class="fab fa-pinterest" aria-hidden="true"></i></Link></li>
                                        </ul>
                                    </div>
                                    {/* <!-- /.address-info --> */}
                                </div>
                            </div>
                            {/* <img src={shape64} alt="" class="shapes shape-one" /> */}
                        </div>
                        {/* <!-- /.contact-info-wrapper --> */}

                        <div class="form-style-classic mt-150 md-mt-80">
                            <form action="#" id="contact-form" data-toggle="validator">
                            <div class="messages"></div>
                            <div class="row controls">
                                <div class="col-md-6" data-aos="fade-right" data-aos-duration="1200">
                                    <div class="input-group-meta form-group mb-60">
                                        <label>First Name</label>
                                        <input type="text" placeholder="Michel" name="Fname" required="required" data-error="Name is required." />
                                        <div class="help-block with-errors"></div>
                                    </div>
                                </div>
                                <div class="col-md-6" data-aos="fade-left" data-aos-duration="1200">
                                    <div class="input-group-meta form-group mb-60">
                                        <label>Last Name</label>
                                        <input type="text" placeholder="Hawkins" name="Lname" required="required" data-error="Name is required." />
                                        <div class="help-block with-errors"></div>
                                    </div>
                                </div>
                                <div class="col-12" data-aos="fade-up" data-aos-duration="1200">
                                    <div class="input-group-meta form-group mb-60">
                                        <label>Email Address</label>
                                        <input type="email" placeholder="saeslal@zouj.co.uk" name="email" required="required" data-error="Valid email is required." />
                                        <div class="help-block with-errors"></div>
                                    </div>
                                </div>
                                <div class="col-12" data-aos="fade-up" data-aos-duration="1200">
                                    <div class="input-group-meta form-group lg mb-40">
                                        <label>Your Message</label>
                                        <textarea placeholder="your message here.." name="message" required="required" data-error="Please,leave us a message."></textarea>
                                        <div class="help-block with-errors"></div>
                                    </div>
                                </div>
                                <div class="col-12" data-aos="fade-up" data-aos-duration="1200"><button class="theme-btn-two">Send Message</button></div>
                            </div>
                            </form>
                        </div>
                        {/* <!-- /.form-style-classic --> */}
                    </div>
                </div>
                {/* <!-- /.contact-style-two --> */}
                {/* <!--
			    =====================================================
				    Footer Style Four
			    =====================================================
			    --> */}
                <Footer />
                {/* <!-- Scroll Top Button --> */}
                <ScrollTopBtn />
            </div>
        </>
    );
}

export default Contact;