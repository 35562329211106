import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { Stack, TextField, ThemeProvider, Typography } from '@mui/material';
import { theme } from './Theme';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function BookingPopup(props) {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <ThemeProvider theme={theme}>
                <Button variant="outlined" color={props.color} className={props.class} onClick={handleClickOpen}>
                    {props.BtnText}
                </Button>
                <Dialog
                    open={open}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={handleClose}
                    aria-describedby="booking-popup"
                    fullWidth
                    maxWidth='xs'
                >
                    <Stack direction='row' justifyContent='space-between' alignItems='center'>
                        <DialogTitle variant='h3' color='secondary'>{props.Title}</DialogTitle>
                        <Button variant="text" color='secondary' onClick={handleClose} sx={{ background: 'transparent!important', fontSize: 20 }}><i className="fal fa-times"></i></Button>
                    </Stack>
                    <DialogContent sx={{paddingTop: 0}}>
                        <DialogContentText id="booking-popup-description">
                            <Typography variant='h6' color='secondary' sx={{ fontWeight: 600, padding: '15px 0', borderTop: '1px solid #F1F5F9' }}>Event Name: <Typography variant='body1' sx={{ display: 'inline-block' }}>{props.EName}</Typography></Typography>
                            <Typography variant='h6' color='secondary' sx={{ fontWeight: 600, padding: '15px 0', borderTop: '1px solid #F1F5F9' }}>Date & Time: <Typography variant='body1' sx={{ display: 'inline-block' }}>{props.EDate}</Typography></Typography>
                            <Typography variant='h6' color='secondary' sx={{ fontWeight: 600, padding: '15px 0', borderTop: '1px solid #F1F5F9' }}>Duration: <Typography variant='body1' sx={{ display: 'inline-block' }}>{props.Duration}</Typography></Typography>
                            <Typography variant='h6' color='secondary' sx={{ fontWeight: 600, padding: '15px 0', borderTop: '1px solid #F1F5F9' }}>With: <Typography variant='body1' sx={{ display: 'inline-block' }}>{props.With}</Typography></Typography>
                            {props.EventCancelPopup ? null : <Typography variant='h6' color='secondary' sx={{ fontWeight: 600, padding: '15px 0', borderTop: '1px solid #F1F5F9' }}>Email: <Typography variant='body1' sx={{ display: 'inline-block' }}>{props.Email}</Typography></Typography>}
                            {props.EventCancelPopup ? <>
                                <Typography variant='body1' color='secondary.light' sx={{ padding: '15px 0', borderTop: '1px solid #F1F5F9' }}>Please confirm tht you would like to cancel this event. A cancellation email will also go out to the invitee.</Typography>
                                <TextField label='Write a Summary' placeholder='Write a Summary' rows={4} sx={{marginBottom: 0}} multiline fullWidth />
                            </> : null}
                        </DialogContentText>
                    </DialogContent>
                    {props.EventCancelPopup ? <>
                    <DialogActions sx={{justifyContent: 'space-between', padding: '0 24px 25px'}}>
                        <Button variant='outlined' color='secondary'onClick={handleClose}>No, don’t cancel</Button>
                        <Button variant='contained' color='primary' onClick={props.cancelFunc}>Cancle Event</Button>
                    </DialogActions>
                    </> : null }
                </Dialog>
            </ThemeProvider>
        </>
    );
}