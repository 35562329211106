//import GoogleLogin from 'react-google-login';
// import GmailIcon from'./../assets/images/gmail.svg';
// import api from "./../component/Api"
// import urlhelper  from './../helper/urlhelper';
// import Storage from "./../helper/Storage"
// import { useAuthContext } from './../contexts/AuthContext'
// import {useHistory} from "react-router-dom";
// import React from 'react';
// import Toaster from "./../component/Toaster"
// const Fun=(props)=>{
// const history = useHistory();
// const user_data=useAuthContext();
// const responseGoogle = async (response) => {
//   console.log(response)
//     let obj={
//       social_login:1,
//       social_login_type:"google",
//       s_token:response.tokenId
//     }
//     let result=await api.social_login(obj);
//      Storage.setCookies(result);
//      let local=Storage.setlocalstorage(result.data);
//      user_data.updateState(local);
//      Toaster.success("Login Successfully");
//      history.push(urlhelper.getAbsoluteUrl(local.username,"dashboard"));
//   }
//   const Fail=()=>{
    
//   }
//   console.log(process.env.REACT_APP_google_client_id)
// return <>
//      <GoogleLogin
//     clientId={process.env.REACT_APP_google_client_id}
//     render={renderProps => (
//       <button className="btn" id="gbtn" onClick={renderProps.onClick} disabled={renderProps.disabled}>
//         <img src={GmailIcon} alt="google"/> Continue with Gmail
//       </button>
//     )}
//     buttonText="Login"
//     onSuccess={responseGoogle}
//     onFailure={Fail}
//     isSignedIn={false}
//     cookiePolicy='single_host_origin'
//   />
//     </>
// }
// export default React.memo(Fun);





//custom
import GmailIcon from'./../assets/images/gmail.svg';
import api from "./../component/Api"
import urlhelper  from './../helper/urlhelper';
import Storage from "./../helper/Storage"
import { useAuthContext } from './../contexts/AuthContext'
import {useHistory} from "react-router-dom";
import Toaster from "./../component/Toaster"
import React, { useEffect } from 'react';


function GoogleButton(props) {
  const history = useHistory();
const user_data=useAuthContext();
const responseGoogle = async (token) => {
    let obj={
      social_login:1,
      social_login_type:"google",
      s_token:token
    }
    let result=await api.social_login(obj);
     Storage.setCookies(result);
     let local=Storage.setlocalstorage(result.data);
     user_data.updateState(local);
     Toaster.success("Login Successfully");
     history.push(urlhelper.getAbsoluteUrl(local.username,"dashboard"));
  }
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://apis.google.com/js/platform.js';
    script.async = true;
    document.body.appendChild(script);
    script.addEventListener('load', () => {
      console.log('Google isLoaded');
      window.gapi.load('auth2', () => {
        
      window.auth2 = window.gapi.auth2.init({
          client_id: process.env.REACT_APP_google_client_id,
          cookiepolicy: 'single_host_origin',
          fetch_basic_profile: true,
          scope: 'profile email',
          plugin_name: "chat"
        });
        window.auth2.attachClickHandler(document.getElementById("gbtn"), {}, googleUser => {
          let authRes = googleUser.getAuthResponse();
          responseGoogle(authRes.id_token)
    
        });
      });
    });
    return ()=>{
      script.remove();
    }
  
  
  }, []);

  const login = element => {
    console.log(element)
  
  };
  return (
   <>
     <button className="btn" id="gbtn" >
         <img src={GmailIcon} alt="google"/> Continue with Gmail
      </button>
   </>
  );
}
export default GoogleButton;

