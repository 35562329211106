import React, {useState} from "react";

const ScrollTopBtn = () => {
    const [visible, setVisible] = useState(false)
  
  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 200){
      setVisible(true)
    } 
    else if (scrolled <= 200){
      setVisible(false)
    }
  };
    const scrollToTop = () =>{
        window.scrollTo({
          top: 0, 
          behavior: 'smooth'
        });
      };
      
      window.addEventListener('scroll', toggleVisible);
    return (
        <>
        <button class="scroll-top" onClick={scrollToTop} style={{display: visible ? 'inline' : 'none'}}>
			<i class="fa fa-angle-up" aria-hidden="true"></i>
		</button>
        </>
    );
}

export default ScrollTopBtn;