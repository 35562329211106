import { useState, useEffect } from "react"
import Api from "../Api"
import Cards from "./Cards"
import Loader from "./../../component/loader"
import { timeToInt } from 'time-number';
import toaster, { ToasterC } from "./../../component/Toaster"
import { Box, FormControl, InputLabel, MenuItem, Paper, Select, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, ThemeProvider, Typography } from "@mui/material";
import MuiTable from "../AppTheme/MuiTable";
import { theme } from "../AppTheme/Theme"
const Main = () => {
  const [apidata, setapidata] = useState({ loader: true, cards: [] });
  const [status, setstatus] = useState(1);
  const filter = (event) => {
    setstatus(parseInt(event.target.value));
  }
  const cancel_event = (id, index, google_event_id = null) => {
    if (window.confirm("are you want to cancel this booking")) {
      Api.cancel_booking(id).then((data) => {
        if (data.code === 200) {
          let new_arr = apidata.cards.map((val, i) => {
            if (index === i) {
              val.status = 0;
            }
            return val;
          });

          setapidata((pre) => {
            return { ...pre, cards: new_arr }
          });
          toaster.error("Booking has been canceled");
        }


      }).catch((e) => {

      })
    }

  }
  useEffect(() => {
    Api.get_allbookedevent().then((data) => {
      // console.log(data.data);
      setapidata((pre) => {
        return { ...pre, loader: false, cards: data.data }
      })
    }).catch((e) => {

    })
  }, [])
  useEffect(() => {
    return () => {
      setapidata({ loader: false, cards: [] });
    }
  }, []);


  if (!apidata.loader) {
    var cards = [];
    let curr_date = new Date();
    if (apidata.cards !== undefined && apidata.cards.length !== 0) {
      cards = apidata.cards.map((key, index) => {
        let past = false;
        let current_date = `${curr_date.getFullYear()}-${curr_date.getMonth()}-${curr_date.getDate()}`;
        let curr_time = timeToInt(curr_date.getHours() + ":" + curr_date.getMinutes() + ":" + curr_date.getSeconds());
        let event_fulldate = new Date(key.date);
        let event_date = `${event_fulldate.getFullYear()}-${event_fulldate.getMonth()}-${event_fulldate.getDate()}`
        let event_time = timeToInt(event_fulldate.getHours() + ":" + event_fulldate.getMinutes() + ":" + event_fulldate.getSeconds());
        if (current_date >= event_date) {
          if (current_date > event_date) {
            past = true;
          }
          else {
            if (curr_time > event_time) {
              past = true;
            }
          }
        }

        if (status === 1 && key.status === 1 && !past) {
          return <MuiTable key={index} index={index} data={key} status="upcoming" cancel={cancel_event} />
          // return <Cards key={index} index={index} data={key} status="upcoming" cancel={cancel_event} />
        }

        else if (status === 2 && key.status === 1 && past) {
          return <MuiTable key={index} data={key} status="past" cancel={cancel_event} />
          // return <Cards key={index} index={index} data={key} status="past" cancel={cancel_event} />
        }
        else if (status === 3 && key.status === 0) {
          return <MuiTable key={index} data={key} status="cancel" cancel={cancel_event} />
          // return <Cards key={index} index={index} data={key} status="cancel" cancel={cancel_event} />
        }
        else if (status === 4) {
          // return <Cards key={index} index={index} data={key} status="all" cancel={cancel_event} subStatus={
          return <MuiTable key={index} data={key} status="all" cancel={cancel_event} subStatus={
            () => {
              if (past && key.status === 1) {
                return "Past"
              }
              else {
                if (key.status === 0) {
                  return "Cancled"
                }
                else {
                  return "Scheduled"
                }
              }
            }
          } />
        }
        return false;
      });

    }
    else {
      cards = <p style={{padding: '15px', fontWeight: '600'}}>There is no Events</p>
    };

    return (
      <>

        <section className="site-main py-5">
          <div className="container">

            <div className="section-title">
              {/* <div className="d-flex justify-content-between align-items-center flex-wrap">
                <h1 className="mb-4">Event Details</h1>
                <form action="#">
                  <div className="d-flex">
                    <button className="btn mb-4">View</button>
                    <select name="filter" className="form-control mb-4" onChange={filter} id="">
                      <option value="1">Upcoming Events</option>
                      <option value="2">Past Events</option>
                      <option value="3">Cancelled Events</option>
                      <option value="4">View All Bookings</option>
                    </select>
                  </div>
                </form>
              </div> */}
              <ThemeProvider theme={theme}>
                <Box mb={5}>
                  <Stack direction='row' justifyContent='space-between' alignItems='center'>
                    <Typography component='h1' variant="h1">Bookings</Typography>
                    <FormControl>
                      <InputLabel>Type</InputLabel>
                      <Select
                        value={status}
                        label="Type"
                        onChange={filter}
                      >
                        <MenuItem value={1}>Upcoming booking</MenuItem>
                        <MenuItem value={2}>Past bookings</MenuItem>
                        <MenuItem value={3}>Cancle bookings</MenuItem>
                        <MenuItem value={4}>All Bookings</MenuItem>
                      </Select>
                    </FormControl>
                  </Stack>
                </Box>
              </ThemeProvider>
            </div>
            <ThemeProvider theme={theme}>
              <Box>
                <TableContainer component={Paper} sx={{boxShadow: '0px 6px 30px rgba(0, 0, 0, 0.06)'}}>
                  <Table aria-label="simple table">

                    <TableHead sx={{backgroundColor: '#F1F5F9'}}>
                      <TableRow>
                        <TableCell sx={{ fontWeight: 600, fontSize: 18, color: theme.palette.secondary.main }}>Date</TableCell>
                        <TableCell sx={{ fontWeight: 600, fontSize: 18, color: theme.palette.secondary.main }} align="center">Time</TableCell>
                        <TableCell sx={{ fontWeight: 600, fontSize: 18, color: theme.palette.secondary.main }} align="center">Duration</TableCell>
                        <TableCell sx={{ fontWeight: 600, fontSize: 18, color: theme.palette.secondary.main }} align="center">Event</TableCell>
                        <TableCell sx={{ fontWeight: 600, fontSize: 18, color: theme.palette.secondary.main }} align="center">With</TableCell>
                        <TableCell sx={{ fontWeight: 600, fontSize: 18, color: theme.palette.secondary.main }} align="center">Status</TableCell>
                        <TableCell sx={{ fontWeight: 600, fontSize: 18, color: theme.palette.secondary.main }} align="center">Action</TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {cards}

                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </ThemeProvider>
            {/* <div className="event-details-content">
              {(cards.length !== 0) ?
                <table>
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Time</th>
                      <th>Duration</th>
                      <th>Event</th>
                      <th>With</th>
                      <th>Status</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {cards}
                  </tbody>
                </table>
                : <h1> There is no book event yet</h1>}
            </div> */}
          </div>
        </section>
        <ToasterC />

      </>
    )
  }
  else {
    return <Loader />
  }

};
export default Main;