import Cookies from 'js-cookie'
import base64 from "./base"
import Api from "./../component/Api"
let Fun = {
    setCookies: function(data) {
        Cookies.set(`${process.env.NODE_ENV}-user_token`, data.token.token, { expires: data.token.expires_at / (24 * 60 * 60), path: '/', })
    },
    getCookies: () => {
        let token = Cookies.get(`${process.env.NODE_ENV}-user_token`);
        if (token !== undefined && token !== "" && token !== null) {
            return token;
        } else {
            return false;
        }
    },
    removeCookie: () => {
        if (Cookies.get(`${process.env.NODE_ENV}-user_token`) !== undefined && Cookies.get(`${process.env.NODE_ENV}-user_token`) !== "") {
            Cookies.remove(`${process.env.NODE_ENV}-user_token`);
            return;
        }
        return true;
    },
    setlocalstorage: (data) => {
        var obj = {
            id: base64.encrypt(data._id),
            img: data.profile_img,
            email: data.email,
            firstName: data.firstName,
            lastName: data.lastName,
            username: data.username,
            default_calender: data.default_calender,
            web_link: data.web_link,
            about: data.about,
            time_zone: data.time_zone,
            time_format: data.time_format,
            google_calender: data.google_calender,
            meeting_integration: data.meeting_integration,
            notificationSetting: data.notificationSetting,
            booking_page_title:data.booking_page_title,
            googleMeet:data.googleMeet
        }
        window.localStorage.setItem(`${process.env.NODE_ENV}-user_data`, JSON.stringify(obj));
        return obj;
    },
    removelocalstorage: () => {
        window.localStorage.removeItem(`${process.env.NODE_ENV}-user_data`);
        return true;
    },
    getlocalstorage: async function() {
        let local = window.localStorage.getItem(`${process.env.NODE_ENV}-user_data`);
        if (local !== undefined && local !== "" && local !== null) {
            return new Promise((res, rej) => {
                res(JSON.parse(local));
            });
        } else {
            let token = this.getCookies();
            if (!token) {
                return new Promise((res, rej) => {
                    rej(false);
                })
            } else {
                try {
                    let data = await Api.check_token(base64.decrypt(token));
                    local = this.setlocalstorage(data.data);
                    return new Promise((res, rej) => {
                        res(local);
                    });
                } catch (e) {
                    this.removeCookie();
                    return new Promise((res, rej) => {
                        rej(false);
                    })
                }
            }
        }
    }
}
export default Fun;