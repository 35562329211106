import React from "react";

const FeatureTwoColumns = (props) => {
    return (
        <>
            <div class={props.classMain}>
            <div class="container">
                <div class="row">
                    <div class="col-lg-6 order-lg-last" data-aos="fade-left" data-aos-duration="1200">
                        <div class={`text-wrapper ${props.classWrapper}`}>
                            <div class="title-style-three mb-35 md-mb-20">
                                <h6 className={props.subtileClass}>{props.subTitle}</h6>
                                <h2><span>{props.titleUnderline} <img src={props.titleLine} alt="" /></span> {props.title}.
                                </h2>
                            </div>
                            {/* <!-- /.title-style-two --> */}
                            <p>{props.para1}</p>
                            <p class={props.paraClass}>{props.para2}</p>
                            {/* {this.props.para2 && <p class={props.paraClass}>{props.para2}</p>} */}
                        </div>
                    </div>
                    <div class="col-lg-6 order-lg-first">
                        <div class="img-gallery">
                            <img src={props.imgMain} alt="" data-aos="fade-right" data-aos-duration="1200" />
                            <img src={props.imgFloat} alt="" class="overlay-img" data-aos="fade-left" data-aos-duration="1200" data-aos-delay="100" />
                            {/* <img src={props.shape1} alt="" class="shapes shape-one" /> */}
                            {/* <img src={props.shape2} alt="" class={`shapes ${props.shapeClass}`} /> */}
                        </div>
                        {/* <!-- /.img-gallery --> */}
                    </div>
                </div>
            </div>
        </div>
        </>
    );
}

export default FeatureTwoColumns;