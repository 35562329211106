import icon from "./../../assets/images/selecticon.svg"
import moment from "moment";
import Er from "./../Error"
import { Button, TextField, ThemeProvider,FormLabel,RadioGroup,FormControlLabel,Radio} from "@mui/material";
import { theme } from "../AppTheme/Theme";
const Fun = (props) => {
    console.log(props)
    return (
        <>
            <div className="modal event-detail-popup event-confirm fade" id="confirmEventModal" tabIndex="-1" aria-labelledby="confirmEventModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header border-0 p-0 d-block">
                            <button type="button" className="close float-none" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                            <h2>Confirm Booking</h2>
                        </div>
                        <div className="modal-body border-0 p-0">

                            <p>{props.eventname}  with {props.name}</p>

                            <p><i className="fal fa-calendar"></i> <span>{moment(props.date).format('dddd, MMMM Do')} at {props.time}</span></p>

                            <p><img src={icon} alt="icon" /> <span>Asia/Karachi</span></p>

                            <p><i className="fal fa-clock"></i> <span>{props.event_duration} Minutes</span></p>
                            <div className="event-confirm-form">
                                <ThemeProvider theme={theme}>
                                    <form onSubmit={props.submit}>
                                        {/* <input type="text" name="name" className="form-control mb-3" placeholder="Your Name" value={props.username} onChange={props.fun} disabled={(props.id)?true:false} required/> */}
                                        <TextField label='Your Name' name="name" placeholder="Your Name" value={props.username} onChange={props.fun} disabled={(props.id) ? true : false} required fullWidth />
                                        {(props.error.name) ? <Er value={props.error.name} /> : ""}
                                        {/* <input type="email" name="email" className="form-control mb-4" placeholder="Your Email" value={props.email} onChange={props.fun} disabled={(props.id)?true:false} required/> */}
                                        <TextField label='Your Email' placeholder="Your Email" type='email' name="email" value={props.email} onChange={props.fun} disabled={(props.id) ? true : false} required fullWidth />
                                        {(props.error.email) ? <Er value={props.error.email} /> : ""}
                                        {(!props.quesAns.length) ? null : (props.quesAns).map((val, key) => {
                                        return <TextField label={val.ques}  placeholder="Answer" type='text' name="quesAns" value={val.answer} onChange={(e)=>props.fun(e,key)}  required={val.required} fullWidth />
                                        })}
                                        <FormLabel id="popup_radio">Meeting Link</FormLabel>
                           <RadioGroup aria-labelledby="popup_radio" defaultValue="customlink" name="radio-buttons-group" row>
                          <FormControlLabel value="customlink" name="meetingLink" control={<Radio checked={props.meetingLink==="customlink"}/>} label="Custom Link" onChange={props.fun}/>
                          <FormControlLabel value="googlemeet" name="meetingLink"control={<Radio checked={props.meetingLink ==="googlemeet"} />} label="Google Meet"  onChange={props.fun}/>
                          </RadioGroup>
                          {props.meetingLink==="customlink" && <TextField label='Enter Link' name="meeting_url" placeholder="Enter Link"  onChange={props.fun} value={props.meeting_url}  required fullWidth />}
                                        
                                        {/* <button className="btn btn-delete mr-md-1" data-dismiss="modal" type="button">Cancel</button>
                                        <button className="btn btn-edit ml-md-1" type="submit" disabled={(props.loading)?true:false}>Book Event{(props.loading)?<div className="spinner-border"></div>:""} </button> */}
                                        <Button variant="outlined" color="error" size="large" data-dismiss="modal" className="mr-md-1">Cancel</Button>
                                        <Button variant="contained" color="primary" size="large" type="submit" className=" ml-md-1" disabled={(props.loading) ? true : false}>Book Event {(props.loading) ? <div className="spinner-border"></div> : ""}</Button>
                                    </form>
                                </ThemeProvider>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Fun;