import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { ThemeProvider } from '@mui/material';
import { theme } from './Theme';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
];

export default function MultipleSelectCheckmarks(props) {
  return (

    <>
      <ThemeProvider theme={theme}>
        <FormControl sx={{ m: '0 0 0 20px', width: '100%', maxWidth: 100 ,opacity:props.css}} >
          <InputLabel id='multi-select-label' sx={{fontSize: 14, fontWeight: 500}}>Duplicate</InputLabel>
          <Select
            // labelId="multiple-checkbox-label"
            class="multiple-checkbox"
            multiple
            value={props.days[props.day][props.indexNum] || []}
            onChange={(e)=>props.onDuplicate(e,props.day,props.day)}
            input={<OutlinedInput label='tag' class="multiple-checkbox-select" />}
            renderValue={(selected) => selected.join(', ')}
            MenuProps={MenuProps}
            disabled={props.disabled}
          >
            {names.filter(val=> val !==props.day).map((name) => 
                <MenuItem key={name} value={name}>
                <Checkbox  checked={props.days[props.day].indexOf(name) > -1} />
                <ListItemText primary={name.toUpperCase()} />
              </MenuItem>
            )}
          </Select>
        </FormControl>
      </ThemeProvider>
    </>
  );
}
