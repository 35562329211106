import { FormControl, ThemeProvider, Typography } from '@mui/material';
import { useState } from 'react';
import { timeFromInt } from 'time-number';
import { theme } from '../AppTheme/Theme';
import TimeZone from "../availability/timezone";
const Radio = (props) => {
    return (
        <>
            <div className="col-12">
                <input type="radio" data-start_time={timeFromInt(props.value, { format: 12 })} data-end_time={timeFromInt(props.value + (props.duration * 60), { format: 12 })} value={JSON.stringify({ from: timeFromInt(props.value, { format: 12 }), to: timeFromInt(props.value + (props.duration * 60), { format: 12 }) })} name="slot" id={`mor${props.index}`} onClick={props.fun} />
                <label htmlFor={`mor${props.index}`} data-toggle="modal" data-target="#confirmEventModal">
                    {timeFromInt(props.value, { format: 12 })}-{timeFromInt(props.value + (props.duration * 60), { format: 12 })}
                </label>
            </div>
        </>
    )
}
const Fun = (props) => {
    const [input, setInput] = useState({
        time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    })
    const timezonechange = (time) => {
        setInput((pre) => {
            return { ...pre, time_zone: time };
        })
    }
    console.log(props);
    return (
        <>
            <div className="col-md-3" id="selectMeetTime">
                <div className="book-event-timetable">
                    <div className="text-center table-border">
                        {/* <h2>Select Time</h2> */}
                        <ThemeProvider theme={theme}>
                            <Typography variant='h6' component='h2' color='info.main' py={2}>{props.UserName}</Typography>
                        </ThemeProvider>
                    </div>
                    <form action="#">
                        <FormControl sx={{padding: '15px 20px', borderBottom: '0.3px solid rgba(2, 61, 77, 0.6)'}} mb={0} fullWidth>
                            <TimeZone data={{ value: input.time_zone, fun: timezonechange }} style={{ display: 'block' }} />
                        </FormControl>
                        <div className="time-selection d-flex flex-wrap justify-content-between">
                            {(props.data.length !== 0) ?
                                props.data.map((val, i) => {
                                    return <Radio key={i} index={i} value={val} fun={props.fun} duration={props.eveduration} />
                                })
                                : "There is no Slots against your date"
                            }
                        </div>
                    </form>
                </div>
            </div>

        </>
    )
}
export default Fun;