import React from "react";
import PricingTable from "./LaComponents/PricingTable";
import CounterSec from "./LaComponents/CounterSec";
import Faq from "./LaComponents/Faq";
import SignupLanding from "./LaComponents/SignupLanding";
import Footer from "./LaComponents/Footer";
import ScrollTopBtn from "./LaComponents/ScrollTopBtn";
// Import Css
import '../../assets/css/framework.css';
import '../../assets/css/responsive.css';
import '../../assets/css/custom-animation.css';
// Import Js
import '../../assets/js/theme'
// Import Images
// import shape131 from '../../assets/images/landing/shape/131.svg';
// import shape132 from '../../assets/images/landing/shape/132.svg';


const Pricing = () => {    
    return (
        <>
        <div class="main-page-wrapper p0">
          {/* <!--
			=====================================================
				Pricing Section Three
			=====================================================
			--> */}
        <div class="pricing-section-three-inner-page mb-200 md-mb-120">
            <img src="../images/shape/124.svg" alt="" class="shapes shape-one" />
            <img src="../images/shape/125.svg" alt="" class="shapes shape-two" />
            <div class="container">
                <div class="title-style-five text-center mb-50 md-mb-30">
                    <h6>Our Pricing</h6>
                    <div class="row">
                        <div class="col-lg-9 m-auto">
                            <h2>Unbeatable prices, no contracts, simple & easy</h2>
                        </div>
                    </div>
                </div>
                {/* Pricing Table Area */}
                <PricingTable />
                {/* <!-- /.pricing-table-area-three --> */}
                {/* <!-- ./container --> */}
                <div class="counter-with-icon-one pt-100 md-pt-60">
                    <CounterSec />
                </div>
                {/* <!-- /.counter-with-icon-one --> */}
            </div>
        </div>
        {/* <!-- /.pricing-section-three-inner-page --> */}
        {/* <!--
			=====================================================
				Faq Section Four
			=====================================================
			--> */}
        <div class="faq-section-four bg-white">
            <Faq />
        </div>
        {/* <!-- /.faq-section-four --> */}
        {/* <!--
		=====================================================
				Fancy Short Banner Five
		=====================================================
		--> */}
        <div class="fancy-short-banner-five with-bg pt-130 pb-150 md-pt-100 md-pb-120">
            {/* <img src={shape131} alt="" class="shapes shape-one" />
            <img src={shape132} alt="" class="shapes shape-two" /> */}
            {/* Sign up section */}
            <SignupLanding />
        </div>
        {/* <!--
			=====================================================
				Footer Style Four
			=====================================================
			--> */}
            <Footer />
            {/* <!-- Scroll Top Button --> */}
            <ScrollTopBtn />
        </div>
        </>
    );
}

export default Pricing;