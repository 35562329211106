import React from 'react';
import { Button, Chip, TableCell, TableRow, ThemeProvider } from "@mui/material";
import moment from 'moment';
import base from "./../../helper/base"
import urlhelper from './../../helper/urlhelper';
import { useHistory } from "react-router-dom";
import { theme } from './Theme';
import BookingPopup from './BookingPopup';

const MuiTable = (props) => {
  const history = useHistory();
  function createData(Date, Time, Duration, Event, With, Status, Actions) {
    return { Date, Time, Duration, Event, With, Status, Actions };
  }
  var eventStatus = '';
  var eventCta = '';
  if (props.status === "upcoming") {
    eventStatus = <ThemeProvider theme={theme}><Chip label="Scheduled" sx={{ color: theme.palette.primary.main, backgroundColor: theme.palette.primary.light }} /></ThemeProvider>;
    eventCta = <>
      {/* <button className="btn btn-delete" onClick={() => props.cancel(base.encrypt(props.data._id), props.index, props.data.google_event_id)} type="button">Cancel Event</button>
      <button className="btn btn-delete" onClick={() => history.push(urlhelper.getAbsoluteUrl(props.data.user_details.username, props.data.event_details.event_name, base.encrypt(props.data._id)))} type="button">Reschdule</button> */}
      <BookingPopup
        BtnText='Cancel booking'
        color='error'
        class='text-capitalize'
        Title='Cancel Event'
        EName={props.data.event_details.name}
        EDate={moment(props.data.date).format('MMMM Do YYYY, h:mm:ss a')}
        Duration={`${props.data.event_details.event_duration} Minutes`}
        With={props.data.name}
        Email={props.data.email}
        EventCancelPopup={true}
        cancelFunc={() => props.cancel(base.encrypt(props.data._id), props.index, props.data.google_event_id)}
      />
      <Button variant='outlined' color='secondary' sx={{ marginLeft: '15px' }} onClick={() => history.push(urlhelper.getAbsoluteUrl(props.data.user_details.username, props.data.event_details.event_name, base.encrypt(props.data._id)))}>Reschdule</Button>
    </>
  } else if (props.status === "past") {
    eventStatus = <ThemeProvider theme={theme}><Chip label="Past" sx={{ color: theme.palette.success.dark, backgroundColor: theme.palette.success.light }} /></ThemeProvider>;
    eventCta = <BookingPopup
      BtnText='View More'
      color='secondary'
      class='text-capitalize'
      Title='Bookings Details'
      EName={props.data.event_details.name}
      Duration={`${props.data.event_details.event_duration} Minutes`}
      With={props.data.name}
      Email={props.data.email}
      EventCancelPopup={false}
    />
    // <button className="btn btn-view" type="button" data-toggle="modal" data-target="#viewEventModal">View More</button>;
  } else if (props.status === "cancel") {
    eventStatus = <ThemeProvider theme={theme}><Chip label="Cancelled" sx={{ color: theme.palette.error.light2, backgroundColor: theme.palette.error.light }} /></ThemeProvider>;
    eventCta = <BookingPopup
    BtnText='View More'
    color='secondary'
    class='text-capitalize'
    Title='Bookings Details'
    EName={props.data.event_details.name}
    Duration={`${props.data.event_details.event_duration} Minutes`}
    With={props.data.name}
    Email={props.data.email}
    EventCancelPopup={false}
  />
    // <button className="btn btn-view" type="button" data-toggle="modal" data-target="#viewEventModal">View More</button>;
  } else if (props.status === "all" && props.subStatus() === "Past") {
    eventStatus = <ThemeProvider theme={theme}><Chip label="Past" sx={{ color: theme.palette.success.dark, backgroundColor: theme.palette.success.light }} /></ThemeProvider>;
    eventCta = <BookingPopup
    BtnText='View More'
    color='secondary'
    class='text-capitalize'
    Title='Bookings Details'
    EName={props.data.event_details.name}
    Duration={`${props.data.event_details.event_duration} Minutes`}
    With={props.data.name}
    Email={props.data.email}
    EventCancelPopup={false}
  />
    // <button className="btn btn-view" type="button" data-toggle="modal" data-target="#viewEventModal">View More</button>;
  } else if (props.status === "all" && props.subStatus() === "Cancled") {
    eventStatus = <ThemeProvider theme={theme}><Chip label="Cancelled" sx={{ color: theme.palette.error.light2, backgroundColor: theme.palette.error.light }} /></ThemeProvider>;
    eventCta = <BookingPopup
    BtnText='View More'
    color='secondary'
    class='text-capitalize'
    Title='Bookings Details'
    EName={props.data.event_details.name}
    Duration={`${props.data.event_details.event_duration} Minutes`}
    With={props.data.name}
    Email={props.data.email}
    EventCancelPopup={false}
  />
    // <button className="btn btn-view" type="button" data-toggle="modal" data-target="#viewEventModal">View More</button>;
  } else if (props.status === "all" && props.subStatus() === "Scheduled") {
    eventStatus = <ThemeProvider theme={theme}><Chip label="Scheduled" sx={{ color: theme.palette.primary.main, backgroundColor: theme.palette.primary.light }} /></ThemeProvider>;
    eventCta = <>
      {/* <button className="btn btn-delete" onClick={() => props.cancel(base.encrypt(props.data._id), props.index, props.data.google_event_id)} type="button">Cancel Event</button>
      <button className="btn btn-delete" type="button" onClick={() => history.push(urlhelper.getAbsoluteUrl(props.data.user_details.username, props.data.event_details.event_name, base.encrypt(props.data._id)))}>Reschdule</button> */}
      <BookingPopup
        BtnText='Cancel booking'
        color='error'
        class='text-capitalize'
        Title='Cancel Event'
        EName={props.data.event_details.name}
        Duration={`${props.data.event_details.event_duration} Minutes`}
        With={props.data.name}
        Email={props.data.email}
        EventCancelPopup={true}
        cancelFunc={() => props.cancel(base.encrypt(props.data._id), props.index, props.data.google_event_id)}
      />
      <Button variant='outlined' color='secondary' sx={{ marginLeft: '15px' }} onClick={() => history.push(urlhelper.getAbsoluteUrl(props.data.user_details.username, props.data.event_details.event_name, base.encrypt(props.data._id)))}>Reschdule</Button>
    </>;
  } else {
    eventStatus = ''
    eventCta = ''
  }
  const row =
    createData(
      moment(props.data.date).format("MMM Do YYYY"),
      props.data.start_time,
      `${props.data.event_details.event_duration} Minutes`,
      props.data.event_details.name,
      props.data.name,
      eventStatus,
      eventCta,

    )
  return (<>
    <TableRow
      key={row.Date}
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    >
      <TableCell component="th" scope="row">
        {row.Date}
      </TableCell>
      <TableCell align="center">{row.Time}</TableCell>
      <TableCell align="center">{row.Duration}</TableCell>
      <TableCell align="center">{row.Event}</TableCell>
      <TableCell align="center">{row.With}</TableCell>
      <TableCell align="center">{row.Status}</TableCell>
      <TableCell align="center">{row.Actions}</TableCell>
    </TableRow>
  </>);
}

export default MuiTable;