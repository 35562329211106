import React, { createContext, PureComponent, useContext } from 'react'
import Storage from "./../helper/Storage"
import Loader from "./../component/loader"
import socket from "./../helper/socket"
import api from "./../component/Api"

// const getState = async () => {
//     const state = ;
//     try {
//     const user =await ;
//     state.user = JSON.parse(user);
//     state.isAuthenticated = true;
//     return state;
//     } catch (e) {
//            state.user=null;
//            state.isAuthenticated=false;
//            return state;
//         }
    
// }

export const AuthContext = createContext({
    user: null,
    isAuthenticated: false,
    updateState: () => {},
    notifications:[],
    setNotification:()=>{

    }
})

export const useAuthContext = () => {
    return useContext(AuthContext)
}

export class AuthContextProvider extends PureComponent {
  state = {
    user:null,
    isAuthenticated: false,
    loader:true,
    notifications:[],
    setNotification:()=>{
    },
    socket:"not Connected",
   }
updateState = (user) => {
        if(user) {
            const userData = JSON.stringify(user);
            localStorage.setItem(`${process.env.NODE_ENV}-user_data`, userData)
            this.setState({
                user:user,
                isAuthenticated: true,
                socket:"ready for connected"
            })
            
            } else {
            localStorage.clear();
            this.setState({
                user:null,
                isAuthenticated: false,
                loader:false ,
                socket:"not Connected",
             })
        }
    }
    setNotification = (notifications=[]) => {
        if(notifications.length !==0) {
          this.setState({
               notifications:notifications,
               })
        } 
    }
    setSocket=()=>{
        this.setState({
            socket:"connected",
            loader:false,
        })
    }
    
    componentDidMount(){
    Storage.getlocalstorage().then((data)=>{
    api.dashboard_data().then((value)=>{
        this.setState({
            user:data,
            isAuthenticated: true,
            socket:"ready for connected",
            notifications:value.notification,
            loader:false
        });
       
    });
    
    }).catch((e)=>{
            this.setState({
                user:null,
                isAuthenticated: false,
                loader:false,
                notifications:[],
                socket:"not Connected",
                
               
            })
        })
       
    }
    
render() {
    
    if(!this.state.loader){
        const context = {
            user: this.state.user,
            isAuthenticated: this.state.isAuthenticated,
            updateState: this.updateState,
            notifications:this.state.notifications,
            setNotification:this.setNotification,
        }
        // socket.get_notification(this.setNotification);
       
return (
            <AuthContext.Provider value={context}>
                {this.props.children}
            </AuthContext.Provider>
        )
    }
    else{
      return  <Loader/>
    }
   
    }

}