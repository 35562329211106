import React from "react";

// Images
// import shape113 from '../../../assets/images/landing/shape/113.svg';

const WhyChooseRow = (props) => {
    return(
        <>
            <div class={props.rowClass}>
                    <div class="col-lg-6" data-aos={props.animationTextClass} data-aos-duration="1200">

                        {/* <!-- Accordion Style Two --> */}
                        <div class={props.rowInnerClass}>
                            <h2>{props.title}</h2>
                            <p>{props.text}</p>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-8 m-auto" data-aos={props.animationImgClass} data-aos-duration="1200">
                        <div class={`illustration-holder d-flex ${props.imgClass} md-mt-50`}>
                            <img src={props.imgSrc} alt="" />
                            {/* <img src={shape113} alt="" class="shapes shape-one" /> */}
                        </div>
                        {/* <!-- /.illustration-holder --> */}
                    </div>
                </div>
        </>
    );

}

export default WhyChooseRow;