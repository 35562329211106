import React, { Component } from "react";
import Slider from "react-slick";

function NextArrow(props) {
  const {  style, onClick } = props;
  return (
    <div
      className={'slider-arrow slider-next fal fa-long-arrow-right'}
      style={{ ...style, display: "block", color: "#5D5D5D" }}
      onClick={onClick}
    />
  );
}

function PrevArrow(props) {
  const { style, onClick } = props;
  return (
    <div
      className={"slider-arrow slider-prev fal fa-long-arrow-left"}
      style={{ ...style, display: "block", color: "#5D5D5D" }}
      onClick={onClick}
    />
  );
}

export default class ClientSlider extends Component {
  render() {
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />
    };
    return (
        <div class="clientSliderThree">
            <Slider {...settings}>
                <div class="item">
                    <p>Having a home based business is a wonderful asset to your life. The problem still stands it comes time advertise your business for a cheap cost. I know you have looked answer everywhere.</p>
                    <h6 class="name">Rashed kabir</h6>
                    <span class="desig">Designer</span>
                </div>
                <div class="item">
                    <p>Having a home based business is a wonderful asset to your life. The problem still stands it comes time advertise your business for a cheap cost. I know you have looked answer everywhere.</p>
                    <h6 class="name">Zubayer Hasan</h6>
                    <span class="desig">Developer</span>
                </div>
            </Slider>
      </div>
    );
  }
}