import React, { useEffect, useState } from 'react';
import { useAuthContext } from '../../contexts/AuthContext'
import avatar from './../../assets/images/avatar.png';
import Toaster, { ToasterC } from "../Toaster"
import Storage from "../../helper/Storage"
import Api from "../Api"
import { Box, FormControl, InputLabel, MenuItem, Select, Stack, TextField, ThemeProvider, Typography } from '@mui/material';
import { theme } from '../AppTheme/Theme';
import DefaultProfilePic from '../AppTheme/DefaultProfilePic';
import UploadedProfilePic from '../AppTheme/UploadedProfilePic';
import TimeZone from "../availability/timezone";

const Fun = () => {
    const user_data = useAuthContext();

    const [input, setInput] = useState({
        firstName: (user_data.user !== undefined && user_data.user.firstName !== undefined && user_data.user.firstName !== null) ? user_data.user.firstName : "",
        lastName: (user_data.user !== undefined && user_data.user.lastName !== undefined && user_data.user.lastName !== null) ? user_data.user.lastName : "",
        email: (user_data.user !== undefined && user_data.user.email !== undefined && user_data.user.email !== null) ? user_data.user.email : "",
        web_link: (user_data.user !== undefined && user_data.user.web_link !== undefined && user_data.user.web_link !== null) ? user_data.user.web_link : "",
        profile_img: null,
        preview_img: (user_data.isAuthenticated && user_data.user.img !== null && user_data.user.img !== undefined && user_data.user.img !== "") ? user_data.user.img : null,
        about: (user_data.user.about !== undefined && user_data.user.about !== undefined && user_data.user.about !== null) ? user_data.user.about : "",
        time_zone: (user_data.time_zone !== undefined && user_data.user.time_zone !== undefined && user_data.user.time_zone !== null) ? user_data.user.time_zone : Intl.DateTimeFormat().resolvedOptions().timeZone,
        time_format: (user_data.user.time_format !== undefined && user_data.user.time_format !== undefined && user_data.user.time_format !== null) ? user_data.user.time_format : "",
        booking_page_title: (user_data.user.booking_page_title !== undefined && user_data.user.booking_page_title !== undefined && user_data.user.booking_page_title !== null) ? user_data.user.booking_page_title : "",
        loading: false,
    });
    console.log(input)
    var file = {};
    const handler = (event) => {
        let key = event.target.name;
        if (key === "profile_img") {
            file = event.target.files[0];
            var reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = function (e) {
                setInput((pre) => {
                    return { ...pre, preview_img: reader.result, profile_img: file }
                })
                // console.log(file);
            }
        }
        else {
            setInput((pre) => {
                return { ...pre, [key]: event.target.value };
            });
            console.log(input);
        }

    }
    const timezonechange = (time) => {
        setInput((pre) => {
            return { ...pre, time_zone: time };
        })
    }
    const submit = (event) => {
        event.preventDefault();
        setInput((pre) => {
            return { ...pre, loading: true }
        });

        Api.edit_profile(input).then((data) => {
            //console.log(data);
            Storage.setCookies(data);
            let local = Storage.setlocalstorage(data.data);
            user_data.updateState(local);
            Toaster.success("Profile Updated successfully");
            setInput((pre) => {
                return { ...pre, loading: false }
            });
        }).catch((e) => {
            setInput((pre) => {
                return { ...pre, loading: false }
            });
            Toaster.error(e.error_msg);
        })


    }
    useEffect(() => {
        return () => {
            setInput("")
        }
    }, [])


    return (
        <>
            <section className="site-main py-5">
                <div className="container">

                    <div className="page-content profile">
                        <div className="main-row row justify-content-center align-items-center">
                            <div className="page-col">
                                <ThemeProvider theme={theme}>
                                    <Box className="section-title text-center">
                                        <Typography variant='h1' color="secondary">Profile</Typography>
                                    </Box>
                                    <div className="form-col login">
                                        <form onSubmit={submit}>
                                            <Box className="profile-img position-relative">
                                                {input.preview_img ? <UploadedProfilePic width={120} height={120} src={input.preview_img} /> : <DefaultProfilePic width={120} height={120} fontSize={50} />}
                                                <label htmlFor="profilImgUpload">
                                                    <input type="file" name="profile_img" accept="image/*" value={undefined} id="profilImgUpload" onChange={handler} />
                                                    <i className="fal fa-camera" style={{ cursor: "pointer" }}></i>
                                                </label>
                                            </Box>
                                            {/* <TextField label="Booking page Link" type='url' fullWidth={true} placeholder='https://appointly.com/' name='web_link' value={input.web_link} onChange={handler} /> */}
                                            <div className="urlField">
                                                <label htmlFor="bookingLink">Booking page Link</label>
                                                <div class="input-group" style={{ marginBottom: 30 }}>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text" id="bookingLink">https://appointly.online/</span>
                                                    </div>
                                                    <input type="text" class="form-control" aria-describedby="bookingLink" name='web_link' value={input.web_link} onChange={handler} />
                                                </div>
                                            </div>
                                            <Stack direction='row' spacing={1}>
                                                <TextField label="First Name" type='text' fullWidth={true} placeholder='First Name' name='firstName' value={input.firstName} onChange={handler} />
                                                <TextField label="Last Name" type='text' fullWidth={true} placeholder='Last Name' name='lastName' value={input.lastName} onChange={handler} />
                                            </Stack>
                                            <TextField label="Email" type='email' fullWidth={true} placeholder='Email' name='email' value={input.email} onChange={handler} disabled={true} />
                                            <FormControl fullWidth>
                                                <TimeZone data={{ value: input.time_zone, fun: timezonechange }} style={{ display: 'block' }} />
                                            </FormControl>
                                            <FormControl fullWidth>
                                                <InputLabel id="demo-simple-select-label">Time Format</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={input.time_format}
                                                    name='time_format'
                                                    label="Time Format"
                                                    onChange={handler}
                                                >
                                                    <MenuItem value={12}>12 Hours</MenuItem>
                                                    <MenuItem value={24}>24 Hours</MenuItem>
                                                </Select>
                                            </FormControl>
                                            <TextField label="Booking Page Title" type='text' placeholder='Booking Page title' name='booking_page_title' value={input.booking_page_title} onChange={handler} fullWidth />
                                            <TextField label="Bokking page Discription" multiline maxRows={4} fullWidth={true} placeholder='About' name='about' value={input.about} onChange={handler} />
                                            {/* <input className="form-control mb-3" type="text" placeholder="Name" name="name" value={input.name} onChange={handler} /> */}
                                            {/* <input className="form-control mb-3" type="email" placeholder="Email" name="email" value={input.email} disabled={true} onChange={handler} /> */}
                                            {/* <input className="form-control mb-3" type="text" placeholder="Web Link" name="web_link" value={input.web_link} onChange={handler} /> */}
                                            {/* <textarea name="about" className="form-control mb-3" id="" rows="6" placeholder="About" value={input.about} onChange={handler}>{input.about}</textarea> */}
                                            {/* <div className="d-flex justify-content-between flex-wrap mb-4">
                                                <div className="col-md-6 px-0 pr-md-3">
                                                    <div className="slect-with-icon label-top form-group position-relative mb-3">
                                                        <img src={icon} alt="icon" />
                                                        <label htmlFor="">Time zone</label>
                                                        <select name="time_zone" className="form-control" value={input.time_zone} onChange={handler}>
                                                            <option value="">GMT 5+</option>
                                                            <option value="">GMT 4+</option>
                                                            <option value="">GMT 3+</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 px-0 pr-md-3">
                                                    <div className="slect-with-icon label-top form-group position-relative mb-3">
                                                        <i className="fal fa-clock"></i>
                                                        <label htmlFor="">Time Formate</label>
                                                        <select name="time_format" className="form-control" value={input.time_format} onChange={handler}>
                                                            <option value="">12 Hours</option>
                                                            <option value="">12 Hours</option>
                                                            <option value="">12 Hours</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div> */}
                                            {/* <TextField sx={{borderRadius: '4px'}} type='submit' fullWidth={true} value='Save changes' disabled={(input.loading) ? true : false} label={false} /> */}
                                            <input className="form-control" type="submit" value="Save changes" disabled={(input.loading) ? true : false} />
                                        </form>
                                    </div>
                                </ThemeProvider>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <ToasterC />
        </>
    )
};
export default Fun;