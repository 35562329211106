import React from 'react';
import Footer from './LaComponents/Footer';
import ScrollTopBtn from './LaComponents/ScrollTopBtn';

const PrivacyPolicy = () => {
    return (<>
        {/* <!-- Privacy Policy Section --> */}
    <section className="policy">
        <div className="container">
            <div className="content-wrapper">
                <div className="section-title">
                    <h1 className="mw-100">Privacy Policy</h1>
                </div>
                <div className="policy-content">
                    <div className="text-que-1">
                        <h3>Information collection & use of it</h3>
                        <p>When you fill out a questionnaire, we gather information from you. As necessary, you may be asked to enter your name, email address, or phone number when ordering or registering on our site.</p>
                    </div>
                    <div className="text-que-2">
                        <h3>How We Use The Information?</h3>
                        <p>We use the collected information to,</p>
                        <ul className="ps-3">
                            <li>Respond to queries</li>
                            <li>Resolve any problems with services/products provided by us</li>
                            <li>Generate services/products that meet your needs</li>
                            <li>Enhance our services</li>
                        </ul>
                    </div>
                    <div className="text-que-3">
                        <h3>How do we protect your information?</h3>
                        <p>Our website has multiple security measurements to protect the information you provide to us in any form.</p>
                    </div>
                    <div className="text-que-4">
                        <h3>Use of cookies</h3>
                        <p>We use cookies to collect some browsing information during your browsing session to offer you a more personalized experience.</p>
                    </div>
                    <div className="text-que-5">
                        <h3>Disclosure of any information we collect</h3>
                        <p>We do not sell, swap, or otherwise pass your personally identifiable information to third parties. Besides, when we feel that it is appropriate to comply with the legislation, enforce our site's policies or defend our rights, property,
                            security, rights, and rights of others, we can disclose your information.</p>
                    </div>
                    <div className="text-que-6">
                        <h3>Modification to the privacy policy</h3>
                        <p>If we plan to modify our privacy policy, we will update the Privacy Policy Adjustment Date and/or publish the changes on this website.</p>
                    </div>
                    <div className="text-que-7">
                        <p>If you disagree or have any questions, you can always write to us or call us regarding your concerns.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
        {/* <!--
			=====================================================
				Footer Style Four
			=====================================================
			--> */ }
            <Footer />
        {/* <!-- /.theme-footer-four --> */}
        {/* <!-- Scroll Top Button --> */}
        <ScrollTopBtn />
    </>);
}

export default PrivacyPolicy;
