import react from "react";
import { Link } from "react-router-dom";
import dotenv from "dotenv";
import urlhelper from "./../../helper/urlhelper";
import { useAuthContext } from './../../contexts/AuthContext';
import { theme } from "../AppTheme/Theme";
import { Box, Button, ThemeProvider, Typography } from "@mui/material";
dotenv.config();
const Fun = (props) => {
    const user_data = useAuthContext();
    const DescriptionStyle = {
        display: '-webkit-box',
        '-webkit-line-clamp': '3',
        '-webkit-box-orient': 'vertical',
        overflow: 'hidden'
    };


    return (<>
        <div className="col-lg-4 col-md-6 mb-4">
            <ThemeProvider theme={theme}>
                <Box className="booking-info-col">
                    <Typography variant="h5" color='secondary'>
                        {props.data.name}
                    </Typography>
                    <Typography variant="body1" color='info.light' mt={2} mb={3} sx={DescriptionStyle}>
                        {props.data.description}
                    </Typography>
                    <Link to={() => urlhelper.getAbsoluteUrl(user_data.user.username, props.data.event_name)} style={{width: '100%'}}>
                        <Button variant="outlined" size="large">
                            Book Now
                        </Button>
                    </Link>
                </Box>
            </ThemeProvider>
            {/* <div className="booking-info-col">
                <h2>{props.data.name}</h2>

                <p className="meeting-description">{props.data.description}</p>
                <div className="booking-manage-button">
                    <div className="col-12 px-0 mt-3 mt-md-0">
                        <Link to={() => urlhelper.getAbsoluteUrl(user_data.user.username, props.data.event_name)}>   <button type="button" className="btn btn-edit">Book Now</button></Link>
                    </div>
                </div>
            </div> */}
        </div>
    </>
    )


}
export default react.memo(Fun);