import { useState, useEffect } from "react"
import Api from "./Api"
import Loader from "./../component/loader"
import { useLocation } from "react-router-dom";
import Toaster, { ToasterC } from "./../component/Toaster"
import Er from "./Error"
import validate from './../helper/validation';
import { Box, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, ThemeProvider, Typography } from "@mui/material";
import { theme } from "./AppTheme/Theme";
import { Visibility, VisibilityOff } from '@mui/icons-material';

const Main = () => {
    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }
    let query = useQuery();
    let q_params = { token: query.get("token"), user_id: query.get("user_id") };
    const [error, setError] = useState({ password: false, confirmpassword: false });
    const [apidata, setapidata] = useState({ loader: true, verfiy: false, data: { user_id: q_params.user_id, password: "", confirmpassword: "" } });
    const [color, setColor] = useState({ veryweak: "", weak: "", normal: "", strong: "", verystrong: "" });
    const [type, settype] = useState("password");
    const [btn_dis, setbtndis] = useState(false);
    const handler = (event) => {
        let { name, value } = event.target;
        setapidata((prevState) => {
            let newobj = { ...prevState.data, [name]: value }
            return { ...prevState, data: newobj }
        });
    };
    const changetype = () => {
        settype((prevState) => {
            if (prevState === "password") {
                return "text";
            }
            else {
                return "password"
            }
        })
    };
    useEffect(() => {
        let fetchData = async () => {
            try {
                let data = await Api.verify_reset_token(q_params);
                console.log(data);
                setapidata((pre) => {
                    return { ...pre, loader: false, verfiy: true }
                });
            } catch (e) {
                setapidata((pre) => {
                    return { ...pre, loader: false, verfiy: false }
                });
            }
        }
        if (q_params.token !== undefined && q_params.user_id !== undefined) {
            fetchData();
        }
        else {
            setapidata((pre) => {
                return { ...pre, loader: false, verfiy: false }
            });
        }
    }, []);
    const changecolor = (event) => {
        setColor((prevState) => {
            if (event.target.value === "" || event.target.value === undefined) {
                return {
                    veryweak: "",
                    weak: "",
                    normal: "",
                    strong: "",
                    verystrong: ""
                }
            }
            else if (event.target.value.length < 3) {
                return {
                    veryweak: "#E22357",
                    weak: "",
                    normal: "",
                    strong: "",
                    verystrong: ""
                }
            }
            else if (event.target.value.length >= 3 && event.target.value.length < 5) {
                return {
                    veryweak: "#E22357",
                    weak: "#F98D00",
                    normal: "",
                    strong: "",
                    verystrong: ""
                }
            }
            else if (event.target.value.length >= 5 && event.target.value.length < 9) {
                return {
                    veryweak: "#E22357",
                    weak: "#F98D00",
                    normal: "#57899E",
                    strong: "",
                    verystrong: ""
                }
            }
            else if (event.target.value.length >= 9 && event.target.value.length < 15) {
                return {
                    veryweak: "#E22357",
                    weak: "#F98D00",
                    normal: "#57899E",
                    strong: "#93ccaf",
                    verystrong: ""
                }
            }
            else {
                return {
                    veryweak: "#E22357",
                    weak: "#F98D00",
                    normal: "#57899E",
                    strong: "#93ccaf",
                    verystrong: "#40B680"
                }
            }
        })
    };
    const onsubmit = async (e) => {
        e.preventDefault();
        setbtndis(true);
        try {
            await validate.reset_password.validate(apidata.data, { abortEarly: false });
            await Api.new_password(apidata.data);
            Toaster.success("Password Has been changed");
        } catch (e) {
            console.log(e);
            let obj = {
                password: false,
                confirmpassword: false,
            };
            for (let val of e.errors) {
                let [key, value] = Object.entries(val)[0];
                obj[key] = value;
            }
            // Toaster.error("");
            setError((pre) => {
                return { ...pre, ...obj }
            });
        }
        setbtndis(false);
    }
    if (!apidata.loader) {
        if (!apidata.verfiy) {
            return (
                <>
                    <section className="site-main">
                        <div className="container">
                            {/* <!-- Page content --> */}
                            <div className="page-content">
                                <div className="main-row row justify-content-center align-items-center">
                                    <div className="page-col">
                                        <ThemeProvider theme={theme}>
                                            {/* <!-- Section Title --> */}
                                            <Box className="section-title rec_pass">
                                                <Typography component="h1" variant="h1">Creat New Password</Typography>
                                            </Box>
                                            {/* <!-- Form Fields --> */}
                                            <Box className="form-col">
                                                <Box component="form" onSubmit={onsubmit}>
                                                    <FormControl variant='outlined' fullWidth>
                                                        <InputLabel htmlFor="password">Password</InputLabel>
                                                        <OutlinedInput
                                                            type={type}
                                                            label='Password'
                                                            placeholder="Password"
                                                            value={apidata.data.password}
                                                            onChange={e => { handler(e); changecolor(e) }}
                                                            sx={(error.password) ? { borderColor: "red" } : {}}
                                                            name="password"
                                                            required
                                                            fullWidth
                                                            endAdornment={
                                                                <InputAdornment position="end">
                                                                    <IconButton
                                                                        aria-label="toggle password visibility"
                                                                        onClick={changetype}
                                                                        // onMouseDown={handleMouseDownPassword}
                                                                        edge="end"
                                                                    >
                                                                        {(type === 'password') ? <Visibility /> : <VisibilityOff />}
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            }
                                                        />
                                                    </FormControl>
                                                    {(error.password) ? <Er value={error.password} /> : ""}
                                                    <FormControl variant='outlined' fullWidth>
                                                        <InputLabel htmlFor="password">Confirm Password</InputLabel>
                                                        <OutlinedInput
                                                            type={type}
                                                            label='Confirm Password'
                                                            placeholder="Password"
                                                            value={apidata.data.confirmpassword}
                                                            onChange={e => { handler(e); changecolor(e) }}
                                                            sx={(error.confirmpassword) ? { borderColor: "red" } : {}}
                                                            name="confirmpassword"
                                                            required
                                                            fullWidth
                                                            endAdornment={
                                                                <InputAdornment position="end">
                                                                    <IconButton
                                                                        aria-label="toggle password visibility"
                                                                        onClick={changetype}
                                                                        // onMouseDown={handleMouseDownPassword}
                                                                        edge="end"
                                                                    >
                                                                        {(type === 'password') ? <Visibility /> : <VisibilityOff />}
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            }
                                                        />
                                                    </FormControl>
                                                    {(error.confirmpassword) ? <Er value={error.confirmpassword} /> : ""}
                                                    <div className="pwd-power d-flex justify-content-between align-items-center mb-4 pb-3">
                                                        <span style={{ backgroundColor: color.veryweak }}></span>
                                                        <span style={{ backgroundColor: color.weak }}></span>
                                                        <span style={{ backgroundColor: color.normal }}></span>
                                                        <span style={{ backgroundColor: color.strong }}></span>
                                                        <span style={{ backgroundColor: color.verystrong }}></span>


                                                    </div>
                                                    {/* <!-- Submit button --> */}
                                                    {(btn_dis) ? <div className="spinner-border" style={{ display: "flex", position: "relative", marginLeft: "90%", top: "40px" }}></div> : ""}
                                                    <input className="form-control" type="submit" value="Save" disabled={btn_dis ? "disabled" : ""} />
                                                </Box>
                                                {/* <form onSubmit={onsubmit}>
                                                <div className="form-group pwd-field-eye mb-4">
                                                    <label htmlFor="password">Enter New Password</label>
                                                    <div className="position-relative">
                                                        <input className="form-control" type={type} value={apidata.data.password} onChange={e => { handler(e); changecolor(e) }} placeholder="Enter New Password" style={(error.password) ? { borderColor: "red" } : {}} name="password" required />
                                                        <button type="button" className="btn p-0" onClick={changetype}><i className="fal fa-eye"></i></button>
                                                    </div>
                                                </div>
                                                {(error.password) ? <Er value={error.password} /> : ""}
                                                <div className="form-group pwd-field-eye mb-4">
                                                    <label htmlFor="confirmpassword">Re-Enter New Password</label>
                                                    <input className="form-control mb-3" type="password" value={apidata.data.confirmpassword} onChange={handler} placeholder="Re-Enter New Password" style={(error.confirmpassword) ? { borderColor: "red" } : {}} name="confirmpassword" required />
                                                </div>
                                                {(error.confirmpassword) ? <Er value={error.confirmpassword} /> : ""}
                                                <div className="pwd-power d-flex justify-content-between align-items-center mb-4 pb-3">
                                                    <span style={{ backgroundColor: color.veryweak }}></span>
                                                    <span style={{ backgroundColor: color.weak }}></span>
                                                    <span style={{ backgroundColor: color.normal }}></span>
                                                    <span style={{ backgroundColor: color.strong }}></span>
                                                    <span style={{ backgroundColor: color.verystrong }}></span>


                                                </div>
                                                {/* <!-- Submit button --> 
                                                {(btn_dis) ? <div className="spinner-border" style={{ display: "flex", position: "relative", marginLeft: "90%", top: "40px" }}></div> : ""}
                                                <input className="form-control" type="submit" value="Save" disabled={btn_dis ? "disabled" : ""} />
                                            </form> */}
                                            </Box>
                                        </ThemeProvider>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <ToasterC />

                </>
            )
        }
        else {
            return (
                <>
                    <section className="site-main">
                        <div className="container">
                            {/* <!-- Page content --> */}
                            <div className="page-content">
                                <div className="main-row row justify-content-center align-items-center">
                                    <h1>Page Not Working The Link May be Expired or Not Uthorize</h1>
                                </div>
                            </div>
                        </div>
                    </section>


                </>
            )
        }

    }
    else {
        return <Loader />
    }

};
export default Main;