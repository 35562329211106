let obj = {
    landing: {
        base: '/',
        // home: '/home',
        // homePath: {
        //     url: '/home/:path',
        //     getAbsoluteUrl: path => {
        //         return `/home/${path}`;
        //     }
        // }
    },
    unathorize: {
        login: "/login/:email?",
        register: "/register",
        verify_reset: "/verify"

    },
    authorize: {
        dashboard: "/user/:username/dashboard",
        createevent: "/user/:username/createevent/:id?",
        availabilty: "/user/:username/availabilty",
        mybookings: "/user/:username/mybookings",
        setbookings: "/user/:username/:eventname/:id?",
        bookinglist: "/user/:username/mybookedevent",
        integrations: "/user/integrations",
        profile: "/user/:username/profile"

    }
};
export default obj;