import React from "react";
import Footer from "./LaComponents/Footer";
import Newsletter from "./LaComponents/Newsletter";
import UseableTools from "./LaComponents/UseableTools";
import ScrollTopBtn from "./LaComponents/ScrollTopBtn";
// Import Css
import '../../assets/css/framework.css';
import '../../assets/css/responsive.css';
import '../../assets/css/custom-animation.css';
// Import Js
import '../../assets/js/theme'
// Import Images
import lshape8 from '../../assets/images/landing/shape/line-shape-8.svg';
// import shape60 from '../../assets/images/landing/shape/60.svg';
// import shape61 from '../../assets/images/landing/shape/61.svg';
import ils04 from '../../assets/images/landing/assets/ils_04.svg';
import ils05 from '../../assets/images/landing/assets/ils_05.svg';
import ils06 from '../../assets/images/landing/assets/ils_06.svg';
import ils07 from '../../assets/images/landing/assets/ils_07.svg';
import feimg11 from '../../assets/images/landing/assets/feature-img-11.png';
import media39 from '../../assets/images/landing/media/img_39.png';



const Howitworks = () => {
    return(
        <>
        <div class="main-page-wrapper p0">
        {/* <!-- 
			=============================================
				Fancy Hero Three
			============================================== 
			--> */}
            <div class="fancy-hero-three bg-transparent">
                {/* <div class="shapes shape-one"></div>
                <div class="shapes shape-two"></div>
                <div class="shapes shape-three"></div>
                <div class="shapes shape-four"></div>
                <div class="shapes shape-five"></div>
                <div class="shapes shape-six"></div> */}
                <div class="bg-wrapper">
                    <div class="container">
                        <div class="row">
                            <div class="col-xl-9 col-lg-10 m-auto">
                                <h1 class="heading">Check our features with details.</h1>
                                <p class="sub-heading">Appointly helps teams of all sizes get better at delivering effortless customer</p>
                                <a href="/" class="theme-btn-two trial-button mt-50 md-mt-30">Start Trial</a>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- /.bg-wrapper --> */}
            </div>
            {/* <!-- /.fancy-hero-three --> */}
            {/* <!-- 
			=============================================
				Fancy Feature Seven
			============================================== 
			--> */}
        <div class="fancy-feature-seven">
            <div class="container">
                <div class="title-style-two text-center mb-150 md-mb-70">
                    <p>Our Product</p>
                    <h2>First-timer? Or old-hand? provide support with <span>Appointly is <img src={lshape8} alt="" /></span> simple.
                    </h2>
                </div>
                {/* <!-- /.title-style-two --> */}

                <div class="block-wrapper">
                    <div class="block-style-nine">
                        <div class="row align-items-center">
                            <div class="col-lg-7 col-md-9 m-auto" data-aos="fade-right" data-aos-duration="1200">
                                <div class="illustration-holder">
                                    <img src={ils04} alt="" />
                                </div>
                                {/* <!-- /.illustration-holder --> */}
                            </div>
                            <div class="col-lg-5" data-aos="fade-left" data-aos-duration="1200">
                                <div class="text-wrapper">
                                    <h6>Create Event</h6>
                                    <h3 class="title font-gilroy-bold">Provide your support now simple and easy.</h3>
                                    <p class="font-rubik">Set-up a simple, professional box-office page on your site (or standalone) in minutes. Then design and customise it as much (or as little) as you like with our user-friendly dashboard</p>
                                </div>
                                {/* <!-- /.text-wrapper --> */}
                            </div>
                        </div>
                    </div>
                    {/* <!-- /.block-style-nine --> */}

                    <div class="block-style-nine">
                        <div class="row align-items-center">
                            <div class="col-lg-7 col-md-9 m-auto order-lg-last" data-aos="fade-left" data-aos-duration="1200">
                                <div class="illustration-holder">
                                    <img src={ils05} alt="" />
                                </div>
                                {/* <!-- /.illustration-holder --> */}
                            </div>
                            <div class="col-lg-5 order-lg-first" data-aos="fade-right" data-aos-duration="1200">
                                <div class="text-wrapper">
                                    <h6>Scheduele EVENT</h6>
                                    <h3 class="title font-gilroy-bold">Scheduele events anywhere anytime online.</h3>
                                    <p class="font-rubik">Run your box office as it’s own website, embed it into your website with a simple piece of HTML or widget, or put it on your Facebook page*. All with a reliable, mobile-friendly design.</p>
                                </div>
                                {/* <!-- /.text-wrapper --> */}
                            </div>
                        </div>
                    </div>
                    {/* <!-- /.block-style-nine --> */}

                    <div class="block-style-nine">
                        <div class="row align-items-center">
                            <div class="col-lg-7 col-md-9 m-auto" data-aos="fade-right" data-aos-duration="1200">
                                <div class="illustration-holder">
                                    <img src={ils06} alt="" />
                                </div>
                                {/* <!-- /.illustration-holder --> */}
                            </div>
                            <div class="col-lg-5" data-aos="fade-left" data-aos-duration="1200">
                                <div class="text-wrapper">
                                    <h6>management</h6>
                                    <h3 class="title font-gilroy-bold">Manage client easily by a simple click.</h3>
                                    <p class="font-rubik">Set-up a simple, professional box-office page on your site (or standalone) in minutes. Then design & customise it as much (or as little) as you like with our user-friendly dashboard.</p>
                                </div>
                                {/* <!-- /.text-wrapper --> */}
                            </div>
                        </div>
                    </div>
                    {/* <!-- /.block-style-nine --> */}

                    <div class="block-style-nine">
                        <div class="row align-items-center">
                            <div class="col-lg-7 col-md-9 m-auto order-lg-last" data-aos="fade-left" data-aos-duration="1200">
                                <div class="illustration-holder">
                                    <img src={ils07} alt="" />
                                </div>
                                {/* <!-- /.illustration-holder --> */}
                            </div>
                            <div class="col-lg-5 order-lg-first" data-aos="fade-right" data-aos-duration="1200">
                                <div class="text-wrapper">
                                    <h6>Dashboard</h6>
                                    <h3 class="title font-gilroy-bold">All the data & info for your need.</h3>
                                    <p class="font-rubik">You decide what data you want from your guests – and it’s your data. We don’t see or touch it. Understand how and who you’re selling to, with event sale notifications, and easy-to-understand sales summaries and customer
                                        order.
                                    </p>
                                </div>
                                {/* <!-- /.text-wrapper --> */}
                            </div>
                        </div>
                    </div>
                    {/* <!-- /.block-style-nine --> */}
                </div>
            </div>
        </div>
        {/* <!-- /.fancy-feature-seven --> */}
        {/* <!--
			=====================================================
				Fancy Text Block Thirteen
			=====================================================
			--> */}
        <div class="fancy-text-block-thirteen pt-140 md-pt-100">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-6 order-lg-last" data-aos="fade-left" data-aos-duration="1200">
                        <div class="text-wrapper">
                            <div class="title-style-two">
                                <h2>Security you can trust easily.</h2>
                            </div>
                            {/* <!-- /.title-style-two --> */}
                            <p>Our dashboard is served over SSL which means you can be sure that all data transferred is secure. Payment processing uses the very latest PCI DSS card-security standards</p>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-8 m-auto order-lg-first" data-aos="fade-right" data-aos-duration="1200">
                        <img src={feimg11} alt="" />
                    </div>
                </div>
            </div>
        </div>
        {/* <!-- /.fancy-text-block-thirteen --> */}
        {/* <!--
			=====================================================
				Useable Tools
			=====================================================
			--> */}
        <UseableTools />
        {/* <!-- /.useable-tools-section-two --> */}
        {/* <!--
			=====================================================
				Fancy Text Block Fourteen
			=====================================================
			--> */}
        <div class="fancy-text-block-fourteen pt-30">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6" data-aos="fade-right" data-aos-duration="1200">
                        <div class="text-wrapper">
                            <div class="title-style-two">
                                <p>About us</p>
                                <h2>Best event & online event platform.</h2>
                            </div>
                            {/* <!-- /.title-style-two --> */}
                            <p class="sub-text">Things go wrong. You’ll have questions. We understand. So we have real people, not bots, on hand to help, 7 days a week.</p>
                            <p class="sub-text">We aim to answer any query in less than 10 minutes. Try now</p>
                            <div class="name">Mr Rashed Kabir, <span>CEO</span></div>
                        </div>
                    </div>
                    <div class="col-lg-6" data-aos="fade-left" data-aos-duration="1200">
                        <div class="img-holder md-mt-80">
                            <img src={media39} alt="" />
                            {/* <img src={shape60} alt="" class="shapes shape-one" />
                            <img src={shape61} alt="" class="shapes shape-two" /> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* <!-- /.fancy-text-block-fourteen --> */}
        {/* <!--
			=====================================================
				Fancy Short Banner Three
			=====================================================
			--> */}
            <Newsletter />
        {/* <!-- /.fancy-short-banner-three --> */}
        {/* <!--
			=====================================================
				Footer Style Two
			=====================================================
			--> */}
            <Footer />
            {/* <!-- Scroll Top Button --> */}
            <ScrollTopBtn />
        </div>
        </>
    );
}

export default Howitworks;  