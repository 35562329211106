import axios from "axios"
import dotenv from"dotenv"
import Storage from "./../helper/Storage"
import Base from "../helper/base"
import Cookies from 'js-cookie'
dotenv.config();
let ex={
     user_token:()=>{
     return Base.decrypt(Storage.getCookies());
     },
     
     register:async (data)=>{
     var formdata=new FormData();
     for(let key in data){
         formdata.append(key,data[key]);
     }
     formdata.append("time_zone",Intl.DateTimeFormat().resolvedOptions().timeZone);
     formdata.append("socket_id",Cookies.get(`${process.env.NODE_ENV}-SocketID`));
     let config={
        method: 'post',
        url: `${process.env.REACT_APP_api_url}/register`,
        data: formdata,
      };
      try{
        var result=await axios(config);
        if(result.data.code===200){
            return new Promise((res,rej)=>{
                res(result.data);
            })
        }
        else{
            return new Promise((res,rej)=>{
            rej({errors:[result.data.error_msg]});
            })
        }
       
      }catch(e){
          
        return new Promise((res,rej)=>{
            
            rej([e]);
        })
      }
     

     },
     social_login:async (data)=>{
        var formdata=new FormData();
        for(let key in data){
            formdata.append(key,data[key]);
        }
        formdata.append("time_zone",Intl.DateTimeFormat().resolvedOptions().timeZone);
        formdata.append("socket_id",Cookies.get(`${process.env.NODE_ENV}-SocketID`));
        let config={
           method: 'post',
           url: `${process.env.REACT_APP_api_url}/social_login`,
           data: formdata,
         };
         try{
           var result=await axios(config);
           if(result.data.code===200){
               return new Promise((res,rej)=>{
                   res(result.data);
               })
           }
           else{
               return new Promise((res,rej)=>{
               rej({errors:[result.data.error_msg]});
               })
           }
          
         }catch(e){
             
           return new Promise((res,rej)=>{
               
               rej([e]);
           })
         }
        
        },
     check_token:async (token)=>{
            let config={
                method: 'post',
                url: `${process.env.REACT_APP_api_url}/user/check_token`,
                headers: {'authorization': token}
              };
              try{
                var result=await axios(config);
                if(result.data.code===200){
                    return new Promise((res,rej)=>{
                        res(result.data);
                    })
                }
                else{
                    return new Promise((res,rej)=>{
                    rej({errors:[result.data.error_msg]});
                    })
                }
               
              }catch(e){
                  return new Promise((res,rej)=>{
                rej([e]);
                })
              }
        },
      login:async (data)=>{
          var formdata=new FormData();
          for(let key in data){
              formdata.append(key,data[key]);
          }
          formdata.append("socket_id",Cookies.get(`${process.env.NODE_ENV}-SocketID`));
          let config={
             method: 'post',
             url: `${process.env.REACT_APP_api_url}/login`,
             data: formdata,
           };
           try{
             var result=await axios(config);
             if(result.data.code===200){
                 return new Promise((res,rej)=>{
                     res(result.data);
                 })
             }
             else{
                 return new Promise((res,rej)=>{
                 rej({errors:[result.data.error_msg]});
                 })
             }
            
           }catch(e){
               
             return new Promise((res,rej)=>{
                 
                 rej([e]);
             })
           }
          
     
          },
      dashboard_data:async function (){
            let config={
                method: 'post',
                url: `${process.env.REACT_APP_api_url}/user/dashboard_data`,
                headers: {'authorization': this.user_token()}
              };
              try{
                var result=await axios(config);
                if(result.data.code===200){
                    return new Promise((res,rej)=>{
                        res(result.data.data);
                    })
                }
               }catch(e){
                  return new Promise((res,rej)=>{
                rej(e);
                })
              }
        },
      toggle_event:async function (id){
          let config={
              url: `${process.env.REACT_APP_api_url}/user/change_status?id=${id}`,
              headers: {'authorization': this.user_token()}
            };
            
            try{
              var result=await axios(config);
              if(result.data.code===200){
                  return new Promise((res,rej)=>{
                     res(result.data);
                  })
              }
             }catch(e){
                return new Promise((res,rej)=>{
              rej(e);
              })
            }
      },
      logout:async function (){
        let config={
            url: `${process.env.REACT_APP_api_url}/user/logout`,
            headers: {'authorization': this.user_token()},
            method:"post"
          };
          
          try{
            var result=await axios(config);
            if(result.data.code===200){
                return new Promise((res,rej)=>{
                   res(result.data);
                })
            }
           }catch(e){
              return new Promise((res,rej)=>{
            rej(e);
            })
          }
    },
    delete_event:async function (id){
      let config={
        url: `${process.env.REACT_APP_api_url}/user/delete_event/${id}`,
        headers: {'authorization': this.user_token()},
        method:"post"
      };
      console.log(config);
      try{
        var result=await axios(config);
        if(result.data.code===200){
            return new Promise((res,rej)=>{
               res(result.data);
            })
        }
       }catch(e){
          return new Promise((res,rej)=>{
        rej(e);
        })
      }
    },
    get_latest_avail:async function (){
      let config={
        url: `${process.env.REACT_APP_api_url}/user/get_latest_availability`,
        headers: {'authorization': this.user_token()},
        method:"post"
      };
      try{
        var result=await axios(config);
        if(result.data.code===200){
            return new Promise((res,rej)=>{
               res(result.data);
            })
        }
       }catch(e){
          return new Promise((res,rej)=>{
        rej(e);
        })
      }
    },
    update_avail:async function (data){
      var formdata=new FormData();
      for(let key in data){
        if(key==="schedule_timings" || key==="time_limit" || key==="slotsInfo"){
          console.log("here");
        formdata.append(key,JSON.stringify(data[key]));
        }
        else{
          formdata.append(key,data[key]);
        }
       }
      
     let config={
         method: 'post',
         url: `${process.env.REACT_APP_api_url}/user/update_avail`,
         data: formdata,
         headers: {'authorization': this.user_token()}
       };
       try{
         var result=await axios(config);
         if(result.data.code===200){
             return new Promise((res,rej)=>{
                 res(result.data);
             })
         }
         else{
             return new Promise((res,rej)=>{
             rej({errors:[result.data.error_msg]});
             })
         }
        
       }catch(e){
           
         return new Promise((res,rej)=>{
             
             rej([e]);
         })
       }
      
 
      },
      edit_event_data:async function (id=undefined){
        let config;
        if(id !==undefined){
           config={
            url: `${process.env.REACT_APP_api_url}/user/edit_event_data/${id}`,
            headers: {'authorization': this.user_token()},
            method:"post"
          };
        }
        else{
           config={
            url: `${process.env.REACT_APP_api_url}/user/edit_event_data/`,
            headers: {'authorization': this.user_token()},
            method:"post"
          };
        }
       
        try{
          var result=await axios(config);
          if(result.data.code===200){
              return new Promise((res,rej)=>{
                 res(result.data);
              })
          }
         }catch(e){
            return new Promise((res,rej)=>{
          rej(e);
          })
        }
      },
      create_event:async function (data,edit=false){
        let config;
        var formdata=new FormData();
        for(let key in data){
          if(key==="schedule_timings" || key==="event_time_limit" || key==="questions" || key==="time_limit" || key==="slotsInfo"){
          formdata.append(key,JSON.stringify(data[key]));
          }
          else if(key==="allSlots" || key==="loader"){
            continue;
          }
          else{
            formdata.append(key,data[key]);
          }
         }
        if(!edit){
           config={
            url: `${process.env.REACT_APP_api_url}/user/createevent`,
            headers: {'authorization': this.user_token()},
            method:"post",
            data:formdata
          };
        }
        else{
           config={
            url: `${process.env.REACT_APP_api_url}/user/updateevent`,
            headers: {'authorization': this.user_token()},
            method:"post",
            data:formdata
          };
        }
       
        try{
          var result=await axios(config);
          if(result.data.code===200){
              return new Promise((res,rej)=>{
                 res(result.data);
              })
          }
          else if(result.data.code===400){
            return new Promise((res,rej)=>{
              res(result.data.error_msg);
           })
          }
         }catch(e){
            return new Promise((res,rej)=>{
          rej(e);
          })
        }
      },
      getevent_details:async function (username,eventname,id=false){
        if(username&&eventname){
          let config;
          if(id){
            config={
              url: `${process.env.REACT_APP_api_url}/eventdetails/${username}/${eventname}/${id}`,
              method:"post",
            };
          }
          else{
            config={
              url: `${process.env.REACT_APP_api_url}/eventdetails/${username}/${eventname}`,
              method:"post",
            };
          }
         
         try{
          var result=await axios(config);
          if(result.data.code===200){
              return new Promise((res,rej)=>{
                 res(result.data);
              })
          }
          }catch(e){
            return new Promise((res,rej)=>{
          rej(e);
          })
        }
      }
      },
      book_event:async function (data,edit=false,user_id,event_id,google_event){
        let config;
        var formdata=new FormData();
       
        for(let key in data){
         if( key==="slots" || key==="quesAns"){
         formdata.append(key,JSON.stringify(data[key]));
          }
          else{
            formdata.append(key,data[key]);
          }
         }
         formdata.append("user_id",user_id);
         formdata.append("google_event",JSON.stringify(google_event));
         formdata.append("event_id",event_id);
         if(!edit){
           config={
            url: `${process.env.REACT_APP_api_url}/bookevent`,
            method:"post",
            data:formdata
          };
        }
        else{
          formdata.delete("id");
          formdata.append("id",Base.decrypt(edit))
           config={
            url: `${process.env.REACT_APP_api_url}/booking_rescheduling`,
            method:"post",
            data:formdata
          };
        }
       
        try{
          var result=await axios(config);
          if(result.data.code===200){
              return new Promise((res,rej)=>{
                 res(result.data);
              })
          }
          else if(result.data.code===400){
            return new Promise((res,rej)=>{
              res(result.data.error_msg);
           })
          }
         }catch(e){
            return new Promise((res,rej)=>{
          rej(e);
          })
        }
      },
      get_allbookedevent:async function (){
        let config={
          url: `${process.env.REACT_APP_api_url}/user/get_allbookevent`,
          headers: {'authorization': this.user_token()},
          method:"post"
        };
        try{
          var result=await axios(config);
          if(result.data.code===200){
              return new Promise((res,rej)=>{
                 res(result.data);
              })
          }
          
         }catch(e){
            return new Promise((res,rej)=>{
          rej(e);
          })
        }
      },
      cancel_booking:async function (id=false){
        let config={
          url: `${process.env.REACT_APP_api_url}/cancel_event/${id}`,
          method:"post"
        };
        try{
          var result=await axios(config);
          if(result.data.code===200){
              return new Promise((res,rej)=>{
                 res(result.data);
              })
          }
          
         }catch(e){
            return new Promise((res,rej)=>{
          rej(e);
          })
        }
      },
      addCalender:async function (type="google",token){
        let config={
            url: `${process.env.REACT_APP_api_url}/user/addCalender/${type}/${token}`,
            headers: {'authorization': this.user_token()},
            method:"post"
          };
        
       
        try{
          var result=await axios(config);
          if(result.data.code===200){
              return new Promise((res,rej)=>{
                 res(result.data);
              })
          }
          else{
            
            return new Promise((res,rej)=>{
              rej(result.data.error_msg)
            })
          }
         }catch(e){
            return new Promise((res,rej)=>{
          rej(e);
          })
        }
      },
      edit_profile:async function (data,profile_img=null){
       var formdata=new FormData();
       for(let key in data){
          if(key !=="preview_img"){
            formdata.append(key,(data[key]));
          }
        }
        let url=`${process.env.REACT_APP_api_url}/user/update_user`;
        let header={
          headers:{
          'authorization': this.user_token(),
          "Content-Type": "multipart/form-data"}
        }
       
        try{
          var result=await axios.post(url,formdata,header);
          if(result.data.code===200){
              return new Promise((res,rej)=>{
                 res(result.data);
              })
          }
          else if(result.data.code===400){
            return new Promise((res,rej)=>{
              res(result.data.error_msg);
           })
          }
         }catch(e){
            return new Promise((res,rej)=>{
          rej(e);
          })
        }
      },
    cancel_calender:async function (type="google"){
        let config={
            url: `${process.env.REACT_APP_api_url}/user/cancel_calender/${type}`,
            headers: {'authorization': this.user_token()},
            method:"post"
          };
        
       
        try{
          var result=await axios(config);
          if(result.data.code===200){
              return new Promise((res,rej)=>{
                 res(result.data);
              })
          }
         }catch(e){
            return new Promise((res,rej)=>{
          rej(e);
          })
        }
      },
      zoom_integration:async function (zoom_token){
        let config={
            url: `${process.env.REACT_APP_api_url}/user/zoom_integration/${zoom_token}`,
            headers: {'authorization': this.user_token()},
            method:"post"
          };
        try{
          var result=await axios(config);
          if(result.data.code===200){
           
            let local= Storage.setlocalstorage(result.data.data);
              return new Promise((res,rej)=>{
                 res(local);
              })
          }
          else{
            return new Promise((res,rej)=>{
              rej("error occur");
           })
          }
         }catch(e){
            return new Promise((res,rej)=>{
          rej(e);
          })
        }
      },
      reset_password:async function (data){
        var formdata=new FormData();
        formdata.append("email",data);
        let config={
            url: `${process.env.REACT_APP_api_url}/reset_password_email`,
            method:"post",
            data:formdata

          };
        try{
          var result=await axios(config);
          if(result.data.code===200){
          return new Promise((res,rej)=>{
            res(result);
          })
         }
          else{
            return new Promise((res,rej)=>{
              rej(result.data.error_msg);
            })
           
          }
         }catch(e){
            return new Promise((res,rej)=>{
          rej(e);
          })
        }
      },
      verify_reset_token:async function (data){
        var formdata=new FormData();
        formdata.append("token",data.token);
        formdata.append("user_id",data.user_id);
        let config={
            url: `${process.env.REACT_APP_api_url}/verify_reset_password`,
            method:"post",
            data:formdata

          };
        try{
          var result=await axios(config);
          if(result.data.code===200){
          return new Promise((res,rej)=>{
            res(result);
          })
         }
          else{
            return new Promise((res,rej)=>{
              rej(result.data.error_msg);
            })
           
          }
         }catch(e){
            return new Promise((res,rej)=>{
          rej(e);
          })
        }
      },
      new_password:async (data)=>{
        var formdata=new FormData();
        for(let key in data){
            formdata.append(key,data[key]);
        }
        let config={
           method: 'post',
           url: `${process.env.REACT_APP_api_url}/new_password`,
           data: formdata,
         };
         try{
           var result=await axios(config);
           if(result.data.code===200){
               return new Promise((res,rej)=>{
                   res(result.data);
               })
           }
           else{
               return new Promise((res,rej)=>{
               rej({errors:[result.data.error_msg]});
               })
           }
          
         }catch(e){
             
           return new Promise((res,rej)=>{
               
               rej([e]);
           })
         }
        
   
        },
      setSocketId:async function (socket_id){
          let config={
            url: `${process.env.REACT_APP_api_url}/user/setsocketid/${socket_id}`,
            headers: {'authorization': this.user_token()},
            method:"post"
          };
          try{
            var result=await axios(config);
            if(result.data.code===200){
                return new Promise((res,rej)=>{
                   res(result.data);
                });
            }
           }catch(e){
              return new Promise((res,rej)=>{
            rej(e);
            })
          }
        },
      setPrimaryCalender:async function (email){
          let config={
              url: `${process.env.REACT_APP_api_url}/user/setPrimaryCalender/${email}`,
              headers: {'authorization': this.user_token()},
              method:"post"
            };
          
         
          try{
            var result=await axios(config);
            if(result.data.code===200){
                return new Promise((res,rej)=>{
                   res(result.data);
                })
            }
           }catch(e){
              return new Promise((res,rej)=>{
            rej(e);
            })
          }
        },
      removeCalender:async function (email){
          let config={
              url: `${process.env.REACT_APP_api_url}/user/removeCalender/${email}`,
              headers: {'authorization': this.user_token()},
              method:"post"
            };
          
         
          try{
            var result=await axios(config);
            if(result.data.code===200){
                return new Promise((res,rej)=>{
                   res(result.data);
                })
            }
           }catch(e){
              return new Promise((res,rej)=>{
            rej(e);
            })
          }
        },
      toggleGoogleMeet:async function (email){
          let config={
              url: `${process.env.REACT_APP_api_url}/user/toggleMeet`,
              headers: {'authorization': this.user_token()},
              method:"post"
            };
          
         
          try{
            var result=await axios(config);
            if(result.data.code===200){
                return new Promise((res,rej)=>{
                   res(result.data);
                })
            }
           }catch(e){
              return new Promise((res,rej)=>{
            rej(e);
            })
          }
        },
        
     }
 export default ex;