import React from "react";
import TeamCard from "./TeamCard";
// Images
// import shape126 from '../../../assets/images/landing/shape/126.svg';
import media66 from '../../../assets/images/landing/media/img_66.png';

const TeamWrapper = () => {
    return (
        <>
            <div class="team-wrapper">
                <div class="row">
                    <div class="col-lg-4 col-sm-6" data-aos="fade-up" data-aos-duration="1200">
                        <TeamCard 
                            link='/about-us'
                            imgSrc={media66}
                            imgInfo='Team Member Img'
                            name='Jhon Du'
                            position='Senior Product Designer'
                        />
                        {/* <!-- /.team-member --> */}
                    </div>
                    <div class="col-lg-4 col-sm-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
                        <TeamCard 
                            link='/about-us'
                            imgSrc={media66}
                            imgInfo='Team Member Img'
                            name='Maria Gomez'
                            position='Support Manager'
                        />
                        {/* <!-- /.team-member --> */}
                    </div>
                    <div class="col-lg-4 col-sm-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200">
                        <TeamCard 
                            link='/about-us'
                            imgSrc={media66}
                            imgInfo='Team Member Img'
                            name='Rashed Kabir'
                            position='Senior Designer'
                        />
                        {/* <!-- /.team-member --> */}
                    </div>
                    <div class="col-lg-4 col-sm-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="50">
                        <TeamCard 
                            link='/about-us'
                            imgSrc={media66}
                            imgInfo='Team Member Img'
                            name='Jubayer Hasan'
                            position='Developer'
                        />
                        {/* <!-- /.team-member --> */}
                    </div>
                    <div class="col-lg-4 col-sm-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
                        <TeamCard 
                            link='/about-us'
                            imgSrc={media66}
                            imgInfo='Team Member Img'
                            name='Sumit Jadhav'
                            position='Support Manager'
                        />
                    </div>
                        {/* <!-- /.team-member --> */}
                    <div class="col-lg-4 col-sm-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200">
                        {/* <!-- /.team-member --> */}
                        <TeamCard 
                            link='/about-us'
                            imgSrc={media66}
                            imgInfo='Team Member Img'
                            name='Faisal Rahman'
                            position='Senior Designer'
                        />
                    </div>
                </div>
                    {/* <img src={shape126} alt="" class="shapes shape-one" /> */}
            </div>
        </>
    );
}

export default TeamWrapper;