import React, { useState, useEffect } from "react"
import Api from "../Api"
import Loader from "./../../component/loader"
import Calender from "./calender"
import time from "./../../helper/time"
import { useParams } from "react-router-dom";
import Time from "./times"
import Modal from "./modal"
import Modal2 from "./modal2"
import validate from './../../helper/validation';
import toaster, { ToasterC } from "./../../component/Toaster"
import moment from "moment"
import { useAuthContext } from './../../contexts/AuthContext'
import { timeToInt } from 'time-number';
import { theme } from '../AppTheme/Theme';
import { Stack, ThemeProvider, Typography } from "@mui/material";
import { Box } from "@mui/system"
import UploadedProfilePic from "../AppTheme/UploadedProfilePic"
import DefaultProfilePic from "../AppTheme/DefaultProfilePic"

const Main = () => {
    const param = useParams();
    const user_data = useAuthContext();
    let username = param.username;
    let eventname = param.eventname;
    let id = (param.id !== undefined) ? param.id : false;
    const [apidata, setapidata] = useState({ 
        loader: true, 
        userdetails: {}, 
        eventdetails: {}, 
        bookedvent: [], 
        disabled_datas: [], 
        google_events: [] 
    });
    const [input, setinput] = useState({ 
        loading: false, 
        calender_data: new Date(), 
        name: "", 
        email: "", 
        start_time: "", 
        end_time: "", 
        date: null, 
        slot: {}, 
        show_slot: { show: false, slots: [] }, 
        id: 0, 
        edit_date: "", 
        google_event_id: null, 
        same_page: false, 
        response_data: {} ,
        quesAns:[],
        meetingLink:"customlink",
        meeting_url:"",
    });
    const [error, setError] = useState({
        name: false,
        email: false,
    });
    const selectDays = (value) => {
        let newDate=new Date(value);
        const days = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];
        let new_d = new Date(newDate.getFullYear(), newDate.getMonth(), newDate.getDate());
        let get_slots = time.get_slots( 
        apidata.bookedvent, 
        JSON.parse(apidata.eventdetails.schedule_timings)[days[new_d.getDay()]], 
        new_d, 
        (id) ? input.start_time : false, apidata.google_events,apidata.eventdetails.event_duration);
        let new_v = { ...input.show_slot, show: true, slots: get_slots }
        setinput((pre) => {
            return { ...pre, calender_data: newDate, show_slot: new_v, date: new_d }
        });
    }
    const selecttime = (event) => {
        let start_time = event.target.getAttribute("data-start_time");
        let end_time = event.target.getAttribute("data-end_time");
        let slot = event.target.value;
        setinput({ ...input, slot: slot, start_time: start_time, end_time: end_time });
    }
    const changehandler = (event,index=0) => {
        let name = event.target.name;
        let value = event.target.value;
        
        if(name==="quesAns"){
            input.quesAns[index].answer=value
            setinput((pre)=>{
             return {...pre,...input}
            });
        }
        else{
            setinput({ ...input, [name]: value });
        }
        
    }
    const submit = async (event) => {
        event.preventDefault();
        let edit = false;
        if (param.id) {
            edit = true
        }
       
        try {
            setinput((pre) => {
                return { ...pre, loading: true }
            })
            await validate.bookevent.validate(input, { abortEarly: false });
            let e_date = moment.unix(moment(input.date.toString()).unix());
            let start_time = new Date(e_date + (timeToInt(input.start_time)) * 1000).toISOString();
            let end_time = new Date(e_date + (timeToInt(input.end_time)) * 1000).toISOString();
            let date= new Date(e_date + (timeToInt(input.start_time)) * 1000)
        
           
            let event = {
                'summary': `${apidata.eventdetails.name}`,
                'description': `${apidata.eventdetails.description}`,
                'start': {
                    'dateTime': start_time,
                    'timeZone': 'Asia/Karachi'
                },
                'end': {
                    'dateTime': end_time,
                    'timeZone': 'Asia/Karachi'
                },
                'attendees': [
                    { 'email': `${input.email}` }
                ],
            };
          
            let data = await Api.book_event({...input,date:date}, edit? param.id : false, apidata.eventdetails.user_id, apidata.eventdetails._id, event);
            if (data.code === 200) {
                setinput((pre) => {
                    return { ...pre, loading: false, same_page: true, response_data: data.data }
                })
                toaster.success(data.error_msg);

            }
            else {
                setinput((pre) => {
                    return { ...pre, loading: false }
                })
                toaster.error(data);
            }
        } catch (e) {

            let obj = {
                name: false,
                email: false,
            };
            if (e.error !== undefined && e.errors.length !== 0) {
                for (let val of e.errors) {
                    toaster.error(e.errors);
                    let [key, value] = Object.entries(val)[0];
                    obj[key] = value;
                }
                setError((pre) => {
                    return { ...pre, ...obj }
                });
                setinput((pre) => {
                    return { ...pre, loading: false }
                })

            }
            else {
                setinput((pre) => {
                    toaster.error(e.errors);
                    return { ...pre, loading: false }
                })

            }


        }



    }
    useEffect(() => {
        let fetchData = async () => {
                 try {
                    let data = await Api.getevent_details(username, eventname, id);
                    let disable_d = time.disabled_dates(JSON.parse(data.data.event_details.schedule_timings), data.data.bookeventdetails);
                    setapidata((pre) => {
                        return { ...pre, 
                            loader: false, 
                            eventdetails: data.data.event_details, 
                            disabled_datas: disable_d, 
                            userdetails: data.data.userdetails, 
                            bookedvent: data.data.bookeventdetails, 
                            google_events: data.data.googleevents
                        }
                    });
                    if (data.data.edit_data) {
                        setinput((pre) => {
                            return {
                                ...pre,
                                name: data.data.edit_data.name,
                                email: data.data.edit_data.email,
                                slot: JSON.parse(data.data.edit_data.slot),
                                start_time: data.data.edit_data.start_time,
                                end_time: data.data.edit_data.end_time,
                                date: data.data.edit_data.date,
                                id: param.id,
                                edit_date: data.data.edit_data.date,
                                quesAns:(data.data.edit_data.quesAns)?JSON.parse(data.data.edit_data.quesAns):[],
                                meeting_url:data.data.edit_data.meeting_url,
                                meetingLink:data.data.edit_data.meetingLink,
                                
                                
                            };
                        })
                    }
                    else{
                        setinput((pre)=>{
                            return {
                                ...pre,
                                quesAns:(data.data.event_details.questions)?JSON.parse(data.data.event_details.questions).map((val)=>{
                                    return {...val,answer:""}
                                }):[],
                            }
                        })
                    }
                    

                } catch (e) {
                    console.log(e);
                }
            
         

        }
        fetchData();


    }, []);
    useEffect(() => {
        return () => {
            setapidata({ loader: false, cards: [] });
        }
    }, []);
   

    if (!apidata.loader) {

        return (
            <>
                <section className="site-main py-5">
                    <div className="container">
                        <div className="page-content book-event with-client">
                            <div className="row">
                                <div className="col-md-3">
                                    <Box className="book-event-with">
                                        <ThemeProvider theme={theme}>
                                            <Stack direction="row" alignItems="center" pb={2} spacing={1} sx={{borderBottom: '0.5px solid #CBD5E1'}}>
                                                {/* <img src={avatar} alt={avatar} /> */}
                                                {user_data.user && user_data.user.img ? <UploadedProfilePic width={32} height={32} src={user_data.user.img} /> : <DefaultProfilePic width={32} height={32} fontSize={14} />}
                                                <Typography variant="h6" color='info.main' component="h2">
                                                    {(user_data.isAuthenticated && user_data.user.firstName !== null && user_data.user.firstName !== undefined && user_data.user.firstName !== "" ) || (user_data.isAuthenticated && user_data.user.lastName !== null && user_data.user.lastName !== undefined && user_data.user.lastName !== "") ? user_data.user.firstName + " "+ user_data.user.lastName : "Name"}
                                                </Typography>
                                                {/* <h2 className="">{apidata.userdetails.name}</h2> */}
                                            </Stack>
                                            <Box py={2} sx={{borderBottom: '0.5px solid #CBD5E1'}}>
                                                <Typography variant="body1" component="p" color="secondary" sx={{fontWeight: 600, letterSpacing: '-0.02em' }}>
                                                    <i className="far fa-clock mr-2"></i> {apidata.eventdetails.event_duration} Mintues
                                                </Typography>
                                            </Box>
                                                <Typography variant="body1" component="p" color="secondary" py={2}>
                                                    {apidata.eventdetails.description}
                                                </Typography>
                                        {(id) ?
                                            <>
                                                <Typography variant="body1" color="secondary">
                                                    <i className="fal fa-calendar mr-2"></i> {moment(input.edit_date).format('dddd, MMMM Do')} at {input.start_time}
                                                </Typography>
                                            </> : ""
                                        }
                                        </ThemeProvider>
                                    </Box>
                                </div>
                                <Calender disabledDates={apidata.disabled_datas} edit={id} value={input.calender_data} fun={selectDays} />
                                {(input.show_slot.show) ? <Time data={input.show_slot.slots} fun={selecttime} UserName={apidata.userdetails.name} eveduration={apidata.eventdetails.event_duration} /> : ""}


                            </div>
                        </div>
                    </div>

                    {(input.same_page) ? <Modal2 date={input.date} id={input.response_data._id} name={apidata.userdetails} eventname={apidata.eventdetails} time={input.start_time} email={input.email} event_duration={apidata.eventdetails.event_duration} username={input.name} fun={setinput} /> : <Modal date={input.date} id={id} name={apidata.userdetails.name} eventname={apidata.eventdetails.name} time={input.start_time} email={input.email} quesAns={input.quesAns} event_duration={apidata.eventdetails.event_duration} username={input.name} error={error} fun={changehandler} submit={submit} loading={input.loading} meetingLink={input.meetingLink} meeting_url={input.meeting_url}/>}
                </section>
                <ToasterC />

            </>
        )
    }
    else {
        return <Loader />
    }

};
export default Main;