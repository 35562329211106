import { io } from "socket.io-client";
var socket =io(process.env.REACT_APP_socket_server);
let obj={
    connectSocket:async (id,fun)=>{
    socket.emit("setSocket",id);
    socket.on("setSocketResponse",(data)=>{
        fun();
    })
    
   },
   get_notification:(fun)=>{
    socket.on("notifications",(data)=>{
        fun(data);
    });
   }
 
}
export default obj;