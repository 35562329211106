import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
    palette: {
        primary: {
            light: '#EEF3FF',
            light2: '#DDE8FF',
            main: '#1155ED',
            dark: '#0D40B2',
            // dark: will be calculated from palette.primary.main,
            // contrastText: will be calculated to contrast with palette.primary.main
        },
        secondary: {
            light: '#415980',
            main: '#202C40',
            dark: '#141A26',
            //     // dark: will be calculated from palette.secondary.main,
            //     contrastText: '#ffcc00',
        },
        info: {
            light: '#64748B',
            light2: '#CBD5E1',
            main: '#0F172A',
            dark: '#475569'
        },
        success: {
            light: '#E5FAF0',
            main: '#10B981',
            dark: '#059669',
        },
        error: {
            light: '#FEF2F2',
            light2: '#F87171',
            main: '#EF4444'
        },
        // Used by `getContrastText()` to maximize the contrast between
        // the background and the text.
        // contrastThreshold: 3,
        // Used by the functions below to shift a color's luminance by approximately
        // two indexes within its tonal palette.
        // E.g., shift from Red 500 to Red 300 or Red 700.
        // tonalOffset: 0.2,
    },
    typography: {
        fontFamily: [
            "'Red Hat Display', sans-serif"
        ],
        h1: {
            fontSize: '30px',
            fontWeight: '700',
            // letterSpacing: '-0.04em'
        },
        h2: {
            fontSize: '30px',
            fontWeight: '700',
        },
        h3: {
            fontSize: '24px',
            fontWeight: '600'
        },
        h5: {
            fontSize: '20px',
            fontWeight: '700',
            // letterSpacing: '-0.02em'
        },
        h6: {
            fontSize: '18px',
            fontWeight: '600'
        },
        subtitle2: {
            fontWeight: '400'
        }
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: ({ ownerState }) => ({
                    ...(ownerState.size === 'large' && {
                        fontSize: '16px',
                        width: '100%',
                        textTransform: 'capitalize'
                    }),

                }),
            },
        },
        MuiFormControl: {
            styleOverrides: {
                root: {
                    background: '#fff',
                    marginBottom: '30px',
                }
            }
        },
        MuiInputLabel: {
            styleOverrides: {
                root: ({ theme }) => ({
                    transform: 'translate(14px, 13px) scale(1)',
                    color: theme.palette.info.dark,
                    fontWeight: 500,
                    '&.Mui-focused': {
                        transform: 'translate(14px, -9px) scale(0.75)!important',
                        color: `${theme.palette.primary.main}!important`,
                        fontWeight: 700,
                    },
                    '&.MuiFormLabel-filled': {
                        transform: 'translate(14px, -9px) scale(0.75)',
                        fontWeight: 700,
                    },
                    '&#multi-select-label': {
                        transform: 'translate(14px, 7px) scale(1)'
                    },
                }),
            }
        },
        MuiInputBase: {
            styleOverrides: {
                input: ({ theme }) => ({
                    boxSizing: 'border-box',
                    padding: '.375rem .75rem!important',
                    color: theme.palette.info.main,
                    height: '50px',
                    fontSize: 14,
                    fontWeight: 500,
                    '&::placeholder': {
                        color: '#000'
                    },
                    '&.Mui-disabled': {
                        backgroundColor: '#e9ecef'
                    },
                    '&.MuiInputBase-inputMultiline': {
                        height: '100px!important',
                        padding: '0!important'
                    },
                }),
            }
        },
        MuiSelect: {
            styleOverrides: {
                select: ({ theme }) => ({
                    height: '50px',
                    padding: '14px 40px 14px 20px !important',
                }),
                multiple: {
                    padding: '0!important',
                    height: 34,
                },
            },
        },
        MuiFormGroup: {
            styleOverrides: {
                root: {
                    'label': {
                        display: 'flex',
                        justifyContent: 'space-between',
                    }
                }
            }
        },
        MuiSwitch: {
            styleOverrides: {
                'track': ({ theme }) => ({
                    backgroundColor: '#E2E8F0',
                    borderRadius: '12px',
                    opacity: '1!important',
                }),
                'thumb': {
                    background: '#fff',
                },
                'switchBase': ({ theme }) => ({
                    top: '5px',
                    left: '6px',
                    '&.Mui-checked': {
                        transform: 'translateX(16px)'
                    },
                    '&.Mui-checked + .MuiSwitch-track': {
                        backgroundColor: theme.palette.success.main
                    }
                }),
                'edgeStart': {},
                root: {
                    width: '66px',
                    height: '48px'

                }
            }
        },
        MuiDialog: {
            styleOverrides: {
                'root': {

                    '& .MuiPaper-root': {
                        boxShadow: '0px 4px 30px rgba(0, 0, 0, 0.06)',
                        borderRadius: 0
                    }
                },
            }
        },
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 768,
            md: 992,
            lg: 1200,
            xl: 1536,
        },
    }
});