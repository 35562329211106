import $ from "jquery";
import AOS from 'aos';

// -------------------------- Mobile Nav
// if ($(".theme-main-menu").length) {
    $('.theme-main-menu .navbar-toggler').on('click', function() {
        $(".navbar-collapse").toggleClass("show");
        console.log('clicked');
    });
// }

// ------------------------ Navigation Scroll
$(window).on('scroll', function() {
    var sticky = $('.sticky-menu'),
        scroll = $(window).scrollTop();
    if (scroll >= 100) sticky.addClass('fixed');
    else sticky.removeClass('fixed');

});
// -------------------------- Mobile Nav
if ($(".theme-main-menu").length) {
    $('.theme-main-menu .navbar-toggler').on('click', function() {
        $(".navbar-collapse").toggleClass("show");
    });
}
// ------------------------------- AOS Animation

AOS.init({
    duration: 1000,
    mirror: true
});



    // ------------------------------------- Fancybox
    var fancy = $(".fancybox");
    if (fancy.length) {
        fancy.fancybox({
            arrows: true,
            buttons: [
                "zoom",
                //"share",
                "slideShow",
                //"fullScreen",
                //"download",
                "thumbs",
                "close"
            ],
            animationEffect: "zoom-in-out",
            transitionEffect: "zoom-in-out",
        });
    }