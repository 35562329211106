import React from 'react';
import { useAuthContext } from '../../contexts/AuthContext';
import { Avatar } from '@mui/material';
import { theme } from './Theme';
const DefaultProfilePic = (props) => {
    const user_data = useAuthContext();
    return (<>
        <Avatar sx={{ bgcolor: theme.palette.primary.main, width: props.width, height: props.height, fontSize: props.fontSize }}>
            {(user_data.isAuthenticated && user_data.user.firstName !== null && user_data.user.firstName !== undefined && user_data.user.firstName !== "" ) || (user_data.isAuthenticated && user_data.user.lastName !== null && user_data.user.lastName !== undefined && user_data.user.lastName !== "") ? user_data.user.firstName.charAt(0) + user_data.user.lastName.charAt(0) : "Name"}
        </Avatar>
    </>);
}

export default DefaultProfilePic;