import React from "react";

const FeatureSmallCard = (props) => {
    return (
        <>
            <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay={props.animDelay}>
                <div class="block-style-eight">
                    <h6 class="title">{props.title}</h6>
                    <p>{props.desc}</p>
                </div>
                {/* <!-- /.block-style-eight --> */}
            </div>
        </>
    );
}

export default FeatureSmallCard;