import React from 'react';
import Footer from './LaComponents/Footer';
import ScrollTopBtn from './LaComponents/ScrollTopBtn';

const Terms = () => {
    return (<>
        {/* <!-- Privacy Policy Section --> */}
    <section className="policy">
        <div className="container">
            <div className="content-wrapper">
                <div className="section-title">
                <h1>Terms & Conditions</h1>
                    <p>We commit to conducting ourselves and our business with the highest moral and ethical principles, and we expect our customers to do the same. These Terms and Conditions explain how we conduct ourselves with you and control how you
                        conduct yourself while using our service. If you do not wish to use this service in compliance with these Terms and Conditions, please proceed to another website; Your use of this website constitutes your approval of these Terms
                        and Conditions.</p>
                </div>
                <div className="policy-content">
                <div class="text-que-1">
                        <h3>General</h3>
                        <p>It is solely the responsibility of the Customer to keep RIBBASH up to date on any changes in their contact details.</p>
                        <p>Failure to do so may result in service expiry or inability to render ongoing services.</p>
                        <p>An account with RIBBASH is to be used only by the Customer associated with the account.</p>
                        <p>RIBBASH reserves the right to refuse its services and products to anyone.</p>
                        <p>This includes the right to suspend or cancel a Customer’s access to existing services in the violation of this Terms Agreement.</p>
                        <p>All invoices, quotes, service announcements, and other notifications will be sent to the Customer via email.</p>
                    </div>
                    <div class="text-que-2">
                        <h3>The Contract</h3>
                        <p>A self-employed entity-relationship will be made between the customers and ____, and that no organization or joint endeavor is expected or suggested by one or the other party.</p>
                        <p>The date of the beginning of the administration will be settled upon by the two players, and charges will be relevant to that date.</p>
                        <p>A month-to-month report of execution administrations will be given to the customers.</p>
                        <p>Either gathering may not drop or completely move the administration duties to another assistance merchant before an earlier notification of in any event 10 business days.</p>
                        <p>RIBBASH maintains all authority to subcontract to an outsider specialist organization for a portion of the administration errands.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
        {/* <!--
			=====================================================
				Footer Style Four
			=====================================================
			--> */ }
            <Footer />
        {/* <!-- /.theme-footer-four --> */}
        {/* <!-- Scroll Top Button --> */}
        <ScrollTopBtn />        
    </>);
}

export default Terms;
