import react from "react";
import { Link } from "react-router-dom";
import dotenv from "dotenv";
import base from "./../../helper/base";
import urlhelper from "./../../helper/urlhelper";
import { useAuthContext } from './../../contexts/AuthContext';
import { Box, Button, ThemeProvider, Typography } from "@mui/material";
import { theme } from "../AppTheme/Theme";
import { Dropdown } from "react-bootstrap";
dotenv.config();
const Fun = (props) => {
    const user_data = useAuthContext();
    const copyToClipboard = event => {
        const el = document.createElement('textarea');
        el.value = event.currentTarget.getAttribute("data-id");
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
    };
    const MeetingLink = {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        fontWeight: 500
    };
    const flexBox = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: '1px solid rgba(43, 60, 99, 0.1)',
        paddingBottom: '15px'
    };
    const DescriptionStyle = {
        display: '-webkit-box',
        'WebkitLineClamp': '3',
        'WebkitBoxOrient': 'vertical',
        overflow: 'hidden',
        fontWeight: 500
    };
    const copyBtn = {
        backgroundColor : 'rgba(92, 132, 219, 0.15)',
        color : '#1155ED',
        padding: '7px',
        boxShadow : 'none',
        minWidth: 'unset',
        borderRadius: '3px',
        '&:hover': {
            color: '#fff'
        }
    }
    return (<>
        <div className="col-lg-4 col-md-6 mb-4">
            <ThemeProvider theme={theme}>
                <Box className="booking-info-col">
                    {/* Top Bar */}
                    <Box sx={flexBox}>
                        <Typography variant="h6" color='secondary'>
                            {props.data.name}
                        </Typography>
                        <Dropdown>
                            <Dropdown.Toggle variant="none" id="cardOptions" style={{ fontSize: '24px', boxShadow: 'none', content: 'none', padding: '0' }}>
                                <i className="fal fa-ellipsis-v"></i>
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item type='button'><Link to={() => urlhelper.getAbsoluteUrl(user_data.user.username, "createevent", base.encrypt(props.data._id))}>Edit</Link></Dropdown.Item>
                                <Dropdown.Item type='button' onClick={props.delete}>Delete</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </Box>
                    {/* Meeting Link */}
                    <Box className="meeting-link" sx={flexBox} mt={2}>
                        <Link to={`${urlhelper.getAbsoluteUrl(user_data.user.username, props.data.event_name)}`} style={{ overflow: 'hidden', width: '75%' }}>
                            <Typography variant="subtitle2" color='secondary' sx={MeetingLink}>
                                {`${process.env.REACT_APP_App_url}${urlhelper.getAbsoluteUrl(user_data.user.username, props.data.event_name)}`}
                            </Typography>
                        </Link>
                        <Button variant="contained" sx={copyBtn} onClick={copyToClipboard} id={props.data._id} data-id={`${process.env.REACT_APP_App_url}${urlhelper.getAbsoluteUrl(user_data.user.username, props.data.event_name)}`}><i className="far fa-clone"></i></Button>
                    </Box>
                    <Box className="description" pt={2}>
                        <Typography variant="h6" color='secondary'>
                            {props.data.event_duration} Mintues Meeting
                        </Typography>
                        <Typography variant="body2" color='secondary.light' mt={1} sx={DescriptionStyle}>
                            {props.data.description}
                        </Typography>
                    </Box>
                </Box>
            </ThemeProvider>
        </div>
        {/* <div className="col-lg-4 col-md-6 mb-4">
            <div className="booking-info-col">
                <h2>{props.data.name}</h2>
                <div className="booking-on-off">
                    <div className="d-flex justify-content-between align-items-center">
                        <p>{(props.data.status === 1) ? "Booking is on" : "Booking is Off"}</p>
                        <div className="toggle-btn">
                            <label className="switch">
                                <input type="checkbox" name={base.encrypt(props.data._id)} data-index={props.index} onChange={props.fun} checked={props.che} />
                                <span className="slider"></span>
                            </label>
                        </div>
                    </div>
                </div>

                <div className="meeting-link">
                    <div className="d-flex flex-wrap justify-content-between">
                        <Link to="/#" ><i className="fal fa-paperclip"></i>/{props.data.event_name}</Link>

                        <button type="button" onClick={copyToClipboard} id={props.data._id} data-id={`${process.env.REACT_APP_App_url}${urlhelper.getAbsoluteUrl(user_data.user.username, props.data.event_name)}`} className="btn p-0" data-toggle="tooltip" data-placement="bottom" title="Copied!">Copy</button>
                    </div>
                    <p><i className="fal fa-clock"></i> {props.data.event_duration} Mintues</p>
                </div>

                <p className="meeting-description">{props.data.description}</p>
                <div className="booking-manage-button">
                    <div className="d-flex flex-wrap justify-content-between">

                        <div className="col-md-6 pl-md-0 pr-md-2">
                            <button type="button" className="btn btn-delete" onClick={props.delete}>Delete</button>
                        </div>

                        <div className="col-md-6 pr-md-0 pl-md-2 mt-3 mt-md-0">
                            <Link to={() => urlhelper.getAbsoluteUrl(user_data.user.username, "createevent", base.encrypt(props.data._id))}>   <button type="button" className="btn btn-edit">Edit</button></Link>
                        </div>
                    </div>
                </div>
            </div>
        </div> */}
    </>
    )


}
export default react.memo(Fun);