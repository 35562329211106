import React, { useState } from "react";
import api from "../component/Api";
import { theme } from "./AppTheme/Theme";
import Er from "./Error";
import Toaster, { ToasterC } from "./Toaster";
import { Box, TextField, ThemeProvider, Typography } from "@mui/material";


const ResetPassword = () => {
    const [inpEmail, setInpEmail] = useState('');
    const [error, setError] = useState("");
    const [btn_dis, setbtndis] = useState(false);
    const handleEmail = (event) => {
        setInpEmail(event.target.value);
    };
    const handleResetPwd = async (e) => {
        e.preventDefault();
        setbtndis(true);
        try {
            await api.reset_password(inpEmail);
            Toaster.success("Email has been sent ! please check your mail box and reset your password");
        } catch (e) {
            setError(e);
        }
        setbtndis(false);

    };

    return (<>
        <section className="site-main">
            <div className="container">
                {/* <!-- Page content --> */}
                <div className="page-content">
                    <div className="main-row row justify-content-center align-items-center">
                        <div className="page-col">
                            <ThemeProvider theme={theme}>
                                {/* <!-- Section Title --> */}
                                <Box className="section-title rec_pass">
                                    {/* <h1>Recover Password</h1> */}
                                    <Typography component='h1' variant="h1" color={theme.palette.secondary.main}>Recover Password</Typography>
                                </Box>
                                {/* <!-- Form Fields --> */}
                                <Box className="form-col">
                                    <Box component="form" onSubmit={handleResetPwd}>
                                        <TextField type="email" label="Enter your Email Address" placeholder="Email" name="email" value={inpEmail} onChange={handleEmail} required fullWidth />
                                        {(error.email) ? <Er value={error.email} /> : ""}
                                        {/* <div className="from-group mb-4">
                                    <label htmlFor="email" className="mb-2">Enter your Email Address</label>
                                    <input className="form-control" type="email" placeholder="Email" name="email" value={inpEmail} onChange={handleEmail} required/>
                                </div>
                                {(error.email)? <Er value={error.email} />:""} */}
                                        {/* <!-- Submit button --> */}
                                        {(btn_dis) ? <div className="spinner-border" style={{ display: "flex", position: "relative", marginLeft: "90%", top: "40px" }}></div> : ""}
                                        <input className="form-control" type="submit" value="Recover" disabled={btn_dis ? "disabled" : ""} />
                                    </Box>
                                </Box>
                            </ThemeProvider>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <ToasterC />
    </>);
}

export default ResetPassword;