import React from "react";
import { NavLink } from "react-router-dom";
import logo from './../../../assets/images/logo.svg';


const Header = () => {
    const scrollToTop = () =>{
        window.scrollTo({
          top: 0, 
          behavior: 'smooth'
        });
      };
    return (
        <>
            <div class="theme-main-menu sticky-menu theme-menu-four bg-none">
            <div class="d-flex align-items-center justify-content-between">
                <div class="logo">
                    <NavLink to="/"><img src={logo} alt="logo" onClick={scrollToTop} /></NavLink>
                </div>
                <nav id="mega-menu-holder" class="navbar navbar-expand-lg">
                    <div class="container nav-container">
                        <button class="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#landingNav" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <i class="fal fa-bars"></i>
						    </button>
                        <div class="navbar-collapse collapse" id="landingNav">
                            <div class="d-lg-flex align-items-center">
                                <ul class="navbar-nav">
                                    <li class="nav-item">
                                        <NavLink class="nav-link" to="/" onClick={scrollToTop}>Home</NavLink>
                                    </li>
                                    <li class="nav-item">
                                        <NavLink class="nav-link" to="/features" onClick={scrollToTop}>Features</NavLink>
                                    </li>
                                    <li class="nav-item">
                                        <NavLink class="nav-link" to="/how-it-works" onClick={scrollToTop}>How It Works</NavLink>
                                    </li>
                                    <li class="nav-item">
                                        <NavLink class="nav-link" to="/pricing" onClick={scrollToTop}>Pricing</NavLink>
                                    </li>
                                    <li class="nav-item">
                                        <NavLink class="nav-link" to="/about-us" onClick={scrollToTop}>About Us</NavLink>
                                    </li>
                                    <li class="nav-item">
                                        <NavLink class="nav-link" to="/contact-us" onClick={scrollToTop}>Contact Us</NavLink>
                                    </li>
                                </ul>
                                <ul class="right-button-group d-flex align-items-center justify-content-center">
                                    <li>
                                        <a href="/login" class="signIn-action">Login</a>
                                    </li>
                                    <li>
                                        <a href="/register" class="theme-btn-five">Create your event</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
        </div>
        </>

    )
}
export default Header;