import { timeToInt, timeFromInt } from 'time-number';
import moment from "moment"

let obj = {
    gettime: (add_data, data, endtime = 86400, x = 0, edit = false) => {
        let busy_slots = [];
        if (data.length !== 0) {
            let s;
            let e;
            for (let a of data) {
                s = timeToInt(a.from);
                e = timeToInt(a.to);
                while (s <= e) {
                    busy_slots = [...busy_slots, s];
                    s = s + 1800;
                }
            }
            let new_arr = []
            while (x < endtime) {
                if (!busy_slots.includes(x)) {
                    new_arr = [...new_arr, x];
                }
                x = x + 1800;
            }

            let start = timeToInt(add_data.from);
            let end = timeToInt(add_data.to);
            while (start <= end) {
                new_arr = [...new_arr, start];
                start = start + (60 * 30);
            }

            new_arr.sort((a, b) => { return a - b });
            if (edit && new_arr[new_arr.length - 1] < 84600) {

                new_arr = [...new_arr, new_arr[new_arr.length - 1] + 1800]
            }
            return new_arr;
        }

    },
    new_add: (time_limit, endtime = 86400, x = 0) => {
        let start = timeToInt(time_limit.from);
        let end = timeToInt(time_limit.to);

        let new_arr = [];
        while (x < endtime) {
            if (x < start) {
                new_arr = [...new_arr, x];
            } else {
                if (x > end) {
                    new_arr = [...new_arr, x];
                }

            }

            x = x + (60 * 30)
        }
        if (new_arr.length > 0) {
            let new_start;
            let new_end;
            if (new_arr[0] < start) {
                new_start = new_arr[0];
                new_end = start - 1800;
            } else {
                new_start = end + 1800;
                new_end = new_arr[new_arr.length - 1]
            }

            let new_time_limit = time_limit;
            if (new_start < start) {
                new_time_limit = {...new_time_limit, from: timeFromInt(new_start, { format: 12 }) }
            }
            if (new_end > end) {
                new_time_limit = {...new_time_limit, to: timeFromInt(new_end, { format: 12 }) }
            }
            return { initial_value: { from: timeFromInt(new_start, { format: 12 }), to: timeFromInt(new_end, { format: 12 }) }, new_time_limit: new_time_limit };
        } else {
            return false;
        }

    },
    setnewtimelimit: (data) => {
        let from_arr = [];
        let to_arr = [];

        if (data.length !== 0) {
            for (let x of data) {
                from_arr = [...from_arr, timeToInt(x.from)];
                to_arr = [...to_arr, timeToInt(x.to)];
            }
            return { from: timeFromInt(Math.min(...from_arr), { format: 12 }), to: timeFromInt(Math.max(...to_arr), { format: 12 }) };
        }
        return false;
    },
    disabled_dates: (event_time_limit, booked_event) => {
        let start = moment().weekday();
        let end = start + 8;
        const days = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];
        let disable_arr = [];
        let obj = {}
        let new_d;
        while (start <= end) {
            new_d = new Date(moment().day(start));
            if (Object.keys(event_time_limit[days[new_d.getDay()]]).length === 0) {
            disable_arr.push(new_d.getDay())
            }
            start++;
        }
        // if(booked_event.length !==0 && disable_arr.length !==0){

        // }
        return disable_arr;
    },
    get_slots: ( booked_event, schedule_timings, select_date, edit_slot = false, google_events = [], timeDuration) => {
        let start;
        let end;
        var today = new Date();
        console.log(schedule_timings)
        var time = timeToInt(today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds());
        let compare = `${today.getFullYear()}-${today.getMonth()+1}-${today.getDate()}` === `${select_date.getFullYear()}-${select_date.getMonth()+1}-${select_date.getDate()}`;
        let new_arr = [];
        if (schedule_timings.length !== 0) {
            for (let x of schedule_timings) {
                start = timeToInt(x.from);
                end = timeToInt(x.to);
                while (start < end) {
                    if (compare) {

                        if (start > time) {
                            new_arr = [...new_arr, start];
                        }
                    } else {
                        new_arr = [...new_arr, start];
                    }

                    start = start + (timeDuration * 60);
                }
            }
            let book_slot = [];
            if (booked_event.length !== 0 && new_arr.length !== 0) {
                for (let data of booked_event) {
                    if (data.status !== 1 || data.status !== 2) {
                        if (select_date.toString() === data.date) {
                            book_slot = [...book_slot, timeToInt(data.start_time)];
                        }

                    }
                }
            }

            if (google_events.length !== 0) {
                for (let data of google_events) {
                    let g_sdate = new Date(data.start.dateTime);
                    let g_edate = new Date(data.end.dateTime);
                    if (`${g_sdate.getFullYear()}-${g_sdate.getMonth()+1}-${g_sdate.getDate()}` === `${select_date.getFullYear()}-${select_date.getMonth()+1}-${select_date.getDate()}` && data.status !== "cancelled") {
                        let start_t = timeToInt(g_sdate.getHours() + ":" + g_sdate.getMinutes() + ":" + g_sdate.getSeconds());
                        let end_t = timeToInt(g_edate.getHours() + ":" + g_edate.getMinutes() + ":" + g_edate.getSeconds());
                        while (start_t < end_t) {
                            book_slot = [...book_slot, start_t];
                            start_t = start_t + (timeDuration * 60);
                        }
                    }

                }
            }

            if (book_slot.length !== 0) {

                new_arr = new_arr.filter((val) => {
                    return book_slot.includes(val) === false;
                })

            }

            //console.log(edit_slot);
            if (edit_slot) {
                new_arr = [...new_arr, timeToInt(edit_slot)];
            }

            if (new_arr.length !== 0) {
                new_arr.sort((a, b) => { return a - b });
            }

            return new_arr;
        }


    },
    getAllSlots:(startDay=false,duration=30)=>{
        let start=(startDay)?moment().format():moment().startOf("day").unix();
        let end=moment().startOf("day").add(1,"day").unix();
        let newArr=[]
        while(start <= end){
        newArr.push(start)
        start=moment.unix(start).add(duration,"m").unix();
        }
        return newArr;
    },
    getSlotsInfo:(allSlots,timeSchedule=[],edit=false)=>{
        let availableTime=allSlots;
        let occupyTime=[];
        if(allSlots.length && timeSchedule.length){
              for(let time of timeSchedule){
                let from=moment(`${moment(new Date('2019/06/01')).startOf("day").format("MM-DD-YYYY")} ${time.from}`).unix();
                let to=moment(`${moment(new Date('2019/06/01')).startOf("day").format("MM-DD-YYYY")} ${time.to}`).unix();
                occupyTime.push(availableTime.filter((val)=>val >=from && val <=to));
                availableTime=availableTime.filter((val)=>val < from || val >= to);
             }
        }
        return {availableTime,occupyTime}
    },
    addNewSlot:(availableTime=[],timeLimit)=>{
             let from=moment(`${moment(new Date('2019/06/01')).startOf("day").format("MM-DD-YYYY")} ${timeLimit.from}`).unix()
             let to=moment(`${moment(new Date('2019/06/01')).startOf("day").format("MM-DD-YYYY")} ${timeLimit.to}`).unix();
             let newAvailableTime=[];
             let newOccupyTime=[];
             let newTimeLimit={from:timeLimit["from"],to:timeLimit["to"]}
             let flag=true;
             let toIndex;
             for(let i in availableTime){
                if(flag){
                newOccupyTime.push(Number(availableTime[i]));
                toIndex=i;
                }
                else{
                newAvailableTime.push(Number(availableTime[i]));
                }
                if(Number(availableTime[Number(i)+1]) - Number(availableTime[i]) !==1800){
                flag=false;
               }
               }
               if(newOccupyTime[0] < from){
                newTimeLimit["from"]=moment.unix(Number(newOccupyTime[0])).format("h:mm A")
                }
                else if(newOccupyTime[0] + 1800 > to){
                newTimeLimit["to"]=moment.unix(Number(newOccupyTime[0] + 1800)).format("h:mm A")
                }
                
                if(!toIndex){
                   toIndex=newOccupyTime[newOccupyTime.length-1];
                }
             return {slotInfo:{availableTime:newAvailableTime,occupyTime:newOccupyTime},
             timeLimit:newTimeLimit,
             newTimeSlot:{
             from:moment.unix(newOccupyTime[0]).format("h:mm A"),
             to:(newOccupyTime[0] < from)?moment.unix(newOccupyTime[toIndex] +1800).format("h:mm A"):moment.unix(newOccupyTime[toIndex]).format("h:mm A")
            }
            }
    },
    deleteSlot:(input,name,index)=>{
            let deleteSlot=input.slotsInfo[name].occupyTime[index];
            let newAvailableTime=[...deleteSlot,...input.slotsInfo[name].availableTime];
            newAvailableTime.sort((a,b)=>a-b);
            input.slotsInfo[name].availableTime=newAvailableTime;
            let newOccupyValues=[];
            let newScheduleTimings=[];
            let travesingValues=input.slotsInfo[name].occupyTime;
            let from=moment(`${moment(new Date('2019/06/01')).startOf("day").format("MM-DD-YYYY")} ${input.time_limit[name].from}`).unix()
            let to=moment(`${moment(new Date('2019/06/01')).startOf("day").format("MM-DD-YYYY")} ${input.time_limit[name].to}`).unix();
            let newTimeLimit={from:from,to:to};
            if(input.time_limit[name].to ==="12:00 AM"){
                to=to-60;
            }
            for(let x in travesingValues){
            if(Number(x) !==index){
                    newScheduleTimings.push(input.schedule_timings[name][x]);
                    newOccupyValues.push(travesingValues[x]);
            }
            }
            input.slotsInfo[name]={...input.slotsInfo[name],occupyTime:newOccupyValues,availableTime:newAvailableTime}
            newOccupyValues.sort((a,b)=>a-b);
            input.time_limit[name]={...newTimeLimit,from:moment.unix(newOccupyValues[0][0]).format("h:mm A"),to:moment.unix(newOccupyValues[newOccupyValues.length-1][newOccupyValues[newOccupyValues.length-1].length-1]).format("h:mm A")}
            
            return input;
    },
    setSlot:(input,name,index,value,dir)=>{
        input.schedule_timings[name][index][dir]=moment.unix(Number(value)).format("h:mm A");
        if(dir==="from"){
            input.schedule_timings[name][index].to=moment.unix(Number(value) + 1800).format("h:mm A");
        }
        let from=moment(`${moment(new Date('2019/06/01')).startOf("day").format("MM-DD-YYYY")} ${input.schedule_timings[name][index].from}`).unix()
        let to= moment(`${moment(new Date('2019/06/01')).startOf("day").format("MM-DD-YYYY")} ${input.schedule_timings[name][index].to}`).unix()
        let oldValues=input.slotsInfo[name].occupyTime[index];
        let oldAvailableTime=input.slotsInfo[name].availableTime;
        let newArr=[...oldAvailableTime,...oldValues];
        let newOccupyTime=newArr.filter(val=>val >= from && val <=to);
        let newAvailableTime=newArr.filter(val=> val < from || val > to);
            newAvailableTime.sort((a,b)=>a-b);
            input.slotsInfo[name].occupyTime[index]=newOccupyTime;
            input.slotsInfo[name].availableTime=newAvailableTime;
            newOccupyTime.sort((a,b)=>a-b);
            input.time_limit[name]={from:moment.unix(newOccupyTime[0][0]).format("h:mm A"),to:moment.unix(newOccupyTime[newOccupyTime.length-1][newOccupyTime[newOccupyTime.length-1].length-1]).format("h:mm A")}
           return input
    }
    }
       
export default obj;