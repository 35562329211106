import TimezonePicker from 'react-timezone';
const Fun=(props)=>{

return (
    <>
     <TimezonePicker
     value={props.data.value}
     onChange={props.data.fun}
     inputProps={{
      placeholder: 'Select Timezone',
      name: 'time_zone',
    }}
  />
    </>
)
}
export default Fun;