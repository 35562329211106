import { useState, useEffect } from "react";
import Api from "../Api";
import Cards from "./Cards";
import Loader from "./../../component/loader";
import base from "./../../helper/base";
import { useAuthContext } from './../../contexts/AuthContext';
import { Box, ThemeProvider, Typography } from "@mui/material";
import { theme } from "../AppTheme/Theme";
import UploadedProfilePic from "../AppTheme/UploadedProfilePic";
import DefaultProfilePic from "../AppTheme/DefaultProfilePic";
const Main = () => {
  const [apidata, setapidata] = useState({ loader: true, cards: [] });
  const user_data = useAuthContext();
  console.log(user_data)
  useEffect(() => {
    let fetchData = async () => {
      try {
        let data = await Api.dashboard_data();
        if (data.event_data.length !== 0) {
          for (let x of data.event_data) {
            let key = base.encrypt(x._id);
            var obj = { ...obj, [key]: (x.status === 1) ? true : false }
          }
        }

        setapidata({ loader: false, cards: data.event_data });
      } catch (e) {
        console.log(e);
      }
    }
    fetchData();

  }, []);

  useEffect(() => {
    return () => {
      setapidata({ loader: false, cards: [] });
    }
  }, []);


  if (!apidata.loader) {
    var cards
    if (apidata.cards !== undefined && apidata.cards.length !== 0) {
      cards = apidata.cards.map((key, index) => {
        return (key.status === 1) ? <Cards key={index} index={index} data={key} /> : ""
      });

    }
    else {
      cards = "There is no Events"
    }

    const ProfileInfo = {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    }
    return (
      <>

        <section className="site-main py-5">
          <div className="container">
          <ThemeProvider theme={theme}>
            <Box className="profile-info" sx={ProfileInfo} mb={4}>
              {/* <img src={(user_data.isAuthenticated && user_data.user.img !== null && user_data.user.img !== undefined && user_data.user.img !== "") ? user_data.user.img : avatar} alt="img" /> */}
              {/* <h2>{(user_data.isAuthenticated && user_data.user.name !== null && user_data.user.name !== undefined && user_data.user.name !== "") ? user_data.user.name : "anymous"}</h2> */}
              {user_data.user.img ? <UploadedProfilePic  width={120} height={120} src={user_data.user.img} /> : <DefaultProfilePic width={120} height={120} fontSize={50} />}
              <Typography variant="h2" mt={2} color='secondary' sx={{letterSpacing: '-0.04em'}}>
                {(user_data.isAuthenticated && user_data.user.firstName !== null && user_data.user.firstName !== undefined && user_data.user.firstName !== "" ) || (user_data.isAuthenticated && user_data.user.lastName !== null && user_data.user.lastName !== undefined && user_data.user.lastName !== "") ? user_data.user.firstName + " "+ user_data.user.lastName : "Name"}
              </Typography>
            </Box>
            </ThemeProvider>
            <div className="dashboard-content">
              <div className="row">

                {

                  cards
                }
              </div>
            </div>
          </div>
        </section>


      </>
    )
  }
  else {
    return <Loader />
  }

};
export default Main;