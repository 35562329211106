import React, { useState, useCallback, useEffect } from 'react';
import validate from './../helper/validation';
import urlhelper from './../helper/urlhelper';
import Er from "./Error"
import api from "./Api"
import GoogleSocialLogin from "./../helper/GoogleSocialLogin"
// import FacebookSocialLogin from "./../helper/FacebookSocialLogin"
import Storage from "./../helper/Storage"
import { useAuthContext } from './../contexts/AuthContext'
import { NavLink, useHistory } from "react-router-dom";
import Toaster, { ToasterC } from "./Toaster"
import { Box, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField, ThemeProvider } from '@mui/material';
import { theme } from './AppTheme/Theme';
import { Visibility, VisibilityOff } from '@mui/icons-material';
const Fun = () => {
    const history = useHistory();
    const user_data = useAuthContext();
    const [btn_dis, setbtndis] = useState(false);
    const [type, settype] = useState("password");
    const [input, setIput] = useState({ firstName: "", lastName: "", email: "", password: "", confirmpassword: "" });
    const [error, setError] = useState({ firstName: false, lastName: false, email: false, password: false, confirmpassword: false })
    const [color, setColor] = useState({ veryweak: "", weak: "", normal: "", strong: "", verystrong: "" });
    const changetype = useCallback(() => {
        settype((prevState) => {
            if (prevState === "password") {
                return "text";
            }
            else {
                return "password"
            }
        })
    }, []);
    const changecolor = (event) => {
        setColor((prevState) => {
            if (event.target.value === "" || event.target.value === undefined) {
                return {
                    veryweak: "",
                    weak: "",
                    normal: "",
                    strong: "",
                    verystrong: ""
                }
            }
            else if (event.target.value.length < 3) {
                return {
                    veryweak: "#E22357",
                    weak: "",
                    normal: "",
                    strong: "",
                    verystrong: ""
                }
            }
            else if (event.target.value.length >= 3 && event.target.value.length < 5) {
                return {
                    veryweak: "#E22357",
                    weak: "#F98D00",
                    normal: "",
                    strong: "",
                    verystrong: ""
                }
            }
            else if (event.target.value.length >= 5 && event.target.value.length < 9) {
                return {
                    veryweak: "#E22357",
                    weak: "#F98D00",
                    normal: "#57899E",
                    strong: "",
                    verystrong: ""
                }
            }
            else if (event.target.value.length >= 9 && event.target.value.length < 15) {
                return {
                    veryweak: "#E22357",
                    weak: "#F98D00",
                    normal: "#57899E",
                    strong: "#93ccaf",
                    verystrong: ""
                }
            }
            else {
                return {
                    veryweak: "#E22357",
                    weak: "#F98D00",
                    normal: "#57899E",
                    strong: "#93ccaf",
                    verystrong: "#40B680"
                }
            }
        })
    };

    const onsubmit = async (e) => {
        e.preventDefault();
        setbtndis(true);
        try {
            let valid = await validate.register.validate(input, { abortEarly: false });
            let result = await api.register(valid);
            Storage.setCookies(result);
            let local = Storage.setlocalstorage(result.data);
            user_data.updateState(local);
            Toaster.success("register Successfully");
            history.push(urlhelper.getAbsoluteUrl(local.username, "dashboard"));
        } catch (e) {
            console.log(e.errors);
            let obj = {
                firstName: false,
                lastName: false,
                email: false,
                password: false,
                confirmpassword: false
            };
            for (let val of e.errors) {
                let [key, value] = Object.entries(val)[0];
                obj[key] = value;
            }
            // Toaster.error("");
            setError((pre) => {
                return { ...pre, ...obj }
            });
        }
        setbtndis(false);
    }
    const handler = (event) => {
        let { name, value } = event.target;
        setIput((prevState) => {
            return { ...prevState, [name]: value }
        });
    };
    useEffect(() => {
        return () => {
            setbtndis(false);
            settype(false);
            setIput(false);
            setError(false);
            setColor(false);
        }
    }, [])
    return (
        <>
            <section className="site-main">
                <div className="container">
                    <div className="page-content py-md-5">
                        <div className="main-row row justify-content-center">
                            <div className="page-col">
                                <div className="section_link d-flex justify-content-between px-5">
                                    <NavLink to='/login'>Login</NavLink>
                                    <NavLink to='/register'>Sign up</NavLink>
                                </div>
                                <div className="sign-with-client">
                                    <div className="client-btn d-flex justify-content-between flex-wrap">
                                        <GoogleSocialLogin fun={setError} />
                                        {/* <FacebookSocialLogin fun={setError} /> */}
                                    </div>
                                    <p>OR</p>
                                </div>
                                <Box className="form-col register">
                                    <ThemeProvider theme={theme}>
                                        <Box component='form' onSubmit={onsubmit}>
                                            <div className="row justify-content-between">
                                                <div className="col-md-6">
                                                    <TextField label='First Name' placeholder='First Name' sx={error.fname ? { borderColor: "red" } : {}} value={input.name} onChange={handler} name="firstName" required fullWidth />
                                                    {(error.firstName) ? <Er value={error.firstName} /> : ""}
                                                </div>
                                                <div className="col-md-6">
                                                    <TextField label='Last Name' placeholder='Last Name' sx={error.lname ? { borderColor: "red" } : {}} value={input.lname} onChange={handler} name="lastName" required fullWidth />
                                                    {(error.lastName) ? <Er value={error.lastName} /> : ""}
                                                </div>
                                            </div>

                                            <TextField type='email' label='Email' placeholder='Email' sx={(error.email) ? { borderColor: "red" } : {}} value={input.email} onChange={handler} name="email" required fullWidth />
                                            {(error.email) ? <Er value={error.email} /> : ""}
                                            
                                            <FormControl variant='outlined' fullWidth>
                                            <InputLabel htmlFor="password">Password</InputLabel>
                                            <OutlinedInput 
                                                type={type}
                                                label='Password'
                                                placeholder="Password"
                                                value={input.password}
                                                onChange={e => { handler(e); changecolor(e) }}
                                                sx={(error.password) ? { borderColor: "red" } : {}}
                                                name="password"
                                                required
                                                fullWidth
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={changetype}
                                                        // onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                        >
                                                        {(type === 'password') ? <Visibility /> : <VisibilityOff />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                            />
                                            </FormControl>
                                            {(error.password) ? <Er value={error.password} /> : ""}
                                            
                                            <FormControl variant='outlined' fullWidth>
                                            <InputLabel htmlFor="password">Confirm Password</InputLabel>
                                            <OutlinedInput 
                                                type={type}
                                                label='Confirm Password'
                                                placeholder="Password"
                                                value={input.confirmpassword}
                                                onChange={e => { handler(e); changecolor(e) }}
                                                sx={(error.confirmpassword) ? { borderColor: "red" } : {}}
                                                name="confirmpassword"
                                                required
                                                fullWidth
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={changetype}
                                                        // onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                        >
                                                        {(type === 'password') ? <Visibility /> : <VisibilityOff />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                            />
                                            </FormControl>
                                            {(error.confirmpassword) ? <Er value={error.confirmpassword} /> : ""}
                                            <div className="pwd-power d-flex justify-content-between align-items-center mb-3 pb-1">
                                                <span style={{ backgroundColor: color.veryweak }}></span>
                                                <span style={{ backgroundColor: color.weak }}></span>
                                                <span style={{ backgroundColor: color.normal }}></span>
                                                <span style={{ backgroundColor: color.strong }}></span>
                                                <span style={{ backgroundColor: color.verystrong }}></span>
                                            </div>
                                            
                                            {(btn_dis) ? <div className="spinner-border" style={{ display: "flex", position: "relative", marginLeft: "90%", top: "40px" }}></div> : ""}
                                            <input className="form-control" type="submit" value="Sign up" disabled={btn_dis ? true : false} />
                                        </Box>
                                    </ThemeProvider>
                                    {/* <form action="#" onSubmit={onsubmit}>
                                        <div className="row justify-content-between">
                                            <div className="name-field form-group position-relative col-md-6 mb-3">
                                                <label htmlFor="fname">First Name</label>
                                                <input className="form-control" type="text" placeholder="First Name" style={error.fname ? { borderColor: "red" } : {}} value={input.name} onChange={handler} name="name" required />
                                                {(error.fname) ? <Er value={error.fname} /> : ""}
                                            </div>
                                            <div className="name-field form-group position-relative col-md-6 mb-3">
                                                <label htmlFor="lname">Last Name</label>
                                                <input className="form-control" type="text" placeholder="Last Name" style={error.lname ? { borderColor: "red" } : {}} value={input.lname} onChange={handler} name="lname" required />
                                                {(error.lname) ? <Er value={error.lname} /> : ""}
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="email">Email</label>
                                            <input className="form-control mb-3" type="email" placeholder="Email" style={(error.email) ? { borderColor: "red" } : {}} value={input.email} onChange={handler} name="email" required />
                                            {(error.email) ? <Er value={error.email} /> : ""}
                                        </div>
                                        <div className="pwd-field-eye mb-3">
                                            <label htmlFor="password">Password</label>
                                            <div className="position-relative">
                                                <input className="form-control" type={type} value={input.password} onChange={e => { handler(e); changecolor(e) }} placeholder="Password" style={(error.password) ? { borderColor: "red" } : {}} name="password" required />
                                                <button type="button" className="btn p-0" onClick={changetype}><i className="fal fa-eye"></i></button>
                                            </div>
                                        {(error.password) ? <Er value={error.password} /> : ""}
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="confirmpassword">Confirm Password</label>
                                        <input className="form-control mb-3" type="password" value={input.confirmpassword} onChange={handler} placeholder="Confirm Password" style={(error.confirmpassword) ? { borderColor: "red" } : {}} name="confirmpassword" required />
                                        {(error.confirmpassword) ? <Er value={error.confirmpassword} /> : ""}
                                        <div className="pwd-power d-flex justify-content-between align-items-center mb-3 pb-1">
                                            <span style={{ backgroundColor: color.veryweak }}></span>
                                            <span style={{ backgroundColor: color.weak }}></span>
                                            <span style={{ backgroundColor: color.normal }}></span>
                                            <span style={{ backgroundColor: color.strong }}></span>
                                            <span style={{ backgroundColor: color.verystrong }}></span>
                                        </div>
                                        </div>
                                        {(btn_dis) ? <div className="spinner-border" style={{ display: "flex", position: "relative", marginLeft: "90%", top: "40px" }}></div> : ""}
                                        <input className="form-control" type="submit" value="Sign up" disabled={btn_dis ? true : false} />
                                    </form> */}
                                </Box>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <ToasterC />

        </>
    )

};
export default Fun;