import React from "react";
import PricingCard from "./PricingCard";
// Import Images
import shape114 from '../../../assets/images/landing/shape/114.svg';
const PricingTable = () => {
    return (
        <>
        <div class="pricing-table-area-three">
                    <div class="row justify-content-center">
                        <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-duration="1200">
                            <PricingCard 
                                class="skew-right"
                                eventClass="pc1"
                                eventType="Free Event"
                                price="$0"
                                duration="per user/month"
                                lineImg={shape114}
                                felist1="60-day chat history"
                                felist2="Basic widget customization"
                                disableClass1="disable"
                                fedisable1="Event system"
                                disableClass2="disable"
                                fedisable2="Data security"
                                link="/"
                            />
                            {/* <!-- /.pr-table-wrapper --> */}
                        </div>
                        <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
                            <PricingCard 
                                class="skew-left"
                                eventClass="pc2"
                                eventType="Pre Day"
                                price="$0.32"
                                duration="per user/month"
                                lineImg={shape114}
                                felist1="60-day chat history"
                                felist2="Basic widget customization"
                                fedisable1="Event system"
                                disableClass2="disable"
                                fedisable2="Data security"
                                link="/"
                            />
                            {/* <!-- /.pr-table-wrapper --> */}
                        </div>
                        <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200">
                        <PricingCard 
                                class="skew-right"
                                eventClass="pc3"
                                eventType="Pay As You Go"
                                price="$0.65"
                                duration="per user/month"
                                lineImg={shape114}
                                felist1="60-day chat history"
                                felist2="Basic widget customization"
                                fedisable1="Event system"
                                fedisable2="Data security"
                                link="/"
                            />
                            {/* <!-- /.pr-table-wrapper --> */}
                        </div>
                    </div>
                </div>
        </>

    );
}

export default PricingTable;