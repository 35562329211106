import React from "react";
import { Link } from "react-router-dom";
import logo from './../../../assets/images/logo-white.svg';
const Footer = () => {
    const scrollToTop = () =>{
        window.scrollTo({
          top: 0, 
          behavior: 'smooth'
        });
      };
    return (
        <>
        <footer class="theme-footer-four">
            <div class="top-footer">
                <div class="container">
                    <div class="row justify-content-end">
                        <div class="col-xl-4 col-lg-3 col-12 footer-about-widget">
                            <div class="logo">
                                <a href="/"><img src={logo} alt="" onClick={scrollToTop} /></a>
                            </div>
                            <ul class="font-rubik mt-10">
                                <li><Link to="mailto:hello@appointly.online">Hello@Appointly.online</Link></li>
                                <li><Link to="tel:">+92 300 000 0000</Link></li>
                            </ul>
                        </div>
                        {/* <!-- /.about-widget --> */}
                        <div class="col-lg-3 col-md-4 footer-list">
                            <h5 class="footer-title font-slab">Links</h5>
                            <ul>
                                <li><Link to="/landing" onClick={scrollToTop}>Home</Link></li>
                                <li><Link to="/features" onClick={scrollToTop}>Features</Link></li>
                                <li><Link to="/how-it-works" onClick={scrollToTop}>How It Works</Link></li>
                                <li><Link to="/pricing" onClick={scrollToTop}>Pricing</Link></li>
                                <li><Link to="/about-us" onClick={scrollToTop}>About us</Link></li>
                                <li><Link to="/contact-us" onClick={scrollToTop}>Contact us</Link></li>
                            </ul>
                        </div>
                        {/* <!-- /.footer-list --> */}
                        <div class="col-lg-3 col-md-4 footer-list">
                            <h5 class="footer-title font-slab">Legal</h5>
                            <ul>
                                <li><Link to="/terms">Terms &amp; conditions</Link></li>
                                <li><Link to="/privacy">Privacy policy</Link></li>
                            </ul>
                        </div>
                        {/* <!-- /.footer-list --> */}
                        <div class="col-xl-2 col-lg-3 col-md-4 footer-list">
                            <h5 class="footer-title font-slab">Products</h5>
                            <ul class="pe5">
                                <li><a href="/landing">Take the tour</a></li>
                                <li><a href="/landing">Live chat</a></li>
                                <li><a href="/landing">Self-service</a></li>
                                <li><a href="/landing">Social</a></li>
                                <li><a href="/landing">Mobile</a></li>
                            </ul>
                        </div>
                        {/* <!-- /.footer-list --> */}
                    </div>
                    {/* <!-- /.row --> */}
                </div>
                {/* <!-- /.container --> */}
            </div>

            <div class="container">
                <div class="bottom-footer-content">
                    <p>Copyright @2021 Appointly inc.</p>
                </div>
                {/* <!-- /.bottom-footer --> */}
            </div>
        </footer>
        </>
    );
}

export default Footer;